import { IconImage } from '@Components'
import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import { AssetsItem } from 'features/trainings/typings'
import { DisplayEnum } from 'shared/enums/display.enum'

import Trash from '@Assets/trash_training.svg'

type Props = {
	asset: AssetsItem
	removeSelectedAsset: (asset: AssetsItem) => void
}

export function AddedAsset(props: Props) {
	const { asset, removeSelectedAsset } = props
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	return (
		<Box
			display='flex'
			alignItems='center'
			justifyContent='flex-start'
			mb='3px'
			width={mobile ? '100%' : '320px'}
			bgcolor='rgba(33, 167, 111, 0.3)'
			borderRadius='5px'
			py='3px'
			pl='3px'
			boxSizing='border-box'
		>
			<Box
				minWidth='72px'
				width='72px'
				height='43px'
				borderRadius='5px'
				mr='10px'
				sx={{
					backgroundImage: asset.image ? `url(${asset.image})` : 'none',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					boxSizing: 'border-box',
				}}
			></Box>
			<Typography
				fontSize={16}
				fontFamily='GilroyMedium'
				color='rgba(255, 255, 255, 0.7)'
				lineHeight='1'
				overflow='hidden'
				whiteSpace='nowrap'
				textOverflow='ellipsis'
			>
				{asset.name}
			</Typography>
			<Box ml='auto'>
				<Button onClick={() => removeSelectedAsset(asset)} sx={{ minWidth: 'auto' }}>
					<Box display='flex' alignItems='center'>
						<IconImage src={Trash} alt='trash' height='16px' />
					</Box>
				</Button>
			</Box>
		</Box>
	)
}
