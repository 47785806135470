import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { CreateTraining, GroupOnlineTraining } from 'features/trainings/typings'
import { OnlineTraining } from './OnlineTraining'
import { CreateTrainingModel } from 'features/trainings/create-training-page/CreateTrainingPage'
import { DisplayEnum } from '@Enums'

type Props = {
	group: GroupOnlineTraining
	changeTraining: (training: CreateTrainingModel) => void
}

export function GroupOnlineTrainings(props: Props) {
	const theme = useTheme()
	const { group, changeTraining } = props
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))

	return (
		<Stack
			flexDirection={xlDisplay ? 'row' : 'column'}
			alignItems='flex-start'
			justifyContent='space-between'
			width='100%'
		>
			<Box
				width={xlDisplay ? '110px' : '100%'}
				maxWidth='100%'
				mr={xlDisplay ? '30px' : '0'}
				display='flex'
				alignItems='center'
				justifyContent='flex-start'
			>
				<Typography
					fontSize={16}
					fontFamily='GilroyBold'
					color='#fff'
					lineHeight='1'
					whiteSpace={'nowrap'}
					mr={xlDisplay ? '0' : '15px'}
				>
					{group.groupName}
				</Typography>
				{!xlDisplay && <Box height='1px' width='100%' bgcolor='rgba(255, 255, 255, 0.1)' my='7.5px'></Box>}
			</Box>
			<Box width='100%'>
				{xlDisplay && <Box height='1px' width='100%' bgcolor='rgba(255, 255, 255, 0.1)' my='7.5px'></Box>}
				{group.trainings.map((training: CreateTraining) => {
					return <OnlineTraining key={training.id} training={training} changeTraining={changeTraining} />
				})}
			</Box>
		</Stack>
	)
}
