import * as Yup from 'yup'

export const CreateTrainingValidationSchema = Yup.object().shape({
	title: Yup.string()
		.required('This field is required, please fill in the information')
		.min(3, 'Value is too short, please enter minimum 3 characters')
		.max(255, 'Value is too long, maximum length allowed is 255 characters'),
	cover: Yup.string().required('Cover image is required'),
	date: Yup.string().required('Dates fields are required, please fill in the information'),
	startTime: Yup.string().required('Dates fields are required, please fill in the information'),
})
