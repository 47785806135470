import { Typography, useTheme } from '@mui/material'

export function DisputesResolution() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color='primary'>
				<u>Disputes Resolution</u>
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally
				by contacting the Company.
			</Typography>
		</>
	)
}
