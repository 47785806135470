import { TrainingTypesEnum } from './../../../TrainingsReports/enums/trainingTypes.enum';
import { TrainingStatusesEnum } from '../../../TrainingsReports/enums/trainingStatuses.enum';

import type { Theme } from '@mui/material'
import { useMemo } from 'react'

function getHeading(title: TrainingStatusesEnum | string | TrainingTypesEnum) {
	const headings: Record<TrainingStatusesEnum | string | TrainingTypesEnum, string> = {
		[TrainingStatusesEnum.NotStarted]: 'Not Started',
		[TrainingStatusesEnum.InProgress]: 'In Progress',
		[TrainingStatusesEnum.Completed]: 'Completed',
		[TrainingStatusesEnum.All]: 'Total',
		[TrainingStatusesEnum.Users]: 'Users',
		[TrainingTypesEnum.All]: 'All',
		[TrainingTypesEnum.Technical]: 'Technical',
		[TrainingTypesEnum.Service]: 'Service',
		[TrainingTypesEnum.Sales]: 'Sales'
	}
	return headings[title]
}

function getStatusColor(title: TrainingStatusesEnum | string, theme: Theme) {
	const colors: Record<TrainingStatusesEnum | string, string> = {
		[TrainingStatusesEnum.NotStarted]: theme.palette.primary.main,
		[TrainingStatusesEnum.InProgress]: theme.palette.secondary.contrastText,
		[TrainingStatusesEnum.Completed]: theme.palette.success.main,
		[TrainingStatusesEnum.All]: '',
		[TrainingStatusesEnum.Users]: '',
	}


	return colors[title]
}

type Props = {
	title: TrainingStatusesEnum | string
	theme: Theme
}

export function useData(props: Props) {
	const { theme, title } = props
	const heading = useMemo(() => getHeading(title), [title])
	const statusColor = useMemo(() => getStatusColor(title, theme), [title, theme])

	return {
		statusColor, heading
	}
}
