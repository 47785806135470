import { Stack, styled } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'

export const CourseContentCenterBlock = styled(Stack, { name: 'CourseContentCenterBlock' })({
	backgroundImage: `linear-gradient(31deg, #9945FF, #13F094)`,
	borderBottomLeftRadius: '16px',
	borderBottomRightRadius: '16px',
	[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
		borderRadius: '16px'
	}
})
