import Image from '@Assets/bl4.jpg'
import Check from '@Assets/done_tick.svg'

import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { BlockHeader } from './BlockHeader'
import { IconImage } from '@Components'
import { TheoryStack } from './TheoryStack'
import { DisplayEnum } from 'shared/enums/display.enum'

export function Theory() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	return (
		<Stack mt={mobile ? 3 : 10} pb={6} id='theory'>
			<BlockHeader
				color={theme.palette.success.main}
				title='What we do'
				subtitle='What is Virtual Reality Training and how can it help your dealers?'
			/>

			<Stack mx='auto' display='flex' justifyContent='center' alignItems={'center'}>
				<Box borderRadius='16px' overflow='hidden'>
					<IconImage src={Image} width={xlDisplay ? '1180px' : mobile ? '100vw' : '100%'} />
				</Box>
				<TheoryStack
					px={3}
					py={3}
					mt={mobile ? -8.5 : -20}
					mx='auto'
					boxSizing='border-box'
					borderRadius='16px'
					maxWidth={mobile ? 315 : 763}
				>
					<Typography variant='body1' fontFamily='GilroyRegular' maxWidth={703} color='primary' textAlign='center'>
						At Bridge VR, we understand the importance of keeping your employees’ skill sets up-to-date. The demand for
						fully qualified technicians, especially those certified to work with EVs, is at an all-time high.
						<br />
						VR training gives technicians the opportunity to complete necessary training courses without leaving
						their dealers.
					</Typography>

					<Stack
						direction={mobile ? 'column' : 'row'}
						alignContent='start'
						spacing={mobile ? 2 : 5}
						mt={3}
						justifyContent={mobile ? 'flex-start' : 'space-around'}
					>
						<Box>
							<Stack direction='row' mb={2}>
								<IconImage src={Check} alt='checked' />
								<Typography variant='h6' fontFamily='GilroySemiBold' ml={1.5} color='primary' textTransform='uppercase'>
									Immersive Learning
								</Typography>
							</Stack>
							<Stack direction='row'>
								<IconImage src={Check} alt='checked' />
								<Typography variant='h6' fontFamily='GilroySemiBold' ml={1.5} color='primary' textTransform='uppercase'>
									Confidence Building
								</Typography>
							</Stack>
						</Box>
						<Box>
							<Stack direction='row' mb={2}>
								<IconImage src={Check} alt='checked' />
								<Typography variant='h6' fontFamily='GilroySemiBold' ml={1.5} color='primary' textTransform='uppercase'>
									Cost-Effective
								</Typography>
							</Stack>
							<Stack direction='row'>
								<IconImage src={Check} alt='checked' />
								<Typography variant='h6' fontFamily='GilroySemiBold' ml={1.5} color='primary' textTransform='uppercase'>
									Customized Materials
								</Typography>
							</Stack>
						</Box>
					</Stack>
				</TheoryStack>
			</Stack>
		</Stack>
	)
}
