import { Box, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import LinkedinLogo from '@Assets/linkedin.svg'
import InstagramLogo from '@Assets/instagram.svg'
import TicTokLogo from '@Assets/tiktok.svg'
import TwitterLogo from '@Assets/twitter.svg'
import YouTubeLogo from '@Assets/youtube.svg'
import BridgeLogo from '@Assets/fl1.png'

import { DividerGray } from './DividerGray'
import { IconImage } from './IconImage'
import { AdditionalInfo } from './AdditionalInfo'
import { DisplayEnum } from 'shared/enums/display.enum'
import { useFooterData } from '../hooks/useFooterData'

type Props = {
	showAdditionalInfo?: boolean
	onMainPage: boolean
}

export function Footer(props: Props) {
	const { showAdditionalInfo, onMainPage } = props

	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { showInstruction, instructionLink } = useFooterData()

	return (
		<Stack id='contacts' width='100%' pt={xlDisplay ? 0 : 15}>
			{showAdditionalInfo && <AdditionalInfo onMainPage={onMainPage} />}

			<DividerGray />

			<Stack
				px={xlDisplay ? 13 : 3}
				direction={mobile ? 'column' : 'row'}
				justifyContent='space-between'
				pt={4.5}
				pb={5.5}
				bgcolor={theme.palette.background.paper}
			>
				{!mobile && (
					<Box>
						<Link href='https://bridgelt.com/' target='_blank'>
							<IconImage src={BridgeLogo} alt='Bridge Logo' />
						</Link>
						<Typography mt={3} variant='body1' color={theme.palette.text.secondary} fontFamily='GilroyMedium'>
							© 2022 Bridge Learning Tech
						</Typography>
					</Box>
				)}
				<Box>
					<Stack direction='row' spacing={2.8} ml={mobile ? 0 : 'auto'} justifyContent={mobile ? 'center' : 'end'}>
						<Link href='https://twitter.com/bridgelearning1' target='_blank'>
							<IconImage src={TwitterLogo} alt='Twitter Logo' height='26px' />
						</Link>
						<Link href='https://www.youtube.com/channel/UC-zHgMRyo6EOBchJpeIzFZw' target='_blank'>
							<IconImage src={YouTubeLogo} alt='YouTubeLogo' height='26px' />
						</Link>
						<Link href='https://www.instagram.com/bridgelearningtechnologies/' target='_blank'>
							<IconImage src={InstagramLogo} alt='Instagram Logo' height='26px' />
						</Link>
						<Link href='https://www.linkedin.com/company/bridge-learning-technologies-ltd/' target='_blank'>
							<IconImage src={LinkedinLogo} alt='Linkedin Logo' height='26px' />
						</Link>
						<Link href='https://www.tiktok.com/@bridgelt' target='_blank'>
							<IconImage src={TicTokLogo} alt='TicTok Logo' height='26px' />
						</Link>
					</Stack>
					{mobile && (
						<Box textAlign='center' mt={3}>
							<Link href='https://bridgelt.com/' target='_blank'>
								<IconImage src={BridgeLogo} alt='Bridge Logo' />
							</Link>
							<Typography mt={2} variant='body1' color={theme.palette.text.secondary} fontFamily='GilroyMedium'>
								© 2022 Bridge Learning Tech
							</Typography>
						</Box>
					)}
					<Stack direction='row' spacing={1} mt={mobile ? 2.3 : 5.4}>
						{showInstruction && (
							<Link color={theme.palette.text.secondary} target='_blank' href={instructionLink}>
								Instructions for using the Oculus app
							</Link>
						)}
						<Link color={theme.palette.text.secondary} target='_blank' href='/privacy-policy'>
							Privacy Policy
						</Link>
						<Link color={theme.palette.text.secondary} target='_blank' href='/terms-and-conditions'>
							Terms & Conditions
						</Link>
						<Link color={theme.palette.text.secondary} target='_blank' href='/cookie'>
							Cookies
						</Link>
					</Stack>
				</Box>
			</Stack>
		</Stack>
	)
}
