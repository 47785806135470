import type { State } from './typings'

export const initialState: State = {
	trainingsInfo: {
		all: 0,
		new: 0,
		inProgress: 0,
		completed: 0,
		trainings: [],
		technical: 0,
		service: 0,
		sales: 0,
	},
	filters: { search: '' },
}
