import { useMemo } from 'react'
import type { CardOptions } from '@Types'
import { useTheme } from '@mui/material'
import { SubscriptionPlansEnum } from '@Enums'

export function useSubscriptionsCardsData() {
	const theme = useTheme()
	return useMemo(
		() => ({
			standard: {
				users: '',
				title: 'Standard',
				description: 'Begin to explore the world of VR training and get an insight into how this new way of learning can benefit your business.',
				bottomText: 'BILLED ANNUALLY',
				price: 27,
				seats: 3,
				seatPrice: 9,
				type: 'common',
				bgcolor: theme.palette.primary.dark,
				width: '381px',
				height: '541px',
				marginY: '12px',
				marginTop: '',
				buttonVariant: 'price_card_green',
				showContactInfo: false,
				showLabel: false,
				plan: SubscriptionPlansEnum.Standard,
			} as CardOptions,
			pro: {
				users: '',
				title: 'Pro',
				description: 'Discover what VR can bring to your workplace. Expand your employees’ skill-sets with a range of interactive training content.',
				bottomText: 'BILLED ANNUALLY',
				price: 40,
				seats: 5,
				seatPrice: 8,
				type: 'bestseller',
				width: '429px',
				height: '580px',
				marginY: '50px',
				marginTop: '-600px',
				buttonVariant: 'price_card',
				showContactInfo: false,
				showLabel: true,
				plan: SubscriptionPlansEnum.Pro,
			} as CardOptions,
			enterprise: {
				users: '',
				title: 'Enterprise',
				description: 'Have us create your VR content to fit the needs of unique business.',
				subDescription: 'Integrate with your LMS and enroll an unlimited number of Oculus users.',
				bottomText: 'TO SIGN UP OR TO FIND OUT MORE ABOUT HOW YOU CAN START USING VR IN YOUR BUSINESS',
				price: 0,
				seats: 0,
				seatPrice: 0,
				bgcolor: theme.palette.primary.dark,
				width: '381px',
				height: '541px',
				marginTop: '',
				buttonVariant: 'price_card_green',
				showContactInfo: true,
				showLabel: false,
				plan: SubscriptionPlansEnum.Enterprise,
			} as CardOptions,
		}),
		[],
	)
}
