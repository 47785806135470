export const URLS = {
	login: '/user/login',
	registration: '/user/registration',
	resetPassword: '/user/resetpassword',
	confirmReset: '/user/confirmReset',
	checkUser: '/user/check',
	contactUs: '/user/contactus',
	updateToken: '/user/updaterole',
	refreshToken: 'accounts/newtoken',
	fetchSubscription: '/subscription',
	fetchPayments: '/payment/all',
	getInvoice: '/payment/invoice',
	fetchSubscriptionUsers: '/subscription/users',
	addSubscriptionUser: '/subscription/subscription-users',
	removeSubscriptionUser: '/subscription/subscription-users',
	unsubscribe: '/subscription/unsubscribe',
	fetchIntent: '/payment',
	fetchPaymentSession: '/payment/create-checkout-session',
	fetchTrainings: '/trainings/overview',
	fetchCode: '/trainings/code',
	fetchTrainingAssets: '/trainings/online/assets',
	fetchOnlineTrainings: '/trainings/online/overview',
	uploadImage: '/trainings/files/upload',
	createTraining: '/trainings/online/save',
	deleteTraining: '/trainings/online/delete',
	fetchPdfInfo: '/payment/paymentData',
	trainingsStatusesReports: '/statistics/trainingStatuses',
	trainingsReports: '/statistics/trainings',
	participantTypes: '/statistics/participantTypes',
	participants: '/statistics/participants',
}
