import { Fragment } from 'react'
import type { ReactElement } from 'react'
import { useFetchAssetsOnMount } from './hooks'


type Props = {
	children: ReactElement
	setLoading: (value: boolean) => void
}

export function DataManager(props: Props) {
	const { children, setLoading } = props
	
	useFetchAssetsOnMount(setLoading)

	return <Fragment>{children}</Fragment>
}
