import { Avatar, Box, Button, Dialog, IconButton, Link, Stack, Typography, useTheme } from '@mui/material'
import { useCloseBurgerMenu, useGoToMain, useGoToSubscriptions, useIsLoggedIn, useLogout, useToken } from '@Hooks'
import { useSharedContext } from '@Context'
import Logo from '@Assets/bridge_logo.png'
import LinkedinLogo from '@Assets/linkedin.svg'
import InstagramLogo from '@Assets/fl22.png'
import TicTokLogo from '@Assets/fl23.png'
import TwitterLogo from '@Assets/fl24.png'
import YouTubeLogo from '@Assets/fl25.png'
import BridgeLogo from '@Assets/fl1.png'
import LogoutIcon from '@mui/icons-material/Logout'
import CloseIcon from '@mui/icons-material/Close'
import { DividerGray, HashLink, IconImage, StyledAvatar } from '@Components'
import { RolesService } from 'shared/services/roles.service'
import { RolesEnum } from '@Enums'
import { MenuModal } from './MenuModal'

type Props = {
	hideNavigationMenu?: boolean
}

export function BurgerMenu(props: Props) {
	const [{ openBurgerMenu }] = useSharedContext()
	const theme = useTheme()
	const handleCloseBurgerMenu = useCloseBurgerMenu()

	const handleSubscribeClick = useGoToSubscriptions()
	const isLoggedIn = useIsLoggedIn()
	const user = useToken()
	const { hideNavigationMenu } = props
	const handleLogout = useLogout()

	const showSubscriptions = RolesService.availableForRole([RolesEnum.SuperAdmin, RolesEnum.Admin])
	const showTrainings = RolesService.availableForRole([
		RolesEnum.Admin,
		RolesEnum.SuperUser,
		RolesEnum.User,
		RolesEnum.SuperAdmin,
		RolesEnum.Guest,
		RolesEnum.Trainer,
	])
	const showReports = false // RolesService.availableForRole([
	// 	RolesEnum.SuperAdmin,
	// 	RolesEnum.SuperUser,
	// 	RolesEnum.Admin,
	// 	RolesEnum.Guest,
	// 	RolesEnum.Trainer
	// ])
	const showSubscribe = (RolesService.availableForRole([RolesEnum.SuperAdmin]) && isLoggedIn) || !isLoggedIn
	const showOnlineTrainings = RolesService.availableForRole([
		RolesEnum.Trainer,
		RolesEnum.Admin,
		RolesEnum.SuperAdmin,
		RolesEnum.SuperUser,
		RolesEnum.User,
	])

	return (
		<Dialog fullScreen open={openBurgerMenu} PaperComponent={MenuModal}>
			<Stack pt={1.8} pb={1.8} direction='row' alignItems='center'>
				<IconButton edge='start' color='inherit' onClick={handleCloseBurgerMenu} aria-label='close'>
					<CloseIcon />
				</IconButton>
				<IconImage src={Logo} alt='logo' height='24px' />
			</Stack>
			<DividerGray />
			<Stack fontFamily='GilroyBold' direction='column' spacing={2} mt={2} mb={2}>
				{!isLoggedIn && (
					<Box>
						<Link fontSize={24} lineHeight={1.2} underline='none' href='/login'>
							Sign In
						</Link>
					</Box>
				)}
				{isLoggedIn && (
					<Stack spacing={1} direction='row' alignItems='center'>
						<StyledAvatar>
							<Typography color='primary'>{user.name && user.name[0].toUpperCase()}</Typography>
						</StyledAvatar>
						<Typography fontFamily='GilroyMedium' fontSize='18px' color='primary'>
							{user.name}
						</Typography>
					</Stack>
				)}

				{isLoggedIn && (
					<Box>
						<IconButton edge='start' color='inherit' onClick={handleLogout} aria-label='logout'>
							<LogoutIcon />
							<Typography fontFamily='GilroyBold' fontSize={24} lineHeight={1.2}>
								Logout
							</Typography>
						</IconButton>
					</Box>
				)}
			</Stack>
			<DividerGray />
			{!hideNavigationMenu && (
				<Stack direction='column' fontFamily='GilroyBold' fontSize={24} lineHeight={1.2} spacing={2} mt={2} mb={2}>
					<Box>
						<HashLink onClick={handleCloseBurgerMenu} to='#theory'>
							What we do
						</HashLink>
					</Box>
					<Box>
						<HashLink onClick={handleCloseBurgerMenu} to='#about'>
							Technical Training
						</HashLink>
					</Box>
					<Box>
						<HashLink onClick={handleCloseBurgerMenu} to='#safetly'>
							Custom Development
						</HashLink>
					</Box>
					<Box>
						<HashLink onClick={handleCloseBurgerMenu} to='#pricing'>
							Pricing
						</HashLink>
					</Box>
					<Box>
						<HashLink onClick={handleCloseBurgerMenu} to='#contacts'>
							Contact
						</HashLink>
					</Box>
					{showSubscribe && (
						<Box>
							<Link
								href='https://bridgelt.com/blog'
								target='_blank'
								color={theme.palette.primary.main}
								fontSize={24}
								underline='none'
							>
								Blog
							</Link>
						</Box>
					)}
				</Stack>
			)}
			{hideNavigationMenu && isLoggedIn && (
				<Stack direction='column' fontFamily='GilroyRegular' spacing={2}>
					{showSubscriptions && (
						<Box>
							<HashLink onClick={handleCloseBurgerMenu} to='/subscriptions'>
								Subscription
							</HashLink>
						</Box>
					)}
					{showTrainings && (
						<Box>
							<HashLink onClick={handleCloseBurgerMenu} to='/trainings'>
								Trainings
							</HashLink>
						</Box>
					)}
					{false && showReports && (
						<Box>
							<HashLink onClick={handleCloseBurgerMenu} to='/reports'>
								Reports
							</HashLink>
						</Box>
					)}
					{showOnlineTrainings && user?.subscriptionId && (
						<Box>
							<HashLink onClick={handleCloseBurgerMenu} to='/online-trainings'>
								Online
							</HashLink>
						</Box>
					)}
				</Stack>
			)}
			<DividerGray />

			<Stack direction='row' spacing={2} ml='auto' mr='auto' mt={2}>
				<Link href='https://twitter.com/bridgelearning1'>
					<IconImage src={TwitterLogo} alt='Twitter Logo' height='26px' />
				</Link>
				<Link href='https://www.youtube.com/channel/UC-zHgMRyo6EOBchJpeIzFZw' target='_blank'>
					<IconImage src={YouTubeLogo} alt='YouTubeLogo' height='26px' />
				</Link>
				<Link href='https://www.instagram.com/bridgelearningtechnologies/' target='_blank'>
					<IconImage src={InstagramLogo} alt='Instagram Logo' height='26px' />
				</Link>
				<Link href='https://www.linkedin.com/company/bridge-learning-technologies-ltd/' target='_blank'>
					<IconImage src={LinkedinLogo} alt='Linkedin Logo' height='26px' />
				</Link>
				<Link href='https://www.tiktok.com/@bridgelt' target='_blank'>
					<IconImage src={TicTokLogo} alt='TicTok Logo' height='26px' />
				</Link>
			</Stack>

			<Stack mt={2} ml='auto' mr='auto'>
				<IconImage src={BridgeLogo} alt='Bridge Logo' />
				<Typography mt={2} variant='body1' color={theme.palette.text.secondary} fontFamily='GilroyMedium'>
					© 2022 Bridge Learning Tech
				</Typography>
			</Stack>

			<Stack direction='row' spacing={1} ml='auto' mr='auto' mt={2} mb={2}>
				<Link color={theme.palette.text.secondary} target='_blank' href='/privacy-policy'>
					Privacy Policy
				</Link>
				<Link color={theme.palette.text.secondary} target='_blank' href='/terms-and-conditions'>
					Terms & Conditions
				</Link>
				<Link color={theme.palette.text.secondary} target='_blank' href='/cookie'>
					Cookies
				</Link>
			</Stack>
		</Dialog>
	)
}
