import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { CreateTraining } from 'features/trainings/typings'
import { getTimeIntervalWithPeriod, prepareCreateTrainingModel } from 'features/trainings/utils/utils'
import { CreateTrainingModel } from 'features/trainings/create-training-page/CreateTrainingPage'
import { useToken } from '@Hooks'
import { DisplayEnum, RolesEnum } from '@Enums'
import { useEffect, useState } from 'react'
import { fetchCodeEffect } from 'features/trainings/effects'
import { UPDATE_CODE_TIME } from 'features/trainings/constants'
import { pastTraining } from 'features/trainings/utils/calendar.utils'

type Props = {
	training: CreateTraining
	changeTraining: (training: CreateTrainingModel) => void
}

export function OnlineTraining(props: Props) {
	const theme = useTheme()
	const { training, changeTraining } = props
	const user = useToken()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const [timer, setTimer] = useState<boolean>(true)
	const [updatedCode, setUpdatedCode] = useState<null | string>(null)

	const prepareTraining = () => {
		if (pastTraining(training.endTime)) {
			return
		}

		if (user?.role === RolesEnum.User || user?.role === RolesEnum.SuperUser) {
			return
		}
		const data = prepareCreateTrainingModel(training)
		changeTraining(data)
	}

	const getNewCode = async (event: any) => {
		event.stopPropagation()
		const code = await fetchCodeEffect(training.id || '')
		setUpdatedCode(code.code)
		setTimer(true)
	}

	useEffect(() => {
		const interval = setInterval(() => {
			setTimer(false)
		}, UPDATE_CODE_TIME)

		return () => {
			clearInterval(interval)
		}
	}, [timer])

	return (
		<Stack
			flexDirection={mobile ? 'column' : 'row'}
			alignItems='flex-start'
			justifyContent='flex-start'
			my={2}
			width='100%'
			sx={{
				cursor:
					user?.role === RolesEnum.User || user?.role === RolesEnum.SuperUser || pastTraining(training.endTime)
						? 'auto'
						: 'pointer',
				opacity: pastTraining(training.endTime) ? '0.5' : '1',
			}}
			onClick={prepareTraining}
		>
			<Box
				sx={{
					width: '100%',
					maxWidth: mobile ? '100%' : '140px',
					minWidth: mobile ? '100%' : '140px',
					height: '77px',
					borderRadius: '5px',
					backgroundImage: `url(${training.cover})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					boxSizing: 'border-box',
				}}
			></Box>
			<Box
				display='flex'
				flexDirection='column'
				alignItems='flex-start'
				justifyContent='flex-start'
				mt={mobile ? '10px' : '0'}
				ml={mobile ? '0' : '10px'}
			>
				<Box display='flex' alignItems='center' justifyContent='flex-start'>
					{!pastTraining(training.endTime) && timer && (
						<Box
							bgcolor='#9945FF'
							borderRadius='3px'
							padding='2px 5px'
							mr='10px'
							fontFamily='GilroyMedium'
							fontSize='14px'
							color='#fff'
						>
							#{updatedCode ?? training.code}
						</Box>
					)}
					{!pastTraining(training.endTime) && !timer && (
						<Box
							padding='2px 5px'
							fontFamily='GilroyBold'
							fontSize='14px'
							color='rgba(255, 255, 255, 0.7)'
							mr='10px'
							onClick={getNewCode}
						>
							Get Code
						</Box>
					)}
					<Typography
						fontSize={mobile ? 14 : 16}
						fontFamily='GilroyRegular'
						color='rgba(255, 255, 255, 0.7)'
						lineHeight='20px'
					>
						{getTimeIntervalWithPeriod(training.startTime, training.endTime)}
					</Typography>
				</Box>
				<Typography fontSize={mobile ? 16 : 22} fontFamily='GilroySemiBold' color='#fff' lineHeight='27px' my='6px'>
					{training.title}
				</Typography>
				<Typography
					fontSize={mobile ? 14 : 16}
					fontFamily='GilroyRegular'
					color='rgba(255, 255, 255, 0.7)'
					lineHeight='20px'
				>
					{training.description}
				</Typography>
			</Box>
		</Stack>
	)
}
