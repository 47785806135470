import { BpCheckedIcon, BpIcon, StyledCheckbox } from '@Components'
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { AssetsGroupResponse, AssetsItem } from 'features/trainings/typings'
import { DisplayEnum } from 'shared/enums/display.enum'
import { AssetItem } from './AssetItem'

type Props = {
	assetsGroup: AssetsGroupResponse
	marginBottom: string
	selectedAssets: AssetsItem[]
	selectedAssetsFunc: (assets: AssetsItem[]) => void
}

export function AssetsGoup(props: Props) {
	const { assetsGroup, marginBottom, selectedAssets, selectedAssetsFunc } = props
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))

	const selectAllGroups = (event: any) => {
		if (event.target.checked) {
			const unselectedAssets = assetsGroup.assets.filter(
				(a: AssetsItem) => !selectedAssets.find((i: AssetsItem) => i.id === a.id),
			)
			const updatedSelectedAssets = [...selectedAssets, ...unselectedAssets]
			selectedAssetsFunc(updatedSelectedAssets)
		} else {
			const updatedSelectedAssets = selectedAssets.filter(
				(a: AssetsItem) => !assetsGroup.assets.find((i: AssetsItem) => i.id === a.id),
			)
			selectedAssetsFunc(updatedSelectedAssets)
		}
	}

	const selectAsset = (event: any, asset: AssetsItem): void => {
		if (event.target.checked) {
			const updatedSelectedAssets = [...selectedAssets, asset]
			selectedAssetsFunc(updatedSelectedAssets)
		} else {
			const updatedSelectedAssets = selectedAssets.filter((a: AssetsItem) => a.id !== asset.id)
			selectedAssetsFunc(updatedSelectedAssets)
		}
	}

	const checkSelectedAllAssets = (): boolean => {
		return !assetsGroup.assets.filter((a: AssetsItem) => !selectedAssets.find((i: AssetsItem) => i.id === a.id)).length
	}

	return (
		<Stack alignItems='flex-start' justifyContent='flex-start' mb={marginBottom}>
			<Box display='flex' flexDirection='row' alignItems='center'>
				<StyledCheckbox
					disableRipple
					color='default'
					checkedIcon={<BpCheckedIcon />}
					checked={checkSelectedAllAssets()}
					icon={<BpIcon />}
					inputProps={{ 'aria-label': 'Checkbox demo' }}
					onChange={selectAllGroups}
					sx={{ span: { width: 24, height: 24, '::before': { width: 24, height: 24 } } }}
				/>
				<Typography
					fontSize={16}
					fontFamily='GilroyMedium'
					color='#fff'
					lineHeight='1'
					overflow='hidden'
					whiteSpace='nowrap'
					textOverflow='ellipsis'
				>
					{assetsGroup.groupName}
				</Typography>
			</Box>
			<Box
				display='flex'
				alignItems='center'
				justifyContent='flex-start'
				flexWrap='wrap'
				flexDirection={xlDisplay ? 'row' : 'column'}
				width={xlDisplay ? 'auto' : '100%'}
			>
				{assetsGroup.assets.map((asset: AssetsItem, index: number) => {
					return (
						<AssetItem
							key={asset.id}
							asset={asset}
							index={index}
							selectedAssets={selectedAssets}
							selectAsset={selectAsset}
						/>
					)
				})}
			</Box>
		</Stack>
	)
}
