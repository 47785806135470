import { Typography, useTheme } from '@mui/material'

export function TranslationInterpretation() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color='primary'>
				<u>Translation Interpretation</u>
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				These Terms and Conditions may have been translated if We have made them available to You on our Service. You
				agree that the original English text shall prevail in the case of a dispute.
			</Typography>
		</>
	)
}
