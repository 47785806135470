import { Box, Button, Stack, Typography, useTheme } from '@mui/material'
import { TextField, SelectField, BpCheckbox } from '@Components'
import { Field, Form, useFormikContext } from 'formik'
import { useData, useHandlers } from './hooks'
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled'
import { RolesEnum } from '@Enums'
import { Dispatch, SetStateAction, useEffect, useRef } from 'react'

type Props = {
	setShowError: Dispatch<SetStateAction<any>>
	showError: any
	submitting: boolean
}

export function AddUserForm(props: Props) {
	const { showError, submitting, setShowError } = props
	const theme = useTheme()
	const { handlePopupClose } = useHandlers(setShowError)
	const { rolesItems } = useData()
	const { values, setFieldValue, errors, touched } = useFormikContext<{
		role?: RolesEnum
		withOculus: boolean
		email: string
	}>()
	let disabled = useRef(false)

	useEffect(() => {
		switch (values.role) {
			case RolesEnum.Admin:
			case RolesEnum.SuperAdmin:
				setFieldValue('withOculus', false)
				disabled.current = false
				return
			case RolesEnum.User:
			case RolesEnum.SuperUser:
			case RolesEnum.Trainer:
				setFieldValue('withOculus', true)
				disabled.current = true
				return
			case RolesEnum.Guest:
				setFieldValue('withOculus', false)
				disabled.current = true
				return
			default:
				disabled.current = false
		}
	}, [values.role, setFieldValue])

	return (
		<Form>
			<Stack spacing={3}>
				{!!Object.entries(showError).length && (
					<Stack
						spacing={2}
						p={3}
						bgcolor='#AA1D1D42'
						border='1px solid #FD2C2C'
						borderRadius='6px'
						direction='row'
						alignItems='center'
					>
						<Box color='#FD2C2C'>
							<PersonAddDisabledIcon />
						</Box>
						<Typography variant='body1' color='primary'>
							{Object.entries(showError)[0][1] as any}
						</Typography>
					</Stack>
				)}
				<Box>
					<Field label='EMAIL' name='email' id='email' component={TextField} placeholder='Please enter your e-mail' />
					{errors.email && touched.email ? (
						<Typography variant='caption' color='red'>
							{errors.email}
						</Typography>
					) : null}
				</Box>
				<SelectField items={rolesItems} name='role' id='role' bgColor='#fff' width='100%' label='ROLE' />
				<Stack>
					<Typography variant='body1' fontFamily='GilroyMedium' color='primary'>
						USING OCULUS
					</Typography>
					<Stack direction='row' spacing={2}>
						<Field
							id='withOculus'
							name='withOculus'
							component={BpCheckbox}
							style={{ transform: 'scale(2)' }}
							disabled={disabled.current}
						/>
						<Typography variant='body1' fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
							If you register a manager user who will not be using Oculus glasses - they will not be included in the
							active subscription users.
						</Typography>
					</Stack>
				</Stack>
				<Button variant='rounded_success' fullWidth type='submit' disabled={submitting}>
					ADD USER
				</Button>
				<Button variant='text' onClick={handlePopupClose}>
					CANCEL
				</Button>
			</Stack>
		</Form>
	)
}
