import { Box, useTheme } from '@mui/material'
import { Description, About, Theory, Safety, Pricing } from './components'
import { Footer } from '@Components'

export function HomePageComponent() {
	const theme = useTheme()

	return (
		<Box position='relative' bgcolor={theme.palette.background.default}>
			<Description />
			<About />
			<Theory />
			<Safety />
			<Pricing />
			<Footer showAdditionalInfo={true} onMainPage={true} />
		</Box>
	)
}

export default HomePageComponent
