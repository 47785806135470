import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { CourseCard } from './CourseCard'
import { useTrainingsContext } from '../../context'
import { DisplayEnum } from 'shared/enums/display.enum'
import { IconImage } from '@Components'
import { Circles } from  'react-loader-spinner'
import Empty from '@Assets/nothing.svg'


type Props = {
	loading: boolean
}

export function CourseList(props: Props ) {
	const { loading } = props
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet))
	const [
		{
			trainingsInfo: { trainings },
		},
	] = useTrainingsContext()


	console.log(trainings)

	return (
		<Stack maxWidth={830} ml={4} alignItems={mobile ? 'center' : 'start'}>
			<Typography fontSize={18} fontFamily='GilroySemiBold' color={theme.palette.info.main} mb={1}>
				BRIDGE VR
			</Typography>
			<Typography fontSize={mobile ? 24 : 48} fontFamily='GilroyBold' color={theme.palette.primary.main} mb={3}>
				Training & Courses
			</Typography>
			<Stack maxWidth={830} width='100%' direction={mobile ? 'column' : 'row'} flexWrap='wrap'>
				{loading && (
					<Stack width='100%'
						py={tablet ? '0' : '150px'}
						px={tablet ? '0' : '200px'}
						mx='auto'
						alignItems='center'
						boxSizing='border-box'>
						<Circles
							height={120}
							width={120}
							color={theme.palette.grey.A200}
							ariaLabel='loading'
					/>
					</Stack>
				)}
				{!loading && (trainings || []).map((training) => (
					<CourseCard
						name={training.title}
						description={training.overviewDescription}
						src={training.overviewImageUrl}
						status={training.status}
						key={training.id}
						id={training.id}
						timeComplete={training.timeComplete}
					/>
				))}
				{!loading && (!trainings || !trainings.length) && (
					<Stack
						width='100%'
						py={tablet ? '0' : '150px'}
						px={tablet ? '0' : '200px'}
						mx='auto'
						alignItems='center'
						boxSizing='border-box'
					>
						<IconImage src={Empty} alt='no item' width='78px' height='78px' />
						<Typography
							fontSize={28}
							mt={3.6}
							fontFamily='GilroyBold'
							textAlign='center'
							color={theme.palette.grey.A400}
						>
							There are no trainings yet.
						</Typography>
					</Stack>
				)}
			</Stack>
		</Stack>
	)
}
