import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { AlertMsg, Footer, Header, IconImage } from '@Components'
import { ContactUsStack } from './components/ContactUsStack'
import { ContactUsForm } from 'shared/components/ContactUsForm'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useHandlers } from './hooks'
import { useContactUsValidationSchema } from '@Hooks'
import { DisplayEnum } from 'shared/enums/display.enum'
import USA from '@Assets/united-states.svg'
import UK from '@Assets/united-kingdom.svg'
import KOREA from '@Assets/south-korea.svg'
import LockOpenIcon from '@mui/icons-material/LockOpen'

const initialValues = { email: '', name: '', message: '', organization: '' }
type Props = {
	onMainPage: boolean
}
export function ContactUsPage(props: Props) {
	const { onMainPage } = props
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const [serverError, setServerError] = useState(false)
	const { handleSubmit } = useHandlers(setServerError)
	const validationSchema = useContactUsValidationSchema()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<ContactUsStack>
			<Header hideNavigationMenu={true} />
			<Stack alignItems='center' flex={1} mt={16} mb={7}>
				{serverError && <AlertMsg icon={<LockOpenIcon />} text='Email doesnt exist' />}
				<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
					<ContactUsForm onMainPage={onMainPage}/>
				</Formik>
				<Stack
					mt={mobile ? 3 : 6}
					width={mobile ? '100%' : 590}
					pl={mobile ? 3 : 6}
					pr={mobile ? 3 : 6}
					boxSizing='border-box'
				>
					<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
						ABOUT COMPANY
					</Typography>
					<Typography fontFamily='GilroyBold' fontSize={mobile ? 24 : 38} color={theme.palette.text.primary}>
						Our experience for you
					</Typography>
					<Typography fontFamily='GilroyRegular' fontSize={16} color={theme.palette.text.secondary} mb={4}>
						The effect is commonly created by VR headsets consisting of a head-mounted display with a small screen in
						front of the eyes, but can also be created through specially designed rooms with multiple large screens.
						Virtual reality typically incorporates auditory and video feedback, but may also allow other types of
						sensory and force feedback through haptic technology.
					</Typography>
					<Stack direction={'row'} flexWrap={'wrap'}>
						<Box display={'flex'} alignItems={'start'} mt={1} mr={1}>
							<IconImage src={USA} width={'30px'} height={'30px'} />
							<Box ml={0.5} textAlign='left'>
								<Typography fontFamily='GilroyBold' fontSize={14} color={theme.palette.text.primary} lineHeight={1}>
									USA
								</Typography>
								<Typography fontFamily='GilroyRegular' fontSize={14} color={theme.palette.text.secondary}>
									+1 206.407.3774
								</Typography>
								<Typography fontFamily='GilroyRegular' fontSize={14} color={theme.palette.text.secondary}>
									2950 Buskirk Ave, Suite 300
									<br />
									Walnut Creek, CA 94597
								</Typography>
							</Box>
						</Box>
						<Box display={'flex'} alignItems={'start'} mt={1} mr={1}>
							<IconImage src={UK} width={'30px'} height={'30px'} />
							<Box ml={0.5} textAlign='left'>
								<Typography fontFamily='GilroyBold' fontSize={14} color={theme.palette.text.primary} lineHeight={1}>
									UK
								</Typography>
								<Typography fontFamily='GilroyRegular' fontSize={14} color={theme.palette.text.secondary}>
									+44 (0) 3330 119 683
								</Typography>
								<Typography fontFamily='GilroyRegular' fontSize={14} color={theme.palette.text.secondary}>
									Cambridge House, Henry Street
									<br />
									Bath BA1 1BT, United Kingdom
								</Typography>
							</Box>
						</Box>
						<Box display={'flex'} alignItems={'start'} mt={1}>
							<IconImage src={KOREA} width={'30px'} height={'30px'} />
							<Box ml={0.5} textAlign='left'>
								<Typography fontFamily='GilroyBold' fontSize={14} color={theme.palette.text.primary} lineHeight={1}>
									Korea (South)
								</Typography>
								<Typography fontFamily='GilroyRegular' fontSize={14} color={theme.palette.text.secondary}>
									+82 2 3478 4488
								</Typography>
								<Typography fontFamily='GilroyRegular' fontSize={14} color={theme.palette.text.secondary}>
									Kyobo Building, 15th Floor, 1 Jongno,
									<br />
									Jongno-gu, Seoul 03154, Korea
								</Typography>
							</Box>
						</Box>
					</Stack>
				</Stack>
			</Stack>
			<Footer onMainPage={false}/>
		</ContactUsStack>
	)
}
