import {
	ReportingCards,
	ReportingFiltersWithTable,
	StyledReportingsGrid,
	StyledReportingsTableHeaderStack,
} from '../../components'
import { useData } from '../hooks'

export function ParticipantsDetailsContent() {
	const { cards, filtersSubTitles, filtersTitles, searchPlaceholder, totalPages } =
		useData()

	return (
		<StyledReportingsGrid>
			<StyledReportingsTableHeaderStack>
				<ReportingCards cards={cards} />
			</StyledReportingsTableHeaderStack>
			<ReportingFiltersWithTable
				filtersTitles={filtersTitles}
				searchPlaceholder={searchPlaceholder}
				heading='DETAILED INFORMATION PER EACH TRAINING OR ATTEMPT'
				filtersSubTitles={filtersSubTitles}
				totalPages={totalPages}
				entity='trainings'
			>
				<></>
			</ReportingFiltersWithTable>
		</StyledReportingsGrid>
	)
}
