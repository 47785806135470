import { Typography, useTheme } from '@mui/material'

export function ChildrensPrivacy() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color='primary'>
				<u>Children's Privacy</u>
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable
				information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child
				has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data
				from anyone under the age of 13 without verification of parental consent, We take steps to remove that
				information from Our servers.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				If We need to rely on consent as a legal basis for processing Your information and Your country requires consent
				from a parent, We may require Your parent's consent before We collect and use that information.
			</Typography>
		</>
	)
}
