import Logo from '@Assets/bridge_logo.png'

import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import { useHandlers } from './hooks'
import { BackgroundStackVariant, BoxBackgroundStack } from '../components'
import { CustomTextField, IconImage } from '@Components'
import { DisplayEnum } from 'shared/enums/display.enum'

const initialValues = {
	code: '',
	password: '',
	host: window.location.origin,
}

export function CodeVerificationPage() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { handleCancelClick, handleSubmit } = useHandlers()

	return (
		<BackgroundStackVariant
			width='100%'
			height='100%'
			minHeight='100vh'
			pb={xlDisplay ? 14.5 : 0}
			px={mobile ? 3 : 0}
			boxSizing={mobile ? 'border-box' : 'content-box'}
		>
			<BoxBackgroundStack
				px={mobile ? 3 : 5}
				pt={mobile ? 3 : 6}
				pb={mobile ? 3 : 3.5}
				borderRadius='10px'
				mt={mobile ? 5 : 14.5}
				ml={xlDisplay ? 13 : mobile ? 0 : 'auto'}
				mr={xlDisplay || mobile ? 0 : 'auto'}
				width={mobile ? '100%' : 530}
				boxSizing='border-box'
			>
				<IconImage src={Logo} alt='logo' width='154px' />
				<Typography fontSize={mobile ? 24 : 34} variant='h4' color='primary' fontFamily='GilroyBold' mt={5}>
					Reset Password
				</Typography>
				<Typography
					variant='body1'
					color={theme.palette.text.secondary}
					mt={1}
					fontFamily='GilroyMedium'
					letterSpacing={0}
				>
					A password reset code will be sent to the email address you provided during registration.
				</Typography>
				<Formik initialValues={initialValues} onSubmit={handleSubmit}>
					<Form>
						<Box mt={3}>
							<CustomTextField<{ password: string; code: string }> id='code' name='code' label='CODE' />
						</Box>
						<Box mt={3}>
							<CustomTextField<{ password: string; code: string }>
								id='password'
								name='password'
								label='PASSWORD'
								type='password'
								placeholder='************'
							/>
						</Box>
						<Stack direction={mobile ? 'column-reverse' : 'row'} mt={3} spacing={2}>
							<Button variant='rounded_contained__auth_secondary' onClick={handleCancelClick}>
								CANCEL
							</Button>
							<Button variant='rounded_contained__auth_success' fullWidth type='submit'>
								SET NEW PASSWORD
							</Button>
						</Stack>
					</Form>
				</Formik>
			</BoxBackgroundStack>
		</BackgroundStackVariant>
	)
}
