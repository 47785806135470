import { Stack, useMediaQuery, useTheme } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'
import { CourseList } from './CourseList'
import { CourseStatistic } from './CourseStatistic'

type Props = {
	loading: boolean
}

export function CourseWrapperComponent(props: Props) {
	const { loading } = props
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	
	return (
		<Stack
			width={xlDisplay ? 1180 : '100%'}
			p={xlDisplay || mobile ? 0 : '0 30px'}
			mx='auto'
			mt={mobile ? 4 : 15.5}
			mb={mobile ? 3 : 14}
			direction={mobile ? 'column' : 'row'}
			alignItems={mobile ? 'center' : 'start'}
			spacing={4}
			boxSizing='border-box'
		>
			<CourseStatistic />
			<CourseList loading={loading} />
		</Stack>
	)
}
