import type { Subscription } from '../../../typings'
import { format } from 'date-fns'
import { RolesEnum, SubscriptionPlansEnum, SubscriptionStatusEnum } from '@Enums'
import { useToken } from '@Hooks'

export function useData(subscription: Subscription) {
	const user = useToken()
	const percent = `${Math.round(subscription.activeUsersCount / subscription.maxUserLimit)}%`
	const period = `${format(new Date(subscription.from), 'MM/dd/yyyy')} - ${format(
		new Date(subscription.to),
		'MM/dd/yyyy',
	)}`

	const price =
		subscription.subscriptionType === SubscriptionPlansEnum.Standard
			? 27
			: subscription.subscriptionType === SubscriptionPlansEnum.Pro
			? 40
			: 1000

	const disableUnsubscribe = subscription.subscriptionStatus === 5

	const currentSubscriptionStatus = (
		subscription.subscriptionStatus === 5 ? 'Runs Out' : SubscriptionStatusEnum[subscription.subscriptionStatus]
	).toUpperCase()

	const statusColor: 'warning' | 'success' = subscription.subscriptionStatus === 5 ? 'warning' : 'success'

	const enableOptions = user.role === RolesEnum.SuperAdmin

	return {
		percent,
		period,
		price,
		disableUnsubscribe,
		currentSubscriptionStatus,
		statusColor,
		enableOptions,
	}
}
