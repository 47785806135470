import { Typography, useTheme } from '@mui/material'

export function SeverabilityAndWaiver() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color='primary'>
				<u>Severability and Waiver</u>
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Severability
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and
				interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law
				and the remaining provisions will continue in full force and effect.
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Waiver
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Except as provided herein, the failure to exercise a right or to require performance of an obligation under
				these Terms shall not effect a party's ability to exercise such right or require such performance at any time
				thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.
			</Typography>
		</>
	)
}
