import { differenceInMinutes, isPast, parse, parseISO } from 'date-fns'
import { GroupOnlineTraining } from '../typings'

export function getAllWeekDays(startDate: Date, endDate: Date) {
	const weekDays: { dayName: string; dayDate: number; month: string; date: string; today: boolean }[] = []
	const currentDate = new Date(startDate)

	while (currentDate <= endDate) {
		const dayName = getDayName(currentDate.getDay())
		const dayDate = currentDate.getDate()
		const month = getMonthName(currentDate.getMonth())
		const date = `${dayName} ${month} ${dayDate} ${currentDate.getFullYear()}`

		const today = isToday(currentDate)

		weekDays.push({ dayName, dayDate, month, date, today })

		currentDate.setDate(currentDate.getDate() + 1)
	}

	return weekDays
}

function isToday(date: Date) {
	const today = new Date()
	return (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	)
}

function getDayName(day: number) {
	const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
	return dayNames[day]
}

function getMonthName(month: number) {
	const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
	return monthNames[month]
}

export function getTimeLinePosition(currentTime: Date) {
	const startAM = new Date(currentTime)

	startAM.setHours(0, 0, 0, 0)

	return differenceInMinutes(currentTime, startAM)
}

export function matchTrainings(groups: GroupOnlineTraining[], date: string) {
	const arr = groups.filter((group: GroupOnlineTraining) => {
		const date1 = parseISO(group.groupDate)
		const date2 = parse(date, 'EEE MMM dd yyyy', new Date())
		return date1.toDateString() === date2.toDateString()
	})

	return arr[0]?.trainings ?? []
}

export function diffInMinutes(start: string, end: string) {
	const date1 = parseISO(start)
	const date2 = parseISO(end)

	return differenceInMinutes(date2, date1)
}

export function pastTraining(date: string) {
	return !!isPast(parseISO(date))
}
