import Logo from '@Assets/bridge_logo.png'

import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Form, Formik } from 'formik'
import { useHandlers } from './hooks'
import { BackgroundStackVariant } from '../components'
import { BoxBackgroundStack } from '../components'
import { CustomTextField, IconImage } from '@Components'
import { DisplayEnum } from 'shared/enums/display.enum'

export function CreatePasswordPage() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { handleSubmit } = useHandlers()

	return (
		<BackgroundStackVariant
			width='100%'
			height='100%'
			minHeight='100vh'
			pb={xlDisplay ? 14.5 : 0}
			px={mobile ? 3 : 0}
			boxSizing={mobile ? 'border-box' : 'content-box'}
		>
			<BoxBackgroundStack
				px={mobile ? 3 : 5}
				pt={mobile ? 3 : 6}
				pb={mobile ? 3 : 3.5}
				borderRadius='10px'
				mt={mobile ? 5 : 7}
				ml={xlDisplay ? 13 : mobile ? 0 : 'auto'}
				mr={xlDisplay || mobile ? 0 : 'auto'}
				width={mobile ? '100%' : 530}
				boxSizing='border-box'
			>
				<IconImage src={Logo} alt='logo' width='154px' />

				<Typography fontSize={mobile ? 24 : 34} variant='h4' color='primary' fontFamily='GilroyBold' mt={5}>
					Create Password
				</Typography>
				<Typography
					variant='body1'
					color={theme.palette.text.secondary}
					mt={2}
					fontFamily='GilroyMedium'
					letterSpacing={0}
				>
					Create a strong password consisting of characters (a-z), numbers (0-9) and special characters (!"#;%:?)
				</Typography>

				<Formik initialValues={{ password: '' }} onSubmit={handleSubmit}>
					<Form>
						<Box mt={3}>
							<CustomTextField<{ password: string }>
								id='password'
								name='password'
								label='PASSWORD'
								type='password'
								placeholder='************'
							/>
						</Box>
						<Stack direction={mobile ? 'column-reverse' : 'row'} mt={3} spacing={2}>
							<Button variant='rounded_contained__auth_secondary'>CANCEL</Button>
							<Button variant='rounded_contained__auth_success' fullWidth type='submit'>
								CREATE PASSWORD
							</Button>
						</Stack>
					</Form>
				</Formik>
			</BoxBackgroundStack>
		</BackgroundStackVariant>
	)
}
