import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Formik } from 'formik'
import { ContactUsForm } from './ContactUsForm'
import { useHandlers } from '../../features/contactUsPage/hooks'
import { useState } from 'react'
import { useContactUsValidationSchema } from '@Hooks'
import { AlertMsg } from './Alert'
import { DisplayEnum } from 'shared/enums/display.enum'
import { IconImage } from './IconImage'
import USA from '@Assets/united-states.svg'
import UK from '@Assets/united-kingdom.svg'
import KOREA from '@Assets/south-korea.svg'
import LockOpenIcon from '@mui/icons-material/LockOpen'

const initialValues = { email: '', name: '', message: '', organization: '' }

type Props = {
	onMainPage: boolean
}

export function AdditionalInfo(props: Props) {
	const { onMainPage } = props
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const [serverError, setServerError] = useState(false)
	const { handleSubmit } = useHandlers(setServerError)
	const validationSchema = useContactUsValidationSchema()

	return (
		<Stack
			mt={xlDisplay ? 7 : 0}
			pt={xlDisplay ? 11 : 0}
			pb={xlDisplay ? 11 : 3}
			pl={xlDisplay ? 13 : 0}
			position='relative'
			bgcolor={theme.palette.background.paper}
			width='100%'
			boxSizing='border-box'
			alignItems={xlDisplay ? 'flex-start' : 'center'}
		>
			<Stack width={mobile ? 315 : xlDisplay ? 'calc(100% - 730px)' : 560} textAlign={mobile ? 'center' : 'left'}>
				<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary} textTransform='uppercase'>
					About Us
				</Typography>
				<Typography fontFamily='GilroyBold' fontSize={mobile ? 28 : 36} color={theme.palette.text.primary} mb={1}>
					Bridge VR has been developed by <br /> Bridge Learning Tech. 
				</Typography>
				<Typography fontFamily='GilroyRegular' fontSize={16} color={theme.palette.text.secondary}>
					Based out of California and England, our company strives to develop <br />  the new ways in which your employees can learn, <br /> including the use of state-of-the-art Virtual Reality technology. 
				</Typography>
				<Typography fontFamily='GilroyRegular' mt={1} fontSize={16} color={theme.palette.text.secondary} mb={6}>
					Speak to us about your training needs and how we can assist you.
				</Typography>
				<Stack direction={'row'} flexWrap={'wrap'} rowGap={3} columnGap={3}>
					<Box display={'flex'} alignItems={'start'} >
						<IconImage src={USA} width={'30px'} height={'30px'} />
						<Box ml={1} textAlign='left'>
							<Typography fontFamily='GilroyBold' fontSize={16} mb={0.5} color={theme.palette.text.primary} lineHeight={1}>
								USA
							</Typography>
							<Typography fontFamily='GilroyRegular' fontSize={16} color={theme.palette.text.secondary}>
								+1 206.407.3774
							</Typography>
							<Typography fontFamily='GilroyRegular' fontSize={16} color={theme.palette.text.secondary}>
								2950 Buskirk Ave, Suite 300
								<br/>
								Walnut Creek, CA 94597
							</Typography>
						</Box>
					</Box>
					<Box display={'flex'} alignItems={'start'} >
						<IconImage src={UK} width={'30px'} height={'30px'} />
						<Box ml={1} textAlign='left'>
							<Typography fontFamily='GilroyBold' fontSize={16} mb={0.5} color={theme.palette.text.primary} lineHeight={1}>
								UK
							</Typography>
							<Typography fontFamily='GilroyRegular' fontSize={16} color={theme.palette.text.secondary}>
								+44 (0) 3330 119 683
							</Typography>
							<Typography fontFamily='GilroyRegular' fontSize={16} color={theme.palette.text.secondary}>
								Cambridge House, Henry Street
								<br/>
								Bath BA1 1BT, United Kingdom
							</Typography>
						</Box>
					</Box>
					<Box display={'flex'} alignItems={'start'} >
						<IconImage src={KOREA} width={'30px'} height={'30px'} />
						<Box ml={1} textAlign='left'>
							<Typography fontFamily='GilroyBold' fontSize={16} mb={0.5} color={theme.palette.text.primary} lineHeight={1}>
								Korea (South)
							</Typography>
							<Typography fontFamily='GilroyRegular' fontSize={16} color={theme.palette.text.secondary}>
								+82 2 3478 4488
							</Typography>
							<Typography fontFamily='GilroyRegular' fontSize={16} color={theme.palette.text.secondary}>
								Kyobo Building, 15th Floor, 1 Jongno,
								<br/>
								Jongno-gu, Seoul 03154, Korea
							</Typography>
						</Box>
					</Box>
				</Stack>
			</Stack>
			<Box id='contactUs'
				position={xlDisplay ? 'absolute' : 'static'}
				order={xlDisplay ? 0 : -1}
				mt={xlDisplay ? 0 : -15}
				mb={xlDisplay ? 0 : 5}
				right='130px'
				bottom='50px'
			>
				{serverError && <AlertMsg icon={<LockOpenIcon />} text='Email doesnt exist' />}
				<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
					<ContactUsForm onMainPage={onMainPage} />
				</Formik>
			</Box>
		</Stack>
	)
}
