import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'

type Props = {
	color: string
	title: string
	subtitle: string
}

export function BlockHeader(props: Props) {
	const { color, title, subtitle } = props
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	return (
		<Stack mb={1} width={mobile ? '100%' : xlDisplay ? 1040 : 622} mx='auto' textAlign='center'>
			<Typography fontSize={mobile ? 14 : 18} fontFamily='GilroySemiBold' textTransform='uppercase' color={color} mb={1}>
				{title}
			</Typography>
			<Typography fontSize={mobile ? 24 : 48} variant='h3' fontFamily='GilroyBold' color='primary' mb={1}>
				{subtitle}
			</Typography>
		</Stack>
	)
}
