import { Box, Button, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Form } from 'formik'
import { DisplayEnum } from 'shared/enums/display.enum'
import { ForgotPassword, SignUp } from '../components'
import { CustomTextField } from '@Components'
import { TLoginForm } from './typings'
import { useData } from './hooks'

export function LoginForm() {
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { defaultEmail, disableEmail } = useData()

	return (
		<Form>
			<Box position='relative' mt={1} pb={2}>
				<CustomTextField<TLoginForm>
					name='email'
					label='EMAIL'
					id='email'
					type='email'
					placeholder='Please enter your e-mail'
					disabled={disableEmail}
					defaultValue={defaultEmail}
				/>
			</Box>
			<Box position='relative' mt={1} pb={2}>
				<CustomTextField<TLoginForm>
					id='password'
					name='password'
					label='PASSWORD'
					type='password'
					placeholder='Please enter your password '
				/>
			</Box>
			<Stack direction='row' mb={3} mt={mobile ? 2 : 1} justifyContent='space-between'>
				<ForgotPassword
					label={
						<Link href='/reset' underline='none'>
							<Typography color={theme.palette.text.secondary} fontFamily='GilroySemiBold'>
								Forgot password
							</Typography>
						</Link>
					}
				/>
			</Stack>
			<Button variant='rounded_contained__auth_success' fullWidth type='submit'>
				SIGN IN
			</Button>

			<Stack direction='row' alignItems='center' mt={3.5} justifyContent='center'>
				<Typography fontSize={mobile ? 10 : 16} color='primary' fontFamily='GilroySemiBold' variant='body1'>
					Don't have an account?
				</Typography>
				<SignUp
					label={
						<Link href='/register' underline='none'>
							<Typography color='primary' fontFamily='GilroySemiBold' variant='body1'>
								Sign Up
							</Typography>
						</Link>
					}
				/>
				<Typography fontSize={mobile ? 10 : 16} color='primary' fontFamily='GilroySemiBold' variant='body1'>
					for free.
				</Typography>
			</Stack>
		</Form>
	)
}
