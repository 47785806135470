import { Footer, Header } from '@Components'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { ChangesToPrivacyPolicy } from './part/ChangesToPrivacyPolicy'
import { ChildrensPrivacy } from './part/ChildrensPrivacy'
import { ContactUs } from './part/ContactUs'
import { InterpretationAndDefinitions } from './part/InterpretationAndDefinitions'
import { LinksToOtherWebsites } from './part/LinksToOtherWebsites'
import { PersonalData } from './part/PersonalData'

export function PrivacyPolicyPage() {
	const theme = useTheme()

	return (
		<Box bgcolor={theme.palette.background.default}>
			<Header hideNavigationMenu={true} />
			<Box maxWidth={1020} marginX='auto'>
				<Stack padding={4.2} spacing={2} justifyContent='center'>
					<Typography fontFamily='GilroyBold' variant='h3' color='primary'>
						Privacy Policy
					</Typography>
					<Typography variant='body1' color={theme.palette.text.secondary}>
						Last updated: June 21, 2022
					</Typography>
					<Typography variant='body1' color={theme.palette.text.secondary}>
						This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your
						information when You use the Service and tells You about Your privacy rights and how the law protects You.
					</Typography>
					<Typography variant='body1' color={theme.palette.text.secondary}>
						We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
						collection and use of information in accordance with this Privacy Policy.
					</Typography>
					<InterpretationAndDefinitions />
					<PersonalData />
					<ChildrensPrivacy />
					<LinksToOtherWebsites />
					<ChangesToPrivacyPolicy />
					<ContactUs />
				</Stack>
			</Box>
			<Footer onMainPage={false}/>
		</Box>
	)
}
