import { Typography, useTheme } from '@mui/material'

export function GoverningLaw() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color='primary'>
				<u>Governing Law</u>
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the
				Service. Your use of the Application may also be subject to other local, state, national, or international laws.
			</Typography>
		</>
	)
}
