import PriceCardBG from '@Assets/PriceCardBG.png'
import MailForward from '@Assets/email_forward_mail.svg'

import { Box, Stack, Typography, Button, useTheme, useMediaQuery } from '@mui/material'
import { DividerGray, IconImage } from '@Components'
import { useHandlers } from './hooks'
import type { CardOptions } from '@Types'
import { SubscriptionPlansEnum } from '@Enums'
import { DisplayEnum } from 'shared/enums/display.enum'
import { CoverBox } from './components'
import { Fragment } from 'react'

type Props = {
	options: CardOptions
	disabled?: boolean
}

export function PriceCard(props: Props) {
	const { options, disabled } = props
	const showOverlay = disabled
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const bgcolor = options.bgcolor
	const showBestsellerLabel = options.showLabel
	const buttonVariant = options.buttonVariant
	const marginTop = options.marginTop
	const width = options.width
	const height = options.height
	const marginY = options.marginY
	const showContactLabel = options.showContactInfo
	const showPrices = !options.showContactInfo
	const enterpriseCard = options.plan === SubscriptionPlansEnum.Enterprise

	const { handleBuyClick } = useHandlers(options.plan)

	return (
		<Fragment>
			{showBestsellerLabel && (
					<Stack position='absolute' top={-20} left={mobile ? 94 : 140} zIndex={12}>
						<Box pt={0.9} height={41} width={134} boxSizing='border-box' bgcolor={theme.palette.primary.main} borderRadius='48px'>
							<Typography
								fontSize={16}
								textAlign='center'
								fontFamily='GilroyExtraBold'
								color={theme.palette.info.main}
							>
								BEST CHOICE
							</Typography>
						</Box>
						{showOverlay && (
							<Box>
								<CoverBox height={41} width={134} borderRadius='48px' />
							</Box>
						)}
					</Stack>
				)}
			<Stack
				bgcolor={bgcolor}
				width={xlDisplay ? width : mobile ? 315 : 429}
				height={mobile ? '100%' : height}
				borderRadius='16px'
				boxSizing='border-box'
				position='relative'
				overflow='hidden'
			>
				
				{showOverlay && (
					<CoverBox />
				)}
				{showBestsellerLabel && <IconImage src={PriceCardBG} height={mobile ? '600px' : '100%'} width='100%' />}


				<Stack px={2.5} py={options.title === 'Pro' ? 4 : 2.2} mt={marginTop}>
					<Stack direction='column' justifyContent='space-between' height='100%'  >
						<Stack>
							<Typography fontSize={28} fontFamily='GilroyBold' color='primary' mb={0.8} textAlign='center' >
								{options.title}
							</Typography>
							<Typography fontSize={16} fontFamily='GilroyRegular' color={theme.palette.text.secondary} textAlign='center' lineHeight={1.2}>
								{options.description}
							</Typography>
							{enterpriseCard && (
								<Typography fontSize={16} mt={1} fontFamily='GilroyRegular' color={theme.palette.text.secondary} textAlign='center' lineHeight={1.2}>
									{options.subDescription}
								</Typography>
							)}
						</Stack>

						{showContactLabel &&
							<Stack mt={6} mb={1.5} alignItems='center'>
								<IconImage src={MailForward} height='60px' width='75px' />
								<Typography fontSize={15} mt={1} fontFamily='GilroySemiBold' color={theme.palette.primary.main} textAlign='center'>
									CONTACT US TO DISCUSS YOUR REQUIREMENTS AND PRICING
								</Typography>
							</Stack>}
						{showPrices &&
							<Stack>
								<Stack mt={marginY} mb={4.5}>
									<Stack mx='auto' direction='row' alignItems='baseline'>
										<Typography fontSize={26} mr={0.5} fontFamily='GilroyBold' color={theme.palette.text.disabled}>
											$
										</Typography>
										<Typography fontSize={100} fontFamily='GilroyBold' color={theme.palette.primary.main}>
											{options.price}
										</Typography>
										<Typography fontSize={26} fontFamily='GilroyBold' color={theme.palette.text.disabled}>
											USD
										</Typography>
									</Stack>
									<Typography fontSize={15} mt={-3} fontFamily='GilroySemiBold' color={theme.palette.text.secondary} textAlign='center'>
										PER MONTH
									</Typography>
								</Stack>

								<Stack direction='row' mx='auto' spacing={2}>
									<Stack justifyContent='center'>
										<Typography fontSize={15} fontFamily='GilroySemiBold' color={theme.palette.text.secondary}>
											SEAT PRICE
										</Typography>

										<Typography fontSize={26} fontFamily='GilroyBold' color={theme.palette.primary.main}>
											$ {options.seatPrice} USD
										</Typography>
									</Stack>
									<DividerGray orientation='vertical' />
									<Stack>
										<Typography fontSize={15} fontFamily='GilroySemiBold' color={theme.palette.text.secondary}>
											OCULUS USERS
										</Typography>

										<Typography fontSize={26} fontFamily='GilroyBold' color={theme.palette.primary.main}>
											{options.seats} SEATS
										</Typography>
									</Stack>
								</Stack>

							</Stack>}
					</Stack>

					<Stack mt={1.2}>
						<Button variant={buttonVariant} onClick={handleBuyClick} fullWidth>
							{enterpriseCard ? 'CONTACT US' : 'SELECT & BUY'}
						</Button>
						<Box >
							<Typography fontSize={15} mt={1.5} fontFamily='GilroySemiBold' color={theme.palette.text.secondary} textAlign='center'>
								{options.bottomText}
							</Typography>
						</Box>
					</Stack>
				</Stack>
			</Stack>
		</Fragment>
	)
}
