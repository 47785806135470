import TotalIcon from '@Assets/dashboard.svg'
import NotStarted from '@Assets/not_started.svg'
import InProgress from '@Assets/lamp.svg'
import Completed from '@Assets/verified1.svg'
import Person from '@Assets/person.svg'
import PersonLine from '@Assets/person_line.svg'
import { useMemo } from 'react'
import { useReportsContext } from '../../context'
import type { TrainingStatusCard } from '../typings'
import { TrainingStatusesEnum, TrainingTypesEnum } from '../enums'

const totalCard: TrainingStatusCard = {
	title: TrainingStatusesEnum.All,
	icon: TotalIcon,
	isPrimary: true,
	isTotal: true,
	status: TrainingStatusesEnum.All,
	count: 0,
	isTrainingsPage: true,
}

const notStartedCard: TrainingStatusCard = {
	title: TrainingStatusesEnum.NotStarted,
	icon: NotStarted,
	status: TrainingStatusesEnum.NotStarted,
	count: 0,
	isTrainingsPage: true,
}

const inProgressCard: TrainingStatusCard = {
	title: TrainingStatusesEnum.InProgress,
	icon: InProgress,
	status: TrainingStatusesEnum.InProgress,
	count: 0,
	isTrainingsPage: true,
}

const completedCard: TrainingStatusCard = {
	title: TrainingStatusesEnum.Completed,
	icon: Completed,
	status: TrainingStatusesEnum.Completed,
	count: 0,
	isTrainingsPage: true,
}

const filtersTitles = [TrainingTypesEnum.All, TrainingTypesEnum.Technical, TrainingTypesEnum.Service, TrainingTypesEnum.Sales]
const filtersSubTitles = ['Series', 'Series 2', 'Series 3', 'Series 4']

const tableHeadingTitles = [
	{
		title: 'TRAINING MODULES',
		hasFilters: false,
		icon: '',
	},
	{
		title: 'ACTIVE',
		hasFilters: true,
		icon: Person,
	},
	{
		title: 'INACTIVE',
		hasFilters: true,
		icon: PersonLine,
	},
]

const searchPlaceholder = 'SEARCH PARTICIPANT'

export function useData() {
	const [{ statuses, trainings, trainingsFilters }] = useReportsContext()
	const cards: TrainingStatusCard[] = useMemo(
		() =>
			[totalCard, notStartedCard, inProgressCard, completedCard].map((card) => ({
				...card,
				count: statuses[card.status],
				totalCount: card.isTotal ? statuses.users : 0,
			})),
		[statuses],
	)

	return {
		cards,
		filtersTitles,
		filtersSubTitles,
		tableHeadingTitles,
		tableRows: trainings.items,
		totalPages: Math.ceil(trainings.total / trainingsFilters.limit),
		searchPlaceholder,
	}
}
