import Basic from '@Assets/Basic4.jpg'
import Starting from '@Assets/Starting4.jpg'
import Magnetism from '@Assets/Magnetism2.jpg'
import OhmWatt from '@Assets/OhmWatt1.jpg'

import { Box, Button, Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Slide } from './Slide'
import { BlockHeader } from './BlockHeader'
import { AboutStack } from './AboutStack'
import { useHandlers } from './hooks'
import { DisplayEnum } from 'shared/enums/display.enum'

export function About() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { handleTrainings } = useHandlers()

	return (
		<AboutStack pt={4.2} mb={3} mx='auto' width='100%' overflow='hidden' id='about'>
			<BlockHeader
				color={theme.palette.info.main}
				title='Virtual Reality for Technical Training'
				subtitle='Immerse. Learn. Succeed.'
			/>
			<Stack mb={2} width={mobile ? '100%' : xlDisplay ? 958 : 622} mx='auto' textAlign='center'>
				<Typography
					fontSize={mobile ? 14 : 16}
					padding={mobile ? '0 30px' : 0}
					variant='body1'
					color={theme.palette.text.secondary}
					fontFamily='GilroyRegular'
				>
					The immersive experience Virtual Reality offers is like no other form of technical training currently
					available.
					<br/>
					With the ability to completely engage your senses, VR offers an interactive alternative to standard training courses.
					<br />
					This allows technicians to learn vital new skills more quickly and without needing to leave their
					dealership.
				</Typography>
			</Stack>

			<Container>
				<Stack
					mx='auto'
					direction='row'
					justifyContent={xlDisplay ? 'flex-start' : 'center'}
					flexWrap={xlDisplay ? 'nowrap' : 'wrap'}
					mb={2}
				>
					<Slide
						title='Basic Electrical circuits'
						description='This course provides the benefit of learning basic circuits and electrical principals right down to what electricity is in its basic form.'
						image={Basic}
					/>
					<Slide
						title='Starting System'
						description='You will be working through various stations to gain a better understanding of function and design of the basic components of a vehicle starting system.'
						image={Starting}
					/>
					<Slide
						title='Magnetism'
						description='By following the logical steps in the VR space, you have the opportunity gain a better understanding of magnetism and how it is being applied so once you complete each section you can move on to the next or exit the space if you choose to.'
						image={Magnetism}
					/>
					<Slide
						title='Ohm’s and Watt’s Laws'
						description='Ohm’s and Watt’s Laws are an important part of the technologies we work with every day. This course will provide a foundational understanding of Ohm’s and Watt’s Laws, starting with the basics.'
						image={OhmWatt}
					/>
				</Stack>
				<Box mt={1} mb={5} display='flex' justifyContent='center'>
					<Button variant='rounded_contained__black_text' onClick={handleTrainings}>
						GO TO COURSES
					</Button>
				</Box>
			</Container>
		</AboutStack>
	)
}
