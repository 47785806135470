import { Box, Link, Typography, useTheme } from '@mui/material'
import { StyledTableCell, StyledTableRow } from '../../components'
import { TrainingsDetailsTableRow } from '../typings'


type Props = {
	tableRow: TrainingsDetailsTableRow
}

export function TrainingsDetailsRow(props: Props) {
	const { tableRow } = props
	const theme = useTheme()

	return (
		<StyledTableRow key={tableRow.name}>
			<StyledTableCell >
				<Link href={''} underline='none' >
					<Box>
					<Typography fontFamily='GilroySemiBold' fontSize={18} color={theme.palette.text.secondary}>
						{tableRow.name}
					</Typography>
					<Typography fontFamily='GilroyMedium' fontSize={14} color={theme.palette.text.secondary}>
						{tableRow.email}
					</Typography>
					</Box>
				</Link>
			</StyledTableCell>
			<StyledTableCell align='left'>
				<Link href={''} underline='none' >
					<Typography fontFamily='GilroyRegular' fontSize={18} color={theme.palette.text.secondary}>
						{tableRow.attempts}
					</Typography>
				</Link>
			</StyledTableCell>
			<StyledTableCell align='left'>
				<Link href={''} underline='none' >
					<Typography fontFamily='GilroyRegular' fontSize={18} color={theme.palette.text.secondary}>
						{tableRow.startDate}
					</Typography>
				</Link>
			</StyledTableCell>
			<StyledTableCell align='left'>
				<Link href={''} underline='none' >
					<Typography fontFamily='GilroyRegular' fontSize={18} color={theme.palette.text.secondary}>
						{tableRow.endDate}
					</Typography>
				</Link>
			</StyledTableCell>
			<StyledTableCell align='left'>
				<Link href={''} underline='none' >
					<Typography fontFamily='GilroyRegular' fontSize={18} color={theme.palette.text.secondary}>
						{tableRow.executionTime}
					</Typography>
				</Link>
			</StyledTableCell>
		</StyledTableRow>

	)
}
