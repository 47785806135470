import { Dialog, DialogContent, DialogTitle, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import CloseIcon from '@mui/icons-material/Close'
import { Formik } from 'formik'
import { AddUserForm } from './AddUserForm'
import { useData, useHandlers } from './hooks'
import { useState } from 'react'
import { ValidationSchema } from './validationSchema'
import { LeftPartModalStack } from './LeftPartModalStack'
import { ModalWrapper } from './ModalWrapper'
import { UserRoleDescription } from './UserRoleDescription'
import { DisplayEnum } from 'shared/enums/display.enum'

export function AddUserPopup() {
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const [showError, setShowError] = useState({})
	const { addUserIsOpen, subscriptionId, defaultRole } = useData()
	const [submitting, setSubmitting] = useState(false)
	const { handlePopupClose, handleCreateUser } = useHandlers(setShowError)

	return (
		<Dialog open={addUserIsOpen} maxWidth={'lg'} fullWidth={true} disableScrollLock={true}>
			<ModalWrapper direction={mobile ? 'column' : 'row'}>
				<LeftPartModalStack direction='column' maxWidth={496}>
					<DialogTitle>
						<Stack direction='row' spacing={2} mb={1.5} alignItems='center'>
							<Stack color={theme.palette.success.main} alignItems='center' justifyContent='center'>
								<PersonAddIcon fontSize='medium' />
							</Stack>
							<Typography variant='h5' color='primary' fontFamily='GilroyBold' flex={1}>
								Add User
							</Typography>
							<Stack color={theme.palette.text.secondary} alignItems='center' justifyContent='center'>
								<CloseIcon fontSize='medium' onClick={handlePopupClose} cursor='pointer' />
							</Stack>
						</Stack>
					</DialogTitle>
					<DialogContent>
						<Typography variant='body1' color={theme.palette.text.secondary} fontFamily='GilroyMedium' mb={3}>
							After sending the invitation, the user will receive an email with detailed instructions on how to activate
							their participation in the subscription and start training.
						</Typography>
						<Formik
							initialValues={{ withOculus: false, email: '', role: defaultRole, subscriptionId }}
							onSubmit={(values) => handleCreateUser(values, setSubmitting)}
							validationSchema={ValidationSchema}
						>
							<AddUserForm showError={showError} submitting={submitting} setShowError={setShowError} />
						</Formik>
					</DialogContent>
				</LeftPartModalStack>
				<UserRoleDescription />
			</ModalWrapper>
		</Dialog>
	)
}
