import { useTrainingsContext } from '../../context'
import { useEffect } from 'react'
import { fetchTrainingsEffect } from '../../effects'


export function useFetchTrainingsOnMount(setLoading: (value: boolean) => void) {
	const [, dispatch] = useTrainingsContext()

	useEffect(() => {
		let mounted = true

		const execute = async () => {
			setLoading(true)
			const response = await fetchTrainingsEffect()
			setLoading(false)
			if (!mounted || response.status === 'error') return

			dispatch(response.action)
		}
		
		execute()

		return () => {
			mounted = false
		}
	}, [dispatch])
}
