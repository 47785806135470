import { useFetchSubscriptions } from '../hooks'
import type { ReactElement } from 'react'
import { Fragment } from 'react'

type Props = {
	children: ReactElement
}

export function DataManager(props: Props) {
	const { children } = props

	// useFetchSubscriptions()

	return <Fragment>{children}</Fragment>
}
