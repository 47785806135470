import { RequestService } from '@Services'
import { URLS } from '@Utils'
import { CreateTraining } from '../typings'

export async function createTrainingEffect(body: CreateTraining) {
	const response = await RequestService.post<CreateTraining, null>(URLS.createTraining, body, true)
  if (response.status === 200) {
		return response.data
	}
}
