export const knownRoutes: string[] = [
	'/',
	'/subscriptions/all',
	'/subscriptions/unsubscribe/:id',
	'/subscriptions/manage/:id',
	'/subscriptions',
	'/payment',
	'/payment/success',
	'/dashboard',
	'/trainings',
	'/trainings/:id',
	'/contact-us',
	'/privacy-policy',
	'/terms-and-conditions',
	'/cookie',
	'/login',
	'/register',
	'/register/:email',
	'/register/:email/:company',
	'/reset',
	'/verification',
	'/codesent',
	'/createpass/:code',
	'/passcreated',
	'/subscriptions/unsubscribed',
	'/reporting',
	'/reporting/participants',
	'/reporting/details',
	'/reporting/participants/details',
	'/create-training',
	'/online-trainings',
]
