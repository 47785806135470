import { BpCheckedIcon, BpIcon, StyledCheckbox } from '@Components'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { AssetsItem } from 'features/trainings/typings'
import { DisplayEnum } from 'shared/enums/display.enum'

type Props = {
	asset: AssetsItem
	index: number
	selectedAssets: AssetsItem[]
	selectAsset: (event: any, asset: AssetsItem) => void
}

export function AssetItem(props: Props) {
	const { asset, index, selectedAssets, selectAsset } = props
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))

	const checkSelectedAssets = (asset: AssetsItem): boolean => {
		return !!selectedAssets.find((a: AssetsItem) => a.id === asset.id)
	}

	return (
		<Box
			key={asset.id}
			display='flex'
			alignItems='center'
			justifyContent='flex-start'
			mb='3px'
			width={xlDisplay ? '330px' : '100%'}
			bgcolor={checkSelectedAssets(asset) ? 'rgba(33, 167, 111, 0.3)' : '#464354'}
			borderRadius='5px'
			mr={index === 0 || index % 2 === 0 ? '3px' : '0px'}
			py='3px'
      boxSizing='border-box'
		>
			<StyledCheckbox
				disableRipple
				color='default'
				checkedIcon={<BpCheckedIcon />}
				checked={checkSelectedAssets(asset)}
				icon={<BpIcon />}
				inputProps={{ 'aria-label': 'Checkbox demo' }}
				onChange={(event) => selectAsset(event, asset)}
				sx={{ span: { width: 24, height: 24, '::before': { width: 24, height: 24 } } }}
			/>
			<Box
				minWidth='72px'
				width='72px'
				height='43px'
				borderRadius='5px'
				mr='10px'
				sx={{
					backgroundImage: asset.image ? `url(${asset.image})` : 'none',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					boxSizing: 'border-box',
				}}
			></Box>
			<Typography
				fontSize={16}
				fontFamily='GilroyMedium'
				color='rgba(255, 255, 255, 0.7)'
				lineHeight='1'
				overflow='hidden'
				whiteSpace='nowrap'
				textOverflow='ellipsis'
			>
				{asset.name}
			</Typography>
		</Box>
	)
}
