import { IconImage } from '@Components'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { StyledContainer } from './StyledContainer'
import { StyledLineClamp } from './StyledLineClamp'
import { useHandlers } from './hooks'
import { TrainingStatusEnum } from '../../utils/enums'
import { useSharedContext } from '@Context'
import { RolesEnum } from '@Enums'

import Background from '@Assets/CourseCard.svg'
import { DisplayEnum } from 'shared/enums/display.enum'

type Props = {
	name: string
	description: string
	src: string
	status: TrainingStatusEnum
	time?: string
	id: string
	timeComplete?: string
}

export function CourseCard(props: Props) {
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { name, description, src, status, timeComplete, id } = props
	const { handleTrainingCardClick } = useHandlers(id)
	const [{ user }] = useSharedContext()
	const isGuest = (user?.role || RolesEnum.Guest) === RolesEnum.Guest
	const showStatus = !isGuest && user?.withOculus
	const addBackgoundImg = (elem: any) => elem.style.visibility = 'visible'

	return (
		<StyledContainer
			onClick={(e: any) => {
				if (mobile) {
					addBackgoundImg(e.target.parentNode.childNodes[0])
					return setTimeout(() => {
						handleTrainingCardClick()
					}, 1000)
				}
				handleTrainingCardClick()
			}}
			position='relative'
		>
			<Box position='absolute' top={-27} left={-30} zIndex={1} visibility='hidden'>
				<IconImage src={Background} alt='background' width='300px' />
			</Box>
			<Box borderRadius='6px' overflow='hidden' boxSizing='border-box' zIndex={2}>
				<IconImage src={src} alt={name} width='250px' height='153px' />
			</Box>
			{showStatus && status === TrainingStatusEnum.New && (
				<Typography fontFamily='GilroySemiBold' color={theme.palette.info.main} zIndex={2}>
					New
				</Typography>
			)}
			{showStatus && status === TrainingStatusEnum.InProgress && (
				<Typography fontFamily='GilroySemiBold' color={theme.palette.warning.main} zIndex={2}>
					In Progress
				</Typography>
			)}
			{showStatus && status === TrainingStatusEnum.Complete && (
				<Typography fontFamily='GilroySemiBold' color={theme.palette.success.main} zIndex={2}>
					Completed:{' '}
					<Typography component={'span'} fontFamily='GilroySemiBold' color={theme.palette.text.primary} zIndex={2}>
						{timeComplete}
					</Typography>
				</Typography>
			)}
			<Typography fontSize={22} fontFamily='GilroyBold' color={theme.palette.primary.main} noWrap zIndex={2}>
				{name}
			</Typography>
			<StyledLineClamp color={theme.palette.text.secondary} zIndex={2}>
				{description}
			</StyledLineClamp>
		</StyledContainer>
	)
}
