import { Box, Typography, useTheme } from '@mui/material'
import type { Payment } from '../../typings'
import { StyledGrid } from '../StyledGrid'

import { useData } from './hooks'

type Props = {
	payment: Payment
}

export function SubscriptionsTableRowMobile(props: Props) {
	const theme = useTheme()
	const { payment } = props
	const { name, period, price, payDate } = useData(payment)

	return (
		<StyledGrid direction={'column'} container borderTop='1px solid #FFFFFF57' width='100%' px={3} py={3}>
			<Box>
				<Typography fontSize='14px' fontFamily='GilroyRegular' color={theme.palette.text.secondary} mb={0.5}>
					Subscription
				</Typography>
				<Typography fontSize='14px' fontFamily='GilroySemiBold' color='primary' mb={1.5}>
					{name}
				</Typography>
			</Box>
			<Box>
				<Typography fontSize='14px' fontFamily='GilroyRegular' color={theme.palette.text.secondary} mb={0.5}>
					Period:
				</Typography>
				<Typography fontSize='14px' fontFamily='GilroySemiBold' color='primary' mb={1.5}>
					{period}
				</Typography>
			</Box>
			<Box>
				<Typography fontSize='14px' fontFamily='GilroyRegular' color={theme.palette.text.secondary} mb={0.5}>
					Payment amount
				</Typography>
				<Typography fontSize='14px' fontFamily='GilroySemiBold' color='primary' mb={1.5}>
					{price}
				</Typography>
			</Box>
			<Box>
				<Typography fontSize='14px' fontFamily='GilroyRegular' color={theme.palette.text.secondary} mb={0.5}>
					Payment date
				</Typography>
				<Typography fontSize='14px' fontFamily='GilroySemiBold' color='primary'>
					{payDate}
				</Typography>
			</Box>
		</StyledGrid>
	)
}
