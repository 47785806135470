import { Footer, Header, IconImage } from '@Components'
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { HeaderCreateTrainingPage } from './components/HeaderCreateTraining'
import { CardBlock } from '../components/CardBlock'
import { Formik } from 'formik'
import { FormCreateTraining } from './components/FormCreateTraining'
import { CreateTrainingValidationSchema } from './components/validationSchema'
import { useEffect, useRef, useState } from 'react'
import { DataManager } from './DataManager'
import { AssetsItem } from '../typings'
import { AssetsList } from './components/AssetsList'
import { prepareCreateTrainingPayload } from '../utils/utils'
import { createTrainingEffect } from '../effects/createTraining.effect'
import { useNavigate } from 'react-router'
import { DisplayEnum } from '@Enums'
import { useLocation } from 'react-router-dom'
import { useHandlers } from './hooks'

import Assets from '@Assets/file-text.svg'

export type CreateTrainingModel = {
	id?: string
	title: string
	description: string
	date: string
	startTime: string
	duration: string
	cover: string
	assets: AssetsItem[]
	users: []
}

const initialValues: CreateTrainingModel = {
	title: '',
	description: '',
	date: '',
	startTime: '',
	duration: '30',
	cover: '',
	assets: [],
	users: [],
}

export function CreateTrainingPage() {
	const theme = useTheme()
	const formikRef = useRef<any>()
	const [loading, setLoading] = useState<boolean>(false)
	const [selectedTrainingLocal] = useState<CreateTrainingModel>(
		localStorage.getItem('selectedTrainingLocal')
			? JSON.parse(localStorage.getItem('selectedTrainingLocal') || '')
			: null,
	)
	const navigate = useNavigate()
	const location = useLocation()
	const viewMode = location.state.viewMode
	const weekRange = location.state.weekRange
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const { deleteTraining } = useHandlers()

	const createTraining = () => {
		if (formikRef.current) {
			formikRef.current.submitForm()
		}
	}

	const cancelTraining = () => {
		navigate('/online-trainings', { state: { viewMode, weekRange } })
	}

	const deleteEvent = (id: string) => {
		deleteTraining(id, setLoading)
		navigate('/online-trainings', { state: { viewMode, weekRange } })
	}

	const onSubmit = async (value: any) => {
		const data = prepareCreateTrainingPayload(value)
		await createTrainingEffect(data)
		navigate('/online-trainings', { state: { viewMode, weekRange } })
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<DataManager setLoading={setLoading}>
			<Stack bgcolor={theme.palette.background.default}>
				<Header hideNavigationMenu />
				<Formik
					innerRef={formikRef}
					initialValues={selectedTrainingLocal || initialValues}
					validationSchema={CreateTrainingValidationSchema}
					onSubmit={onSubmit}
					enableReinitialize={true}
				>
					<Stack
						px={xlDisplay ? 13 : 3}
						pb={xlDisplay ? 10 : 0}
						minHeight='calc(100vh - 272.5px)'
						boxSizing='border-box'
					>
						<HeaderCreateTrainingPage
							trainingId={selectedTrainingLocal?.id}
							updateMode={!!selectedTrainingLocal?.id}
							createEvent={createTraining}
							cancelEvent={cancelTraining}
							deleteEvent={deleteEvent}
						/>
						<CardBlock>
							<FormCreateTraining initialValues={selectedTrainingLocal || initialValues} />
						</CardBlock>
						<Stack
							mt={xlDisplay ? 3 : 2}
							mb={1.5}
							pl={xlDisplay ? 6 : 'auto'}
							flexDirection='row'
							justifyContent={xlDisplay ? 'flex-start' : 'center'}
						>
							<Box display='flex' alignItems='center' position='relative'>
								<Box display='flex' alignItems='center' mr={1}>
									<IconImage src={Assets} alt='assets' height='16px' />
								</Box>
								<Typography fontSize={16} fontFamily='GilroySemiBold' color='#fff' lineHeight='1'>
									Assets
								</Typography>
								<Box
									bgcolor='#fff'
									borderRadius='10px 10px 0 0'
									position='absolute'
									top='27px'
									left='0'
									right='0'
									height='3px'
								></Box>
							</Box>
						</Stack>
						<CardBlock>
							<AssetsList assetsSelect={selectedTrainingLocal?.assets || []} />
						</CardBlock>
					</Stack>
				</Formik>
				<Footer onMainPage={false} />
			</Stack>
		</DataManager>
	)
}
