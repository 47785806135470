import { IconImage } from '@Components'
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useToken } from '@Hooks'
import { DisplayEnum, RolesEnum } from '@Enums'
import { ViewModeEnum } from 'features/trainings/enums'

import Plus from '@Assets/plus.svg'
import Calendar from '@Assets/calendar.svg'
import CalendarActive from '@Assets/calendar_active.svg'
import List from '@Assets/list.svg'
import ListActive from '@Assets/list_active.svg'
import { WeekNavigator } from './WeekNavigator'

type Props = {
	viewMode: ViewModeEnum
	weekRange: Date
	changeViewMode: (viewMode: ViewModeEnum) => void
	createEvent: () => void
	handleStartAndEndDate: (startDate: Date, endDate: Date) => void
	changedWeerRange: (week: Date) => void
}

export function HeaderOnlineTrainingsPage(props: Props) {
	const theme = useTheme()
	const user = useToken()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	const { viewMode, weekRange, changeViewMode, createEvent, handleStartAndEndDate, changedWeerRange } = props

	return (
		<Stack flexDirection='row' alignItems='center' justifyContent='space-between' py={2} width='100%' flexWrap='wrap'>
			<Box position='relative'>
				<Typography fontSize={xlDisplay ? 36 : 24} fontFamily='GilroyBold' color='#fff' lineHeight='1'>
					Online Trainings
				</Typography>

				{xlDisplay && (
					<Box
						bgcolor='#fff'
						borderRadius='10px 10px 0 0'
						position='absolute'
						top='61px'
						left='0'
						right='0'
						height='5px'
					></Box>
				)}
			</Box>

			<Box display='flex'>
				{!mobile && (
					<Box display='flex' mr='20px'>
						<Box
							display='flex'
							alignItems='center'
							mr='2px'
							padding='20px'
							borderRadius='5px 0 0 5px'
							bgcolor='#353345'
							sx={{
								cursor: 'pointer',
								backgroundImage:
									viewMode === ViewModeEnum.Calendar ? 'linear-gradient(300deg, #9945FF 0%, #13F094 100%)' : '#353345',
							}}
							onClick={() => changeViewMode(ViewModeEnum.Calendar)}
						>
							<IconImage
								src={viewMode === ViewModeEnum.Calendar ? CalendarActive : Calendar}
								alt='Calendar'
								width='14px'
								height='14px'
							/>
							<Typography
								fontSize='16px'
								fontFamily='GilroySemiBold'
								color={viewMode === ViewModeEnum.Calendar ? '#fff' : 'rgba(255, 255, 255, 0.5)'}
								lineHeight='1'
								ml='10px'
							>
								Calendar
							</Typography>
						</Box>
						<Box
							display='flex'
							alignItems='center'
							padding='20px'
							borderRadius='0px 5px 5px 0'
							bgcolor='#353345'
							sx={{
								cursor: 'pointer',
								backgroundImage:
									viewMode === ViewModeEnum.List ? 'linear-gradient(300deg, #9945FF 0%, #13F094 100%)' : '#353345',
							}}
							onClick={() => changeViewMode(ViewModeEnum.List)}
						>
							<IconImage
								src={viewMode === ViewModeEnum.List ? ListActive : List}
								alt='List'
								width='14px'
								height='14px'
							/>
							<Typography
								fontSize='16px'
								fontFamily='GilroySemiBold'
								color={viewMode === ViewModeEnum.List ? '#fff' : 'rgba(255, 255, 255, 0.5)'}
								lineHeight='1'
								ml='10px'
							>
								List
							</Typography>
						</Box>
					</Box>
				)}

				{!mobile && (
					<WeekNavigator
						weekRange={weekRange}
						handleStartAndEndDate={handleStartAndEndDate}
						changedWeerRange={changedWeerRange}
					/>
				)}

				{user?.role !== RolesEnum.User && user?.role !== RolesEnum.SuperUser && (
					<Box display='flex' alignItems='center' ml='20px'>
						<Button variant='create' onClick={createEvent}>
							<Box display='flex' alignItems='center'>
								<IconImage src={Plus} alt='Plus' height={xlDisplay ? '16px' : '14px'} />
							</Box>
						</Button>
					</Box>
				)}
			</Box>

			{mobile && (
				<Box display='flex' flexDirection='column' alignItems='center' justifyContent='center' width='100%' mt='15px'>
					<Box display='flex' justifyContent='space-between' width='100%' mb='20px'>
						<Box
							width='calc(50% - 1px)'
							display='flex'
							alignItems='center'
							mr='2px'
							padding='12px'
							borderRadius='5px 0 0 5px'
							bgcolor='#353345'
							sx={{
								cursor: 'pointer',
								backgroundImage:
									viewMode === ViewModeEnum.Calendar ? 'linear-gradient(300deg, #9945FF 0%, #13F094 100%)' : '#353345',
							}}
							onClick={() => changeViewMode(ViewModeEnum.Calendar)}
						>
							<IconImage
								src={viewMode === ViewModeEnum.Calendar ? CalendarActive : Calendar}
								alt='Calendar'
								width='14px'
								height='14px'
							/>
							<Typography
								fontSize='16px'
								fontFamily='GilroySemiBold'
								color={viewMode === ViewModeEnum.Calendar ? '#fff' : 'rgba(255, 255, 255, 0.5)'}
								lineHeight='1'
								ml='10px'
							>
								Calendar
							</Typography>
						</Box>
						<Box
							display='flex'
							width='calc(50% - 1px)'
							alignItems='center'
							padding='12px'
							borderRadius='0px 5px 5px 0'
							bgcolor='#353345'
							sx={{
								cursor: 'pointer',
								backgroundImage:
									viewMode === ViewModeEnum.List ? 'linear-gradient(300deg, #9945FF 0%, #13F094 100%)' : '#353345',
							}}
							onClick={() => changeViewMode(ViewModeEnum.List)}
						>
							<IconImage
								src={viewMode === ViewModeEnum.List ? ListActive : List}
								alt='List'
								width='14px'
								height='14px'
							/>
							<Typography
								fontSize='16px'
								fontFamily='GilroySemiBold'
								color={viewMode === ViewModeEnum.List ? '#fff' : 'rgba(255, 255, 255, 0.5)'}
								lineHeight='1'
								ml='10px'
							>
								List
							</Typography>
						</Box>
					</Box>

					<WeekNavigator
						weekRange={weekRange}
						handleStartAndEndDate={handleStartAndEndDate}
						changedWeerRange={changedWeerRange}
					/>
				</Box>
			)}
		</Stack>
	)
}
