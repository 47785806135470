import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import { IconImage } from '@Components'

import { useGoToPreviousPage, useGoToContactUs, useGoToMain } from '@Hooks'

import { DisplayEnum } from 'shared/enums/display.enum'

import ArrowLeft from '@Assets/left-arrow.png'
import MissingImage from '@Assets/missing.png'
import MissingCode from '@Assets/404.png'
import BridgeLogo from '@Assets/bridge_logo.png'

import { useState } from 'react'


export function MissingPageComponent() {
    const theme = useTheme()
    const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
    const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
    const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet))
    const laptop = useMediaQuery(theme.breakpoints.down(DisplayEnum.laptop))
    const goToPrevious = useGoToPreviousPage()
    const goToContactUs = useGoToContactUs()
    const [showThankYou, setShowThankYou] = useState(false)
    const goToMain = useGoToMain(setShowThankYou)


    return (
        <Stack mx='auto' maxWidth={xlDisplay ? 1180 : '100%'} p={mobile ? '0 30px' : 0} boxSizing='border-box' >
            <Box mb={xlDisplay ? 13 : -3} mt={mobile ? 1.8 : 11} onClick={goToMain}>
                <IconImage src={BridgeLogo} alt='bridge logo' width={mobile ? '124px' : '200px'} />
            </Box>
            <Stack direction={xlDisplay ? 'row' : 'column-reverse'} mt={xlDisplay ? -12 : 2} mb={mobile ? -10 : 5} justifyContent={xlDisplay ? '' : 'center'}>
                <Stack>
                    <Box mt={xlDisplay ? 12 : 2} mb={xlDisplay ? 7 : 2} >
                    <Box width={xlDisplay ? '100%' : 375 } ml={mobile ? -7.2 : -12.6} mr={mobile ? 0 : -8} mt={mobile ? -5 : -11} mb={mobile ? -6 : -8}>
                            <IconImage src={MissingCode} alt='Forbidden Code' width={mobile ? '360px' : '650px'} />
                        </Box>
                    </Box>
                    <Typography fontFamily='GilroyBold' fontSize={mobile ? 24 : 36} mb={1} color={theme.palette.primary.main}>
                        SOMETHING'S MISSING.
                    </Typography>
                    <Typography fontFamily={mobile ? 'GilroyRegular' : 'GilroySemiBold'} fontSize={mobile ? 14 : 18} mb={1} color={theme.palette.text.secondary}>
                        Unfortunately, something has gone wrong and there is no page at this address.
                    </Typography>
                    <Typography fontFamily={mobile ? 'GilroyRegular' : 'GilroySemiBold'} fontSize={mobile ? 14 : 18} mb={mobile ? 2 : 3} color={theme.palette.text.secondary}>
                        Below are actions you can take:
                    </Typography>
                    <Stack direction='row' spacing={mobile ? 1 : 2}>
                        <Box>
                            <Button variant='rounded_errors_page_purple' onClick={goToPrevious}>
                                <IconImage src={ArrowLeft} alt='arrow back' width='24px' />
                                <Typography ml={0.8} fontFamily='GilroySemiBold' fontSize={mobile ? 14 : 16} color={theme.palette.primary.main} noWrap={true}>GO BACK</Typography>
                            </Button>
                        </Box>
                        <Box>
                            <Button variant='rounded_errors_page' onClick={goToContactUs}>
                                <Typography fontFamily='GilroySemiBold' fontSize={mobile ? 14 : 16} color={theme.palette.primary.main} noWrap={true}>CONTACT US</Typography>
                            </Button>
                        </Box>
                    </Stack>
                </Stack>
                <Box >
                    <IconImage src={MissingImage} alt='Missing image' width={mobile ? '315px' : tablet ? '600px' : '750px'} />
                </Box>
            </Stack>
        </Stack>
    )
}
