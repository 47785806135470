import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { GroupOnlineTraining } from 'features/trainings/typings'
import { DisplayEnum } from '@Enums'
import { CardBlock } from 'features/trainings/components/CardBlock'
import { Circles } from 'react-loader-spinner'
import { GroupOnlineTrainings } from './GroupOnlineTrainings'
import { IconImage } from '@Components'
import { CreateTrainingModel } from 'features/trainings/create-training-page/CreateTrainingPage'

import Empty from '@Assets/nothing.svg'

type Props = {
	loading: boolean
	groups: GroupOnlineTraining[]
	changeTraining: (training: CreateTrainingModel) => void
}

export function ListView(props: Props) {
	const theme = useTheme()
	const { groups, loading, changeTraining } = props
	const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet))
	const groupArrLengthBoolean = !!groups.length

	return (
		<CardBlock>
			{loading && (
				<Stack
					position='absolute'
					top={0}
					right={0}
					bottom={0}
					left={0}
					alignItems='center'
					justifyContent='center'
					zIndex={1000}
				>
					<Circles height={120} width={120} color={theme.palette.grey.A200} ariaLabel='loading' />
				</Stack>
			)}

			{!loading &&
				groupArrLengthBoolean &&
				groups.map((group: GroupOnlineTraining, index: number) => {
					return <GroupOnlineTrainings key={index} group={group} changeTraining={changeTraining} />
				})}

			{!groupArrLengthBoolean && (
				<Stack
					width='100%'
					py={tablet ? '0' : '150px'}
					px={tablet ? '0' : '200px'}
					mx='auto'
					alignItems='center'
					boxSizing='border-box'
				>
					<IconImage src={Empty} alt='no item' width='78px' height='78px' />
					<Typography fontSize={28} mt={3.6} fontFamily='GilroyBold' textAlign='center' color={theme.palette.grey.A400}>
						There are no trainings yet.
					</Typography>
				</Stack>
			)}
		</CardBlock>
	)
}
