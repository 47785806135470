import { Route, Routes } from 'react-router-dom'
import { ProtectedRoute } from '@Components'
import { SubscriptionPage } from './SubscriptionPage'
import { useIsLoggedIn } from '@Hooks'
import { SubscriptionManagementPage } from './SubscriptionManagementPage'
import { UnsubscribePage } from './UnsubscribePage'
import { AllSubscriptionsPage } from './AllSubscriptionsPage'
import { RolesEnum } from '@Enums'
import { DataManager } from './components/DataManager'
import { UnsubscribedSuccessfulyPage } from './UnsubscribedSuccessfuly/UnsubscribedSuccessfulyPage'

export function SubscriptionsRoutes() {
	const isLoggedIn = useIsLoggedIn()

	return (
		<DataManager>
			<Routes>
				<Route
					path='/subscriptions'
					element={
						<ProtectedRoute roles={[RolesEnum.SuperAdmin, RolesEnum.Admin]} isLoggedIn={isLoggedIn}>
							<SubscriptionPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/subscriptions/manage/:id'
					element={
						<ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.SuperAdmin]} isLoggedIn={isLoggedIn}>
							<SubscriptionManagementPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/subscriptions/unsubscribe/:id'
					element={
						<ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.SuperAdmin]} isLoggedIn={isLoggedIn}>
							<UnsubscribePage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/subscriptions/unsubscribed'
					element={
						<ProtectedRoute roles={[RolesEnum.Admin, RolesEnum.SuperAdmin]} isLoggedIn={isLoggedIn}>
							<UnsubscribedSuccessfulyPage />
						</ProtectedRoute>
					}
				/>
				<Route path='/subscriptions/all' element={<AllSubscriptionsPage />} />
			</Routes>
		</DataManager>
	)
}
