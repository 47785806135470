import { styled, Stack } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'

export const StyledContainer = styled(Stack, { name: 'StyledContainer' })({
	maxWidth: '240px',
  width: '100%',
  boxSizing: 'border-box',
  marginBottom: '50px',
  marginRight: '20px',
  borderRadius: '6px',
  ':hover': {
    '& :nth-of-type(1n)': {
      [`@media (min-width: 1025px)`]: {
        visibility: 'visible'
      },
    }
  },
  [`@media (max-width: ${DisplayEnum.mobile}px)`]: {
		marginRight: '0',
	},
})