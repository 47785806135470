import type { Payment } from '../../../typings'
import { useMemo } from 'react'
import { SubscriptionPlansEnum, SubscriptionStatusEnum } from '@Enums'
import { format } from 'date-fns'

export function useData(payment: Payment) {
	const currentSubscriptionStatus = (
		payment.subscriptionStatus === 5 ? 'Runs Out' : SubscriptionStatusEnum[payment.subscriptionStatus]
	).toUpperCase()

	return {
		name: SubscriptionPlansEnum[payment.subscriptionType],
		period: `${format(new Date(payment.from), 'MM/dd/yyyy')} - ${format(
			new Date(payment.to),
			'MM/dd/yyyy',
		)}`.toUpperCase(),
		price: `$${payment.amount}`,
		payDate: format(new Date(payment.paymentDate), 'MM/dd/yyyy').toUpperCase(),
		currentSubscriptionStatus,
	}
}
