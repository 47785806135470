import { Footer, Header } from '@Components'
import { Stack, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { HeaderOnlineTrainingsPage } from './components/HeaderOnlineTrainings'
import { useTrainingsContext } from '../context'
import { useHandlers } from './hooks/useHandlers'
import { CreateTrainingModel } from '../create-training-page/CreateTrainingPage'
import { DisplayEnum } from '@Enums'
import { ViewModeEnum } from '../enums'
import { ListView } from './components/ListView'
import { CalendarView } from './components/CalendarView'
import { getAllWeekDays } from '../utils/calendar.utils'
import { useLocation } from 'react-router-dom'
import { CalendarViewMobile } from './components/CalendarViewMobile'

export function OnlineTrainingsPage() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const location = useLocation()
	const [loading, setLoading] = useState<boolean>(false)
	const [viewMode, setViewMode] = useState<ViewModeEnum>(location?.state?.viewMode ?? ViewModeEnum.List)
	const [weekDays, setWeekDays] = useState<{ [key: string]: string | number | Date | boolean }[]>([])
	const [weekRange, setWeekRange] = useState<Date>(location?.state?.weekRange ?? new Date())
	const [{ onlineTrainings }] = useTrainingsContext()
	const { handleGoToCreateTraining, handleDateRangeChange } = useHandlers()

	const createTraining = () => {
		handleGoToCreateTraining(null, viewMode, weekRange)
	}

	const changeTraining = (training: CreateTrainingModel) => {
		handleGoToCreateTraining(training, viewMode, weekRange)
	}

	const changeViewMode = (viewMode: ViewModeEnum) => {
		setViewMode(viewMode)
	}

	const сreateWeekData = (startDate: Date, endDate: Date) => {
		setWeekDays(getAllWeekDays(startDate, endDate))
		handleDateRangeChange(startDate, endDate, setLoading)
	}

	const changedWeerRange = (week: Date) => {
		setWeekRange(week)
	}

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Stack bgcolor={theme.palette.background.default}>
			<Header hideNavigationMenu />
			<Stack px={xlDisplay ? 13 : 3} pb={xlDisplay ? 10 : 0} minHeight='calc(100vh - 272.5px)' boxSizing='border-box'>
				<HeaderOnlineTrainingsPage
					viewMode={viewMode}
					weekRange={weekRange}
					changeViewMode={changeViewMode}
					createEvent={createTraining}
					handleStartAndEndDate={сreateWeekData}
					changedWeerRange={changedWeerRange}
				/>
				{viewMode === ViewModeEnum.List && (
					<ListView loading={loading} groups={onlineTrainings?.groups || []} changeTraining={changeTraining} />
				)}

				{viewMode === ViewModeEnum.Calendar && xlDisplay && (
					<CalendarView
						loading={loading}
						groups={onlineTrainings?.groups || []}
						weekDays={weekDays || []}
						changeTraining={changeTraining}
					/>
				)}

				{viewMode === ViewModeEnum.Calendar && !xlDisplay && (
					<CalendarViewMobile
						loading={loading}
						groups={onlineTrainings?.groups || []}
						weekDays={weekDays || []}
						changeTraining={changeTraining}
					/>
				)}
			</Stack>
			<Footer onMainPage={false} />
		</Stack>
	)
}
