import Video from '@Assets/head_video.mp4'
import Image from '@Assets/video1.png'

import Yahoo from '@Assets/yahoo_finance.png'
import MarketWatch from '@Assets/market_watch.png'
import Fox from '@Assets/fox.png'
import Abc from '@Assets/abc_2.png'
import Finanzen from '@Assets/finanzen.png'
import SeekingAlpha from '@Assets/alpha.png'
import MarketsInsider from '@Assets/insider.png'
import AP from '@Assets/AP.png'
import MorningStar from '@Assets/morningstar.png'
import Time from '@Assets/Time.png'
import USN from '@Assets/usn.png'

import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Header, IconImage } from '@Components'

import { DescriptionBoxCanvas } from './DescriptionBoxCanvas'
import { DescriptionBox } from './DescriptionBox'
import { DescriptionBoxCanvasDark } from './DescriptionBoxCanvasDark'
import { useHandlers } from './hooks'
import { DisplayEnum } from 'shared/enums/display.enum'
import { DescriptionVideo } from './DescriptionVideo'

export function Description() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { handleSubscribe, handleContactUs } = useHandlers()

	return (
		<Stack position='relative'>
			<Header />
			<Box fontFamily='GilroyRegular' position='relative' pt={xlDisplay ? 23 : 22}>
				<Box position='absolute' top={0} left={0} width='100%' height={xlDisplay ? 810 : 740} overflow='hidden'>
					<DescriptionBoxCanvas position='absolute' top={0} left={0} width='100%' height='100%'>
						{xlDisplay && (
							<DescriptionVideo autoPlay muted loop width='100%'  >
								<source src={Video} type='video/mp4' />
							</DescriptionVideo>
						)}
						{!xlDisplay && (
							<Box position='absolute' top={mobile? -56 : -64} left={0} width='100%' >
								<IconImage src={Image}  height='810px' />
							</Box>
						)}
					</DescriptionBoxCanvas>
				</Box>
				<DescriptionBox
					maxWidth={mobile ? 315 : 776}
					textAlign='center'
					marginX='auto'
					borderRadius={8}
					position='inherit'
				>
					<Stack py={xlDisplay ? 5 : 2.2} px={xlDisplay ? 7.5 : 2.2} >
						<Typography fontSize={mobile ? 24 : 44} fontFamily='GilroyBold' variant='h3' color='primary'>
							Innovative Virtual Reality Technology for{' '}
							<Typography
								fontSize='inherit'
								fontFamily='GilroyBold'
								variant='h3'
								component='span'
								color={theme.palette.success.main}
							>
								Learning & Development
							</Typography>{' '}

						</Typography>
						<Typography fontFamily='GilroyRegular' fontSize={mobile ? 14 : 16} mt={2} color={theme.palette.text.secondary}>
							An immersive experience that facilitates interactive and real-life learning in Technical, Service and Sales Departments
						</Typography>
						<Stack direction='row' spacing={2} color={theme.palette.primary.main} mt={2.5} justifyContent='center'>
							<Button variant='description_white' onClick={handleSubscribe}>
								Subscribe
							</Button>
							<Button variant='description_white' onClick={handleContactUs}>
								Contact Us
							</Button>
						</Stack>
					</Stack>
				</DescriptionBox>

				<DescriptionBoxCanvasDark
					mt={3}
					mb={4.2}
					direction={mobile ? 'column' : 'row'}
					alignItems='center'
					justifyContent='center'
					spacing={4}
				>
					<Stack
						direction='row'
						position='relative'
						zIndex={3}
						maxWidth={xlDisplay ? '800px' : '100%'}
						boxSizing='border-box'
						mt={mobile? -1.8 : -1}
						mx='auto'
						justifyContent={mobile ? 'space-between' : 'center'}
						alignItems='center'
						flexWrap={'wrap'}
						padding={mobile ? '0 30px' : 0}
					>
						<Box m={mobile ? '10px 10px 10px 0' : '10px 20px 10px 0'}>
							<IconImage src={Yahoo} alt='Yahoo Logo' height={mobile ? '20px' : '' } />
						</Box>
						<Box m={mobile ? '10px 10px 10px 0' : '10px 20px 10px 0'}>
							<IconImage src={Time} alt='Time Logo' height={mobile ? '18px' : '28px'}/>
						</Box>
						<Box m={mobile ? '10px 10px 10px 0' : '10px 20px 10px 0'}>
							<IconImage src={MarketWatch} alt='Market Watch Logo' height={mobile ? '15px' : ''}/>
						</Box>
						<Box m={mobile ? '10px 10px 10px 0' : '10px 20px 10px 0'}>
							<IconImage src={Fox} alt='Fox Logo' height={mobile ? '18px' : '28px'} />
						</Box>
						<Box m={mobile ? '10px 10px 10px 0' : '10px 20px 10px 0'}>
							<IconImage src={Abc} alt='ABC Logo' height={mobile ? '27px' : ''} />
						</Box>
						<Box m={mobile ? '10px 10px 10px 0' : '10px 20px 10px 0'}>
							<IconImage src={Finanzen} alt='Finanzen Logo' height={mobile ? '14px' : ''}/>
						</Box>
						<Box m={mobile ? '10px 10px 10px 0' : '10px 20px 10px 0'}>
							<IconImage src={SeekingAlpha} alt='Seeking Alpha Logo' height={mobile ? '20px' : ''} />
						</Box>
						<Box m={mobile ? '10px 10px 10px 0' : '10px 20px 10px 0'}>
							<IconImage src={MarketsInsider} alt='Markets Insider Logo' height={mobile ? '30px' : ''} />
						</Box>
						<Box m={mobile ? '10px 10px 10px 0' : '10px 20px 10px 0'}>
							<IconImage src={AP} alt='AP NEWS Logo' height={mobile ? '27px' : ''} />
						</Box>
						<Box m={mobile ? '10px 10px 10px 0' : '10px 20px 10px 0'}>
							<IconImage src={MorningStar} alt='Morning Star Logo' height={mobile ? '20px' : ''} />
						</Box>
						<Box m={'10px 0'}>
							<IconImage src={USN} alt='Usn Logo' height={mobile ? '20px' : ''} />
						</Box>
					</Stack>
				</DescriptionBoxCanvasDark>
			</Box>
		</Stack>
	)
}
