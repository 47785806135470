import { format } from 'date-fns'
import { useTrainingsContext } from 'features/trainings/context'
import { CreateTrainingModel } from 'features/trainings/create-training-page/CreateTrainingPage'
import { fetchOnlineTrainingsEffect } from 'features/trainings/effects'
import { ViewModeEnum } from 'features/trainings/enums'
import { setSelectedTrainingActionCreator } from 'features/trainings/state/Actions/SetSelectedTraining'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export function useHandlers() {
	const navigate = useNavigate()
	const [, dispatch] = useTrainingsContext()
	const [loading, setIsLoading] = useState(false)

	const handleGoToCreateTraining = useCallback(
		(
			training: CreateTrainingModel | null,
			viewMode: ViewModeEnum,
			weekRange: Date
		) => {
			dispatch(setSelectedTrainingActionCreator(training))
			localStorage.setItem('selectedTrainingLocal', JSON.stringify(training))
			navigate('/create-training', { state: { viewMode, weekRange } })
		},
		[navigate],
	)

	const handleDateRangeChange = useCallback((startDate: Date, endDate: Date, setLoading: (value: boolean) => void) => {
		let mounted = true
		const from = format(startDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
		const to = format(endDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")

		const execute = async () => {
			setLoading(true)
			const response = await fetchOnlineTrainingsEffect({ from, to })
			setLoading(false)
			if (!mounted || response.status === 'error') return

			dispatch(response.action)
		}

		execute()
		setIsLoading(false)
		return () => {
			mounted = false
		}
	}, [])

	return {
		handleGoToCreateTraining,
		handleDateRangeChange,
	}
}
