import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTrainingsContext } from 'features/trainings/context'
import { AssetsGroupResponse, AssetsItem } from 'features/trainings/typings'
import { useFormikContext } from 'formik'
import { useState } from 'react'
import { DisplayEnum } from 'shared/enums/display.enum'
import { AssetsGoup } from './AssetsGroup'
import { IconImage } from '@Components'
import { AddedAsset } from './AddedAsset'

import AddedAssets from '@Assets/added_assets.svg'

type Props = {
	assetsSelect: AssetsItem[]
}

export function AssetsList(props: Props) {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const formik = useFormikContext()
	const [{ assets }] = useTrainingsContext()
	const { assetsSelect } = props
	const [activeTab, setActiveTab] = useState<number>(1)

	const [selectedAssets, setSelectedAssets] = useState<AssetsItem[]>(assetsSelect)

	const setSelectedAssetsFunc = (assets: AssetsItem[]) => {
		setSelectedAssets(assets)
		formik.setFieldValue('assets', assets)
	}

	const removeSelectedAsset = (asset: AssetsItem) => {
		const updatedSelectedAssets = selectedAssets.filter((a: AssetsItem) => a.id !== asset.id)
		setSelectedAssets(updatedSelectedAssets)
		formik.setFieldValue('assets', updatedSelectedAssets)
	}

	const changeActiveTab = (tab: number) => {
		setActiveTab(tab)
	}

	return (
		<Stack flexDirection={mobile ? 'column' : 'row'} alignItems='flex-start' justifyContent='flex-start'>
			{mobile && (
				<Box
					display='flex'
					alignItems='center'
					justifyContent='center'
					width='100%'
					borderBottom='1px solid rgba(255, 255, 255, 0.1)'
					ml='-20px'
					mb='20px'
					px='20px'
				>
					<Box position='relative' mr='30px' pb='13px' onClick={() => changeActiveTab(1)}>
						<Typography
							fontSize={14}
							fontFamily='GilroySemiBold'
							color={activeTab === 1 ? '#fff' : 'rgba(255, 255, 255, 0.7)'}
							lineHeight='1'
						>
							Search
						</Typography>
						{activeTab === 1 && (
							<Box
								position='absolute'
								bottom={0}
								height='3px'
								borderRadius='10px 10px 0 0'
								width='100%'
								bgcolor='#fff'
							></Box>
						)}
					</Box>
					<Box position='relative' pb='13px' onClick={() => changeActiveTab(2)}>
						<Typography
							fontSize={14}
							fontFamily='GilroySemiBold'
							color={activeTab === 2 ? '#fff' : 'rgba(255, 255, 255, 0.7)'}
							lineHeight='1'
						>
							Added Assets
						</Typography>
						{activeTab === 2 && (
							<Box
								position='absolute'
								bottom={0}
								height='3px'
								borderRadius='10px 10px 0 0'
								width='100%'
								bgcolor='#fff'
							></Box>
						)}
					</Box>
				</Box>
			)}
			{((mobile && activeTab === 1) || !mobile) && (
				<Box width={xlDisplay || mobile ? '100%' : '50%'} mt='-9px'>
					{assets.map((item: AssetsGroupResponse, index: number) => {
						return (
							<AssetsGoup
								key={index}
								assetsGroup={item}
								marginBottom={index !== assets.length - 1 ? '11px' : '0px'}
								selectedAssetsFunc={setSelectedAssetsFunc}
								selectedAssets={selectedAssets}
							/>
						)
					})}
				</Box>
			)}
			{((mobile && activeTab === 2) || !mobile) && (
				<Box width={xlDisplay || mobile ? '100%' : '50%'} maxWidth={xlDisplay ? 320 : '100%'} ml={xlDisplay ? 6 : mobile ? 0 : 2}>
					<Box display='flex' alignItems='center' mb={mobile ? '20px' : '30px'}>
						<Box display='flex' alignItems='center' mr={1}>
							<IconImage src={AddedAssets} alt='AddedAssets' height='18px' />
						</Box>
						<Typography fontSize={16} fontFamily='GilroySemiBold' color='rgba(255, 255, 255, 0.7)' lineHeight='1'>
							Added Assets
						</Typography>
					</Box>
					<Box width='100%'>
						{selectedAssets.map((asset: AssetsItem) => {
							return <AddedAsset key={asset.id} asset={asset} removeSelectedAsset={removeSelectedAsset} />
						})}
					</Box>
				</Box>
			)}
		</Stack>
	)
}
