import { SharedSearch } from '@Components'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { StyledStack } from './StyledStack'
import { StyledBox } from './StyledBox'
import { useData, useHandlers } from './hooks'
import { RolesService } from '../../../../shared/services/roles.service'
import { RolesEnum } from '@Enums'
import { TrainingTypeEnum } from '../../utils/enums'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'

export function CourseStatistic() {
	const theme = useTheme()
	const { newTrainings, all, inProgress, completed, technical, service, sales, withOculus, filterStatus } = useData()
	const showByStatuses = RolesService.availableForRole([
		RolesEnum.SuperAdmin,
		RolesEnum.Admin,
		RolesEnum.SuperUser,
		RolesEnum.User,
	])
	const { id } = useParams<{ id: string }>()
	const { handleFilterChange, handleSearchChange, handleStatusChange } = useHandlers(id || '')

	useEffect(() => {
		return handleFilterChange({ search: '' })
	}, [])

	return (
		<StyledStack>
			<>
				<SharedSearch onChange={handleSearchChange} name='courses' placeholder='Search courses …' id='courses' />

				{showByStatuses && withOculus && (
					<>
						<Typography fontFamily='GilroyMedium' color={theme.palette.text.primary} margin='20px 0 20px 20px'>
							Status
						</Typography>
						{typeof filterStatus === 'undefined' && (
							<StyledBox onClick={() => handleStatusChange()}>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.primary}>
									All
								</Typography>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.primary}>
									{/*146*/}
									{all}
								</Typography>
							</StyledBox>
						)}
						{typeof filterStatus !== 'undefined' && (
							<Box
								sx={{ cursor: 'pointer' }}
								onClick={() => handleStatusChange()}
								display='flex'
								justifyContent='space-between'
								padding='13px 20px'
							>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.primary}>
									All
								</Typography>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.primary}>
									{/*146*/}
									{all}
								</Typography>
							</Box>
						)}
						{filterStatus === 0 && (
							<StyledBox
								display='flex'
								justifyContent='space-between'
								padding='13px 20px'
								onClick={() => handleStatusChange(0)}
							>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
									New
								</Typography>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
									{/*123*/}
									{newTrainings}
								</Typography>
							</StyledBox>
						)}
						{filterStatus !== 0 && (
							<Box
								sx={{ cursor: 'pointer' }}
								display='flex'
								justifyContent='space-between'
								padding='13px 20px'
								onClick={() => handleStatusChange(0)}
							>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
									New
								</Typography>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
									{/*123*/}
									{newTrainings}
								</Typography>
							</Box>
						)}
						{filterStatus === 1 && (
							<StyledBox
								display='flex'
								justifyContent='space-between'
								padding='13px 20px'
								onClick={() => handleStatusChange(1)}
							>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
									In Progress
								</Typography>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
									{/*2*/}
									{inProgress}
								</Typography>
							</StyledBox>
						)}
						{filterStatus !== 1 && (
							<Box
								sx={{ cursor: 'pointer' }}
								display='flex'
								justifyContent='space-between'
								padding='13px 20px'
								onClick={() => handleStatusChange(1)}
							>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
									In Progress
								</Typography>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
									{/*2*/}
									{inProgress}
								</Typography>
							</Box>
						)}
						{filterStatus === 2 && (
							<StyledBox
								display='flex'
								justifyContent='space-between'
								padding='13px 20px'
								onClick={() => handleStatusChange(2)}
							>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
									Completed
								</Typography>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
									{/*2*/}
									{completed}
								</Typography>
							</StyledBox>
						)}
						{filterStatus !== 2 && (
							<Box
								sx={{ cursor: 'pointer' }}
								display='flex'
								justifyContent='space-between'
								padding='13px 20px'
								onClick={() => handleStatusChange(2)}
							>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
									Completed
								</Typography>
								<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
									{/*2*/}
									{completed}
								</Typography>
							</Box>
						)}
						{/*<Box margin='0 -21px'>*/}
						{/*	<DividerGray />*/}
						{/*</Box>*/}
					</>
				)}
			</>
			<Stack display='none'>
				<Typography fontFamily='GilroyMedium' color={theme.palette.text.primary} margin='20px 0 20px 20px'>
					Types
				</Typography>
				<StyledBox>
					<Typography fontFamily='GilroyMedium' color={theme.palette.text.primary}>
						All
					</Typography>
					<Typography fontFamily='GilroyMedium' color={theme.palette.text.primary}>
						{/*146*/}
						{all}
					</Typography>
				</StyledBox>
				<Box display='flex' justifyContent='space-between' padding='13px 20px'>
					<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
						{TrainingTypeEnum[0]}
					</Typography>
					<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
						{/*134*/}
						{technical}
					</Typography>
				</Box>
				<Box display='flex' justifyContent='space-between' padding='13px 20px'>
					<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
						{TrainingTypeEnum[1]}
					</Typography>
					<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
						{/*12*/}
						{service}
					</Typography>
				</Box>
				<Box display='flex' justifyContent='space-between' padding='13px 20px 0'>
					<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
						{TrainingTypeEnum[2]}
					</Typography>
					<Typography fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
						{/*12*/}
						{sales}
					</Typography>
				</Box>
			</Stack>
		</StyledStack>
	)
}
