import { IconImage } from '@Components'
import { Box, Typography, useTheme } from '@mui/material'

type Props = {
	title: string
	description: string
	image: string
}

export function Slide(props: Props) {
	const { title, description, image } = props
	const theme = useTheme()

	return (
		<Box width={300} bgcolor='transparent' p={1.5} overflow='hidden'>
			<Box mb={0.6} width='100%' height={171} boxSizing='border-box' borderRadius='6px' overflow='hidden'>
				<IconImage src={image} alt='card' width='100%' height='171px' />
			</Box>
			<Typography 
				fontSize={22}
				color={theme.palette.primary.main} 
				mb={0.6} 
				fontFamily='GilroySemiBold'>
				{title}
			</Typography>
			<Typography 
				fontSize={16}
				color={theme.palette.text.secondary} 
				fontFamily='GilroyRegular'>
				{description}
			</Typography>
		</Box>
	)
}
