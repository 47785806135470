import { styled } from '@mui/material'

export const StyledInput = styled('input')({
	padding: '15px 18px',
	borderRadius: '4px',
	outline: 'none',
	boxShadow: 'none',
	border: 'none',
	fontFamily: 'GilroySemiBold',

	'&:disabled': {
		background: 'rgba(255, 255, 255, .8)',
	},
})
