import { Route, Routes } from 'react-router-dom'
import { CreateTrainingPage } from './create-training-page/CreateTrainingPage'
import { TrainingsContextProvider } from './context'
import { OnlineTrainingsPage } from './online-trainings/OnlineTrainingsPage'
import { ProtectedRoute } from '@Components'
import { RolesEnum } from '@Enums'
import { useIsLoggedIn } from '@Hooks'

export function TrainingsFeatureRoutes() {
	const isLoggedIn = useIsLoggedIn()

	return (
		<TrainingsContextProvider>
			<Routes>
				<Route
					path='/online-trainings'
					element={
						<ProtectedRoute
							roles={[
								RolesEnum.Trainer,
								RolesEnum.Admin,
								RolesEnum.SuperAdmin,
								RolesEnum.SuperUser,
								RolesEnum.User,
							]}
							isLoggedIn={isLoggedIn}
						>
							<OnlineTrainingsPage />
						</ProtectedRoute>
					}
				/>
				<Route
					path='/create-training'
					element={
						<ProtectedRoute
							roles={[RolesEnum.Trainer, RolesEnum.Admin, RolesEnum.SuperAdmin]}
							isLoggedIn={isLoggedIn}
						>
							<CreateTrainingPage />
						</ProtectedRoute>
					}
				/>
			</Routes>
		</TrainingsContextProvider>
	)
}
