import { addMinutes, format, parseISO } from 'date-fns'
import { CreateTrainingModel } from '../create-training-page/CreateTrainingPage'
import { CreateTraining } from '../typings'

export const prepareCreateTrainingPayload = (value: CreateTrainingModel): CreateTraining => {
	const { id, title, description, cover, date, assets, users, duration, startTime } = value

	const [month, day, year] = date.split('/')
	const [hours, minutes] = startTime.split(':')

	const startDate = new Date(Date.UTC(+year, +month - 1, +day, +hours, +minutes))

	const durationInMinutes = parseInt(duration)

	const endDate = addMinutes(startDate, durationInMinutes)

	const formattedStartDate = startDate.toISOString()
	const formattedEndDate = endDate.toISOString()

	const payload: CreateTraining = {
		title: title,
		description: description,
		startTime: formattedStartDate,
		endTime: formattedEndDate,
		cover: cover,
		assets: assets,
		users: users,
	}

	return id ? { ...payload, id } : payload
}

export const getTimeIntervalWithPeriod = (startTime: string, endTime: string): string => {
	const startDate = parseISO(startTime)
	const endDate = parseISO(endTime)

	const formattedStartTime = format(startDate, 'h:mm')
	const formattedEndTime = format(endDate, 'h:mm')

	const startTimePeriod = format(startDate, 'a')
	const endTimePeriod = format(endDate, 'a')

	if (startTimePeriod === endTimePeriod) {
		return `${formattedStartTime} - ${formattedEndTime} ${startTimePeriod}`
	} else {
		return `${formattedStartTime} ${startTimePeriod} - ${formattedEndTime} ${endTimePeriod}`
	}
}

export const prepareCreateTrainingModel = (value: CreateTraining): CreateTrainingModel => {
	const { id, title, description, cover, startTime, endTime, assets, users } = value

	const startDate = new Date(startTime)
	const endDate = new Date(endTime)

	const formattedStartDate = `${startDate.getUTCMonth() + 1}/${startDate.getUTCDate()}/${startDate.getUTCFullYear()}`
	const userLocalHours = startDate.getHours()
	const userLocalMinutes = startDate.getMinutes()
	const formattedStartTime = `${userLocalHours}:${userLocalMinutes}`

	const durationInMinutes = (endDate.getTime() - startDate.getTime()) / (1000 * 60)
	const formattedDuration = String(durationInMinutes)

	return {
		id,
		title,
		description,
		cover,
		date: formattedStartDate,
		startTime: formattedStartTime,
		duration: formattedDuration,
		assets,
		users,
	}
}
