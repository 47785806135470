import { Table, TableBody, TableContainer } from '@mui/material'
import { Fragment } from 'react'
import { ReportingCategoriesRow } from './ReportingCategoriesRow'
import { TrainingsDetailsRow } from '../../TrainingsDetailsPage/components'
import { ParticipantsTableRow } from '../../ParticipantsReports/components'
import { TrainingsReportingRow } from '../../TrainingsReports/components/TrainingsTableContent'

import type { ParticipantTableRow } from '../../ParticipantsReports/typings'
import type { TrainingsDetailsTableRow } from '../../TrainingsDetailsPage/typings'
import type { TrainingsReportingTableRow } from '../../TrainingsReports/components/TrainingsTableContent'

type Props =
	| {
		tableHeadingTitles: {
			title: string
			hasFilters?: boolean
		}[]
		tableRows: TrainingsReportingTableRow[]
		entity: 'trainings'
	}
	| {
		tableHeadingTitles: {
			title: string
			hasFilters?: boolean
		}[]
		tableRows: ParticipantTableRow[]
		entity: 'participants'
	}
	| {
		tableHeadingTitles: {
			title: string
			hasFilters: boolean
		}[]
		tableRows: TrainingsDetailsTableRow[]
		entity: 'details'
	}

export function ReportingTable(props: Props) {
	const { tableHeadingTitles, tableRows, entity } = props

	return (
		<Fragment>
			<TableContainer>
				<Table aria-label='table'>
					<ReportingCategoriesRow tableHeadingTitles={tableHeadingTitles} />
					<TableBody>
						{tableRows.map((tableRow, index) =>
							entity === 'trainings' ? (
								<TrainingsReportingRow tableRow={tableRow as TrainingsReportingTableRow} key={`row_${index}`} />
							) : 
							entity === 'participants' ? (
								<ParticipantsTableRow tableRow={tableRow as ParticipantTableRow} key={`row_${index}`}/>
							) : 
							<TrainingsDetailsRow tableRow={tableRow as TrainingsDetailsTableRow} key={`row_${index}`}/>,
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</Fragment>
	)
}
