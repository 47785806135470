import { Typography, useTheme } from '@mui/material'

export function Termination() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color='primary'>
				<u>Termination</u>
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				We may terminate or suspend Your access immediately, without prior notice or liability, for any reason
				whatsoever, including without limitation if You breach these Terms and Conditions.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Upon termination, Your right to use the Service will cease immediately.
			</Typography>
		</>
	)
}
