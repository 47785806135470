import type { Theme } from '@mui/material'
import { useMemo } from 'react'
import { TrainingAttemptsEnum, TrainingUsersTypes, TrainingStatusesEnum } from '../../../TrainingsReports/enums';

function getHeading(title: TrainingStatusesEnum | TrainingAttemptsEnum | TrainingUsersTypes |  string) {
	const headings: Record<TrainingStatusesEnum | TrainingAttemptsEnum | TrainingUsersTypes | string, string> = {
		[TrainingStatusesEnum.NotStarted]: 'Not Started',
		[TrainingStatusesEnum.InProgress]: 'In progress',
		[TrainingStatusesEnum.Completed]: 'Completed',
		[TrainingAttemptsEnum.averageAttempts]: 'Average Attempts',
		[TrainingAttemptsEnum.averageTime]: 'Average Time',
		[TrainingUsersTypes.Active]: 'Active',
		[TrainingUsersTypes.Inactive]: 'Inactive',
	}
	return headings[title]
}

function getStatusColor(title: TrainingStatusesEnum | TrainingAttemptsEnum | TrainingUsersTypes |  string, theme: Theme) {
	const colors: Record<TrainingStatusesEnum | TrainingAttemptsEnum | TrainingUsersTypes | string, string> = {
		[TrainingStatusesEnum.NotStarted]: theme.palette.primary.contrastText,
		[TrainingStatusesEnum.InProgress]: theme.palette.secondary.contrastText,
		[TrainingStatusesEnum.Completed]: theme.palette.success.main,
		[TrainingAttemptsEnum.averageAttempts]: theme.palette.error.contrastText,
		[TrainingAttemptsEnum.averageTime]: theme.palette.warning.light,
		[TrainingUsersTypes.Active]: theme.palette.info.contrastText,
		[TrainingUsersTypes.Inactive]: theme.palette.text.disabled,
	}
	return colors[title]
}

type Props = {
	title: string
	theme: Theme
}

export function useData(props: Props) {
	const { theme, title } = props
	const heading = useMemo(() => getHeading(title), [title])
	const statusColor = useMemo(() => getStatusColor(title, theme), [title, theme])

	return {
		statusColor, heading
	}
}
