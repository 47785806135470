import { Footer, Header } from '@Components'
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { SubscriptionUsers, AddUserPopup, SubscriptionManagementStack, UserRoleDescription } from './components'
import { useEffect, useRef } from 'react'
import { DisplayEnum } from 'shared/enums/display.enum'
import type { SubscriptionUsersFilters } from '../typings'
import { ConfirmationDeleteUserPopup } from './components/ConfirmationDeleteUserPopup/ConfirmationDeleteUserPopup'
import { useFetchSubscriptions } from '../hooks'

export function SubscriptionManagementPage() {
	const filters = useRef<SubscriptionUsersFilters>({ page: 1, limit: 10, role: 0, email: '', withOculus: 0 })
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useFetchSubscriptions()

	return (
		<Stack bgcolor={theme.palette.background.default}>
			<Header hideNavigationMenu />
			<SubscriptionManagementStack alignItems='center' minHeight='calc(100vh - 271.5px)'>
				<Stack alignItems='center' flex={1} mb={mobile ? 3 : 7.5}>
					<Typography variant='h6' fontSize='18px' fontFamily='GilroySemiBold' mt={8} color={theme.palette.info.main}>
						PERSONAL SPACE
					</Typography>
					<Typography fontSize={mobile ? 32 : 48} fontFamily='GilroyBold' textAlign='center' color='primary' mt={1}>
						Manage Subscription
					</Typography>
				</Stack>
				<SubscriptionUsers filters={filters.current} />
				<AddUserPopup />
				<ConfirmationDeleteUserPopup />
			</SubscriptionManagementStack>
			<Footer onMainPage={false} />
		</Stack>
	)
}
