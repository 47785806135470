import { Box, Button, Container, Link, Stack, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material'
import Logo from '@Assets/bridge_logo.png'
import BurgerMenuImg from '@Assets/burgerMenu.svg'
import { HashLink, IconImage, StyledAvatar, StyledBox } from '@Components'
import { useGoToLoginPage, useGoToMain, useIsLoggedIn, useLogout, useOpenBurgerMenu, useToken } from '@Hooks'
import { StyledBar } from './StyledBar'
import { RolesService } from '../services/roles.service'
import { RolesEnum } from '@Enums'
import LogoutIcon from '@mui/icons-material/Logout'
import { DisplayEnum } from 'shared/enums/display.enum'
import { BurgerMenu } from './BurgerMenu'
import { Fragment, useState } from 'react'

type Props = {
	hideNavigationMenu?: boolean
	showReportingMenu?: boolean
}

export function Header(props: Props) {
	const isLoggedIn = useIsLoggedIn()
	const user = useToken()
	const { hideNavigationMenu, showReportingMenu } = props
	const [showThankYou, setShowThankYou] = useState(false)
	const goToMain = useGoToMain(setShowThankYou)
	const handleLogout = useLogout()
	const handleOpenBurgerMenu = useOpenBurgerMenu()
	const signIn = useGoToLoginPage()
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))

	const showSubscriptions = RolesService.availableForRole([RolesEnum.SuperAdmin, RolesEnum.Admin])
	const showTrainings = RolesService.availableForRole([
		RolesEnum.Admin,
		RolesEnum.SuperUser,
		RolesEnum.User,
		RolesEnum.SuperAdmin,
		RolesEnum.Guest,
		RolesEnum.Trainer,
	])
	const showReports = false //RolesService.availableForRole([
	// 	RolesEnum.SuperAdmin,
	// 	RolesEnum.SuperUser,
	// 	RolesEnum.Admin,
	// 	RolesEnum.Guest,
	// ])
	const showOnlineTrainings = RolesService.availableForRole([
		RolesEnum.Trainer,
		RolesEnum.Admin,
		RolesEnum.SuperAdmin,
		RolesEnum.SuperUser,
		RolesEnum.User,
	])

	return (
		<StyledBox width='100%' zIndex={1000} boxSizing='border-box'>
			<StyledBar>
				<Container maxWidth='xl' disableGutters={!xlDisplay}>
					<Toolbar>
						<Stack
							alignItems='center'
							justifyContent={xlDisplay ? 'space-between' : 'flex-start'}
							direction='row'
							width='100%'
							mx={xlDisplay ? 7 : 0}
						>
							{!xlDisplay && (
								<Box mr={1} onClick={handleOpenBurgerMenu}>
									<IconImage src={BurgerMenuImg} alt='menu' width='24px' />
								</Box>
							)}
							<img src={Logo} alt='logo' onClick={goToMain} />
							{xlDisplay && (
								<>
									{!hideNavigationMenu && (
										<Stack direction='row' fontFamily='GilroyRegular' spacing={2.4}>
											<HashLink to='#theory'>What we do</HashLink>
											<HashLink to='#about'>Technical Training</HashLink>
											<HashLink to='#safetly'>Custom Development</HashLink>
											<HashLink to='#pricing'>Pricing</HashLink>
											<HashLink to='#contacts'>Contact</HashLink>
											<Link
												href='https://bridgelt.com/blog'
												target='_blank'
												color={theme.palette.primary.main}
												underline='none'
											>
												Blog
											</Link>
										</Stack>
									)}
									{hideNavigationMenu && isLoggedIn && (
										<Stack direction='row' fontFamily='GilroyRegular' spacing={4}>
											{showReportingMenu && <HashLink to='/#theory'>What we do</HashLink>}
											{showSubscriptions && <HashLink to='/subscriptions'>Subscription</HashLink>}
											{showTrainings && !showReportingMenu && <HashLink to='/trainings'>Trainings</HashLink>}
											{showReportingMenu && showReports && (
												<Fragment>
													<HashLink to='/reporting'>Statistic</HashLink>
													<HashLink to='/reporting/participants'>Participants</HashLink>
												</Fragment>
											)}
											{showOnlineTrainings && user?.subscriptionId && <HashLink to='/online-trainings'>Online</HashLink>}
										</Stack>
									)}
									<Stack fontFamily='GilroyRegular' direction='row' alignItems='center' spacing={3}>
										{!isLoggedIn && (
											<Button variant='rounded_white' onClick={signIn}>
												Sign In
											</Button>
										)}
										{isLoggedIn && (
											<Stack spacing={1} direction='row' alignItems='center'>
												<StyledAvatar>
													<Typography color='primary'>{user.name && user.name[0].toUpperCase()}</Typography>
												</StyledAvatar>
												<Typography fontFamily='GilroyMedium' fontSize='18px' color='primary'>
													{user.name}
												</Typography>
											</Stack>
										)}

										{isLoggedIn && (
											<Button variant='rounded_white' onClick={handleLogout}>
												<Stack direction='row' alignItems='center' spacing={1}>
													<LogoutIcon />
													<Typography>Logout</Typography>
												</Stack>
											</Button>
										)}
									</Stack>
								</>
							)}
						</Stack>
					</Toolbar>
				</Container>
			</StyledBar>
			<BurgerMenu hideNavigationMenu={hideNavigationMenu} />
		</StyledBox>
	)
}
