import { DisplayEnum } from 'shared/enums/display.enum'
import { Stack, styled } from '@mui/material'
import Background from '@Assets/DetailedBoxBG.png'

export const DetailedBoxStack = styled(Stack, { name: 'DetailedBoxStack' })({
	backgroundImage: `url('${Background}')`,
	backgroundRepeat: 'no repeat',
	backgroundPosition: '-175px -165px',
	backgroundSize: '200%',
	[`@media (max-width: ${DisplayEnum.mobile}px)`]: {
		backgroundSize: '200% 200%',
		backgroundPosition: '-175px -205px',
	},
})
