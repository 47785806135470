import { Typography, useTheme } from '@mui/material'

export function LinksToOtherWebsites() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color='primary'>
				<u>Links to Other Websites</u>
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Our Service may contain links to other websites that are not operated by Us. If You click on a third party link,
				You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every
				site You visit.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				We have no control over and assume no responsibility for the content, privacy policies or practices of any third
				party sites or services.
			</Typography>
		</>
	)
}
