export const DURATIONS: {value: string; label: string}[] = [
	{
		value: '30',
		label: '30 MIN',
	},
	{
		value: '45',
		label: '45 MIN',
	},
	{
		value: '60',
		label: '60 MIN',
	},
	{
		value: '75',
		label: '75 MIN',
	},
	{
		value: '90',
		label: '90 MIN',
	},
	{
		value: '105',
		label: '105 MIN',
	},
	{
		value: '120',
		label: '120 MIN',
	},
]
