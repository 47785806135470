import { Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { useCanDelete, useHandlers } from './hooks'
import type { SubscriptionUser } from '../../typings'
import { RolesEnum } from '@Enums'
import { format } from 'date-fns'
import { DisplayEnum } from 'shared/enums/display.enum'
import { StyledGrid } from 'features/subscriptions/components'

type Props = {
	user: SubscriptionUser
}

export function TableRow(props: Props) {
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { user } = props
	const { handleOpenConfirmationDeleteUser } = useHandlers()
	const canDelete = useCanDelete(user.role)

	const role = RolesEnum[user.role]
	const date = format(new Date(user.addedDate), 'MM/dd/yyyy')

	return (
		<StyledGrid container width={mobile ? '800px' : '100%'} p={2}>
			<Grid item xs={5}>
				<Typography variant='h6' fontFamily='GilroySemiBold' color='primary'>
					{user.email}
				</Typography>
			</Grid>
			<Grid container item xs={2} alignItems='center' display='flex' direction='row'>
				<Stack color={theme.palette.primary.main} fontFamily='GilroyRegular'>
					{user.withOculus && <CheckIcon />}
					{!user.withOculus && (
						<Typography color={theme.palette.text.secondary}>
							<HighlightOffRoundedIcon />
						</Typography>
					)}
				</Stack>
			</Grid>
			<Grid item xs={2}>
				<Typography variant='body1' fontFamily='GilroyRegular' color='primary' fontSize={18}>
					{role}
				</Typography>
			</Grid>
			<Grid item xs={2}>
				<Typography variant='body1' fontFamily='GilroyRegular' color='primary' fontSize={18}>
					{date}
				</Typography>
			</Grid>
			<Grid item xs={1} color={theme.palette.text.secondary}>
				{canDelete && <DeleteOutlineIcon cursor={'pointer'} onClick={() => handleOpenConfirmationDeleteUser(user)} />}
			</Grid>
		</StyledGrid>
	)
}
