import { Typography, useTheme } from '@mui/material'

export function ChangesToPrivacyPolicy() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color='primary'>
				<u>Changes to this Privacy Policy</u>
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy
				Policy on this page.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective
				and update the "Last updated" date at the top of this Privacy Policy.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
				effective when they are posted on this page.
			</Typography>
		</>
	)
}
