import { RequestService } from '@Services'
import { URLS } from '@Utils'
import type { AssetsGroupResponse } from '../typings'
import { fetchTrainingsAssetsActionCreator, initialState } from '../state'

export async function fetchAssetsEffect() {
	const response = await RequestService.get<null, AssetsGroupResponse[]>(URLS.fetchTrainingAssets, null, true)

	if (response.status === 200) {
		return { status: 'success', action: fetchTrainingsAssetsActionCreator(response.data) }
	}

	return { status: 'error', action: fetchTrainingsAssetsActionCreator(initialState.assets) }
}
