import { ReactElement, useRef, useState } from 'react'
import { Button, Box, Stack, Typography, useTheme, useMediaQuery } from '@mui/material'
import { useFormikContext } from 'formik'
import { DisplayEnum } from '@Enums'
import { uploadImageEffect } from 'features/trainings/effects/uploadImage.effect'
import { Circles } from 'react-loader-spinner'
import { IconImage } from '@Components'

import Trash from '@Assets/trash_training.svg'
import DragAndDrop from '@Assets/drag_and_drop.svg'
import Upload from '@Assets/upload.svg'

type Props = {
	id: string
	label: string
	imageUrl: string
}

export function ImageUploader(props: Props) {
	const { id, label, imageUrl } = props
	const formik = useFormikContext()
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	const [uploadedImage, setUploadedImage] = useState<{ url: string } | null>(null)
	const [loading, setLoading] = useState<boolean>(false)
	const fileInputRef = useRef<any>(null)

	const handleFileChange = async (event: any) => {
		const file = event.target.files[0]
		if (file) {
			setLoading(true)
			const url = await uploadImageEffect(file)
			if (url) {
				formik.setFieldValue(id, url)
				setUploadedImage(url)
			}
			setLoading(false)
		}
	}

	const handleDrop = async (event: any) => {
		event.preventDefault()
		const file = event.dataTransfer.files[0]
		if (file) {
			setLoading(true)
			const url = await uploadImageEffect(file)
			if (url) {
				formik.setFieldValue(id, url)
				setUploadedImage(url)
			}
			setLoading(false)
		}
	}

	const handleDragOver = (event: any) => {
		event.preventDefault()
	}

	const handleButtonClick = () => {
		fileInputRef.current.click()
	}

	const removeUploadImageButtonClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.value = ''
		}
		formik.setFieldValue(id, '')
		setUploadedImage(null)
	}

	return (
		<Box position='relative'>
			<Typography fontSize={16} fontFamily='GilroySemiBold' color={theme.palette.text.secondary} mb={0.8}>
				{label}
			</Typography>
			<input type='file' accept='image/*' onChange={handleFileChange} ref={fileInputRef} hidden />
			<Box
				onDrop={handleDrop}
				onDragOver={handleDragOver}
				sx={{
					width: mobile ? '100%' : '320px',
					height: '170px',
					border: uploadedImage || imageUrl ? 'none' : '2px dashed #FFFFFF42',
					borderRadius: '5px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					pointerEvents: loading ? 'none' : 'auto',
					backgroundImage: uploadedImage ? `url(${uploadedImage})` : imageUrl ? `url(${imageUrl})` : 'none',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					boxSizing: 'border-box',
					position: 'relative',
				}}
			>
				{!uploadedImage && !imageUrl && !loading && (
					<Stack flexDirection='row' justifyContent='center' alignItems='center'>
						<IconImage src={DragAndDrop} alt='drag_and_drop' height='20px' />
						<Typography fontSize={16} fontFamily='GilroyMedium' color={theme.palette.text.secondary} ml={1.5}>
							Drag and Drop files here
						</Typography>
					</Stack>
				)}
				{loading && (
					<Stack
						position='absolute'
						top={0}
						right={0}
						bottom={0}
						left={0}
						alignItems='center'
						justifyContent='center'
						zIndex={1000}
					>
						<Circles height={120} width={120} color={theme.palette.grey.A200} ariaLabel='loading' />
					</Stack>
				)}
			</Box>

			<Button
				disabled={loading}
				onClick={handleButtonClick}
				sx={{
					width: '100%',
					borderRadius: '4px',
					backgroundColor: 'rgba(255, 255, 255, 0.1)',
					paddingTop: '18px',
					paddingBottom: '18px',
					marginTop: '20px',
					fontFamily: 'GilroyMedium',
					fontSize: '16px',
					color: 'rgba(255, 255, 255, 0.7)',
					textTransform: 'capitalize',
					lineHeight: '1',
				}}
			>
				<Box display='flex' alignItems='center' mr={1}>
					<IconImage src={Upload} alt='upload' height='18px' />
				</Box>
				Browse
			</Button>

			{formik.errors && (formik.errors as any)[id] && (formik.touched as any)[id] ? (
				<Stack position='absolute' left={0}>
					<Typography variant='caption' color='red' letterSpacing={mobile ? -0.2 : 0.1} lineHeight={mobile ? 1 : ''}>
						{((formik.errors as any)[id] ?? <></>) as ReactElement}
					</Typography>
				</Stack>
			) : null}

			{(uploadedImage || imageUrl) && (
				<Stack position='absolute' top={0} right={0} zIndex={3}>
					<Button onClick={removeUploadImageButtonClick} sx={{ minWidth: 'auto' }}>
						<Box display='flex' alignItems='center'>
							<IconImage src={Trash} alt='trash' height='18px' />
						</Box>
					</Button>
				</Stack>
			)}
		</Box>
	)
}
