import { Typography, useTheme } from '@mui/material'

export function ContactUs() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color='primary'>
				<u>Contact Us</u>
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				If you have any questions about this Privacy Policy, You can contact us:
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				By email: info@bridgeLT.com
			</Typography>
		</>
	)
}
