import Logo from '@Assets/bridge_logo.png'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { AlertMsg, IconImage } from '@Components'
import { Formik } from 'formik'
import { RegisterForm } from './RegisterForm'
import { useHandlers } from './hooks'
import { RegisterValidationSchema } from './validationSchema'
import { useEffect, useState } from 'react'
import { BackgroundStackVariant, BoxBackgroundStack } from '../components'
import { DisplayEnum } from 'shared/enums/display.enum'
import { useAuthData } from '@Hooks'

export function RegisterPage() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const [showError, setShowError] = useState({})
	const { handleSubmit, handleMainClick } = useHandlers(setShowError)
	const { initialValues } = useAuthData()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<BackgroundStackVariant
			width='100%'
			height='100%'
			minHeight='100vh'
			pb={xlDisplay ? 14.5 : 0}
			px={mobile ? 3 : 0}
			boxSizing={mobile ? 'border-box' : 'content-box'}
		>
			<BoxBackgroundStack
				px={mobile ? 3 : 5}
				pt={mobile ? 3 : 6}
				pb={mobile ? 3 : 3.5}
				borderRadius='10px'
				mt={mobile ? 5 : 14.5}
				mb={mobile ? 5 : 0}
				ml={xlDisplay ? 13 : mobile ? 0 : 'auto'}
				mr={xlDisplay || mobile ? 0 : 'auto'}
				width={mobile ? '100%' : 530}
				boxSizing='border-box'
			>
				<Box onClick={handleMainClick}>
					<IconImage src={Logo} alt='logo' width='154px' />
				</Box>

				<Typography fontSize={mobile ? 24 : 34} variant='h4' color='primary' fontFamily='GilroyBold' mt={5}>
					Register to Bridge VR
				</Typography>
				<Typography
					variant='body1'
					color={theme.palette.text.secondary}
					mt={1}
					fontFamily='GilroyMedium'
					letterSpacing={0}
				>
					Fill in the fields so that we can register you on the portal.
				</Typography>

				{false && <AlertMsg icon={<LockOpenIcon />} text='User wasnt registered' />}

				<Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={RegisterValidationSchema}>
					<RegisterForm showError={showError} initialValues={initialValues} />
				</Formik>
			</BoxBackgroundStack>
		</BackgroundStackVariant>
	)
}
