import { SharedSearch, SelectField } from '@Components'
import { useFilterChange } from '../hooks/useFilterChange'
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'
import type { SubscriptionUsersFilters } from '../../typings'

const items = [
	{ value: 0, label: 'ALL USERS' },
	{ value: 1, label: 'SUPER ADMIN' },
	{ value: 2, label: 'ADMIN' },
	{ value: 3, label: 'SUPER USER' },
	{ value: 4, label: 'USER' },
	{ value: 5, label: 'GUEST' },
]

const withOculusItems = [
	{ value: 0, label: 'ALL' },
	{ value: 1, label: 'NO OCULUS' },
	{ value: 2, label: 'WITH OCULUS' },
]

type Props = {
	filters: SubscriptionUsersFilters
}

export function TableFiltersForm(props: Props) {
	const { filters } = props
	const handleFilterChange = useFilterChange(filters)
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	return (
		<Stack direction={mobile ? 'column' : 'row'} alignItems={xlDisplay ? 'center' : 'start'} spacing={1}>
			<SharedSearch name='email' id='email' onChange={handleFilterChange} />
			<Stack direction={xlDisplay ? 'row' : 'column'} width={'100%'} spacing={1}>
				<Box mb={xlDisplay ? 0 : mobile ? 1 : 2}>
					<SelectField
						items={withOculusItems}
						name='withOculus'
						id='withOculus'
						defaultValue={0}
						color={theme.palette.primary.main}
						onChange={handleFilterChange}
					/>
				</Box>
				<SelectField
					items={items}
					name='role'
					id='role'
					defaultValue={0}
					color={theme.palette.primary.main}
					onChange={handleFilterChange}
				/>
			</Stack>
		</Stack>
	)
}
