import { Box, Button, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useCookiesHandlers } from '@Hooks'
import { DisplayEnum } from '@Enums'

export function CookiesPolicyBanner() {
	const theme = useTheme()
	const { cookiesAcceptedHandleClick } = useCookiesHandlers()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))

	return (
		<Box px={3} bgcolor='transparent'
			maxWidth='100%'
			position='fixed'
			bottom={50}
			left={xlDisplay ? 50 : 0}
			>
			<Box
				p={3}
				borderRadius='14px'
				maxWidth={mobile ? '100%' : 352}
				bgcolor={theme.palette.primary.main}
				zIndex={999}
			>
				<Typography fontFamily='GilroyBold' fontSize={18} mb={1} color={theme.palette.background.default}>
					WE VALUE YOUR PRIVACY
				</Typography>
				<Typography fontFamily='GilroyMedium' fontSize={16} mb={2.5} color={theme.palette.background.default}>
					We use cookies on this site to enhance your user experience. For a complete overview of all cookies used, please
					see our{' '}
					<Link color='inherit' target='_blank' href='/cookie'>
						Cookies Policy
					</Link>
					.
				</Typography>
				<Button variant='cookies_accept' fullWidth onClick={cookiesAcceptedHandleClick} type='button'>
					ACCEPT ALL
				</Button>
			</Box>
		</Box>
	)
}
