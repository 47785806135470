import { useCallback } from 'react'
import { useSharedContext } from '@Context'
import type { LoginData } from '../../typings'
import { loginEffect } from '../../effects/login.effect'
import { useNavigate } from 'react-router-dom'
import type { StoreUser } from '@State'
import { RolesEnum } from '@Enums'
import { User } from '@Types'

function getRedirectionUrl(role: RolesEnum = RolesEnum.Guest) {
	const startPages = {
		[RolesEnum.User]: '/trainings',
		[RolesEnum.Guest]: '/trainings',
		[RolesEnum.SuperUser]: '/trainings',
		[RolesEnum.SuperAdmin]: '/subscriptions',
		[RolesEnum.Admin]: '/subscriptions',
		[RolesEnum.Trainer]: '/trainings',
	}

	return startPages[role]
}

export function useHandlers() {
	const [, dispatch] = useSharedContext()
	const navigate = useNavigate()

	const handleSubmit = useCallback(
		async (values: LoginData, setError: (value: boolean) => void) => {
			let mounted = true
			try {
				setError(false)

				const { action, status } = await loginEffect(values)

				if (!mounted) return

				if (status === 'success') {
					dispatch(action as StoreUser)

					localStorage.removeItem('email')

					navigate(getRedirectionUrl((action?.payload as User)?.role))
				} else setError(true)
			} catch (err) {
				setError(true)
			}

			return () => {
				mounted = false
			}
		},
		[dispatch],
	)

	const handleMainClick = useCallback(() => {
		navigate('/')
	}, [navigate])

	return {
		handleSubmit,
		handleMainClick,
	}
}
