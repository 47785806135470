import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Footer, Header } from '@Components'
import { EmptySubscriptions, SubscriptionsPageStack, SubscriptionsTable } from './components'
import { Card } from './components/Card/Card'
import { useData, useFetchPayments, useFetchSubscriptions, useFetchUserPdfInfo } from './hooks'
import { useEffect } from 'react'
import { DisplayEnum } from 'shared/enums/display.enum'

export function SubscriptionPage() {
	const { subscription } = useData()
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	useFetchUserPdfInfo()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	useFetchSubscriptions()
	useFetchPayments()

	return (
		<Stack bgcolor={theme.palette.background.default}>
			<SubscriptionsPageStack>
				<Header hideNavigationMenu />
				<Stack alignItems={'center'} flex={1} px={xlDisplay ? 0 : 3} minHeight='calc(100vh - 271.5px)'>
					<Typography
						fontSize={mobile ? 36 : 48}
						fontFamily='GilroyBold'
						textAlign={'center'}
						color='primary'
						mt={8}
					>
						Personal Space
					</Typography>
					{!subscription && <EmptySubscriptions />}
					{!!subscription && <Card subscription={subscription} />}
					<SubscriptionsTable />
				</Stack>
				<Footer onMainPage={false} />
			</SubscriptionsPageStack>
		</Stack>
	)
}
