import { Footer, Header } from '@Components'
import { Stack, useTheme } from '@mui/material'
import { CourseContentStack } from '../components'
import { CourseWrapperComponent } from './components/CourseWrapperComponent'
import { useEffect, useState } from 'react'
import { DataManager } from '../DataManager'

export function CourseListPage() {
	const theme = useTheme()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<DataManager setLoading={setLoading}>
			<Stack bgcolor={theme.palette.background.default}>
				<CourseContentStack>
					<Header hideNavigationMenu />
					<CourseWrapperComponent loading={loading}/>
					<Footer showAdditionalInfo={true} onMainPage={false} />
				</CourseContentStack>
			</Stack>
		</DataManager>
	)
}
