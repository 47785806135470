import { Grid, Typography, useTheme } from '@mui/material'
import type { Payment } from '../../typings'
import { StyledGrid } from '../StyledGrid'
import { StyledChip } from './components'

import { useData } from './hooks'

type Props = {
	payment: Payment
}

export function SubscriptionsTableRow(props: Props) {
	const { payment } = props
	const { name, period, price, payDate, currentSubscriptionStatus } = useData(payment)

	return (
		<StyledGrid container width='100%'>
			<Grid item xs={2} px={3} py={2}>
				<Typography fontSize='18px' fontFamily='GilroySemiBold' color='primary'>
					{name}
				</Typography>
			</Grid>
			<Grid item xs={2} pl={3} py={2}>
				<StyledChip
					label={
						<Typography fontWeight={700} color='white'>
							{currentSubscriptionStatus}
						</Typography>
					}
				/>
			</Grid>
			<Grid item xs={3} px={3} py={2}>
				<Typography fontSize='18px' fontFamily='GilroyRegular' color='primary'>
					{period}
				</Typography>
			</Grid>
			<Grid item xs={2} px={3} py={2}>
				<Typography fontSize='18px' fontFamily='GilroyRegular' color='primary'>
					{price}
				</Typography>
			</Grid>
			<Grid item xs={3} px={3} py={2}>
				<Typography variant='h6' fontFamily='GilroyRegular' color='primary'>
					{payDate}
				</Typography>
			</Grid>
		</StyledGrid>
	)
}
