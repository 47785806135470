import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { IconImage } from '@Components'
import { useEffect, useState } from 'react'
import { addWeeks, endOfWeek, format, startOfWeek, subWeeks } from 'date-fns'
import { DisplayEnum } from '@Enums'

import ChevronLeft from '@Assets/chevronLeft.svg'
import ChevronRight from '@Assets/chevronRight.svg'

type Props = {
	weekRange: Date
	handleStartAndEndDate: (startDate: Date, endDate: Date) => void
	changedWeerRange: (week: Date) => void
}

export function WeekNavigator(props: Props) {
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { weekRange, changedWeerRange, handleStartAndEndDate } = props
	const [currentWeek, setCurrentWeek] = useState(weekRange ?? new Date())

	const handleNextWeek = () => {
		setCurrentWeek((prevWeek) => {
			changedWeerRange(addWeeks(prevWeek, 1))
			return addWeeks(prevWeek, 1)
		})
	}

	const handlePreviousWeek = () => {
		setCurrentWeek((prevWeek) => {
			changedWeerRange(subWeeks(prevWeek, 1))
			return subWeeks(prevWeek, 1)
		})
	}

	const startDate = format(startOfWeek(currentWeek, { weekStartsOn: 1 }), 'd MMM')
	const endDate = format(endOfWeek(currentWeek, { weekStartsOn: 1 }), 'd MMM')

	useEffect(() => {
		handleStartAndEndDate(startOfWeek(currentWeek, { weekStartsOn: 1 }), endOfWeek(currentWeek, { weekStartsOn: 1 }))
	}, [currentWeek])

	return (
		<Stack flexDirection='row' alignItems='center' width={mobile ? '100%' : 'auto'}>
			<Box
				mr='2px'
				width='50px'
				height='58px'
				bgcolor='#353345'
				display='flex'
				alignItems='center'
				justifyContent='center'
				boxSizing='border-box'
				borderRadius='5px 0 0 5px'
				sx={{ cursor: 'pointer' }}
				onClick={handlePreviousWeek}
			>
				<IconImage src={ChevronLeft} alt='ChevronLeft' width='6px' height='12px' />
			</Box>
			<Box
				bgcolor='#353345'
				padding='10px 15px'
				width={mobile ? 'calc(100% - 104px)' : '160px'}
				height='58px'
				boxSizing='border-box'
			>
				<Typography fontFamily='GilroyMedium' fontSize='14px' color='rgba(255, 255, 255, 0.7)' lineHeight='1' mb='4px'>
					Period to display
				</Typography>
				<Typography fontFamily='GilroyBold' fontSize='16px' color='rgba(255, 255, 255)' lineHeight='1'>
					{startDate} - {endDate}
				</Typography>
			</Box>
			<Box
				ml='2px'
				width='50px'
				height='58px'
				bgcolor='#353345'
				display='flex'
				alignItems='center'
				justifyContent='center'
				boxSizing='border-box'
				borderRadius='0 5px 5px 0'
				sx={{ cursor: 'pointer' }}
				onClick={handleNextWeek}
			>
				<IconImage src={ChevronRight} alt='ChevronLeft' width='6px' height='12px' />
			</Box>
		</Stack>
	)
}
