import type { Actions, State } from './typings'
import { fetchOnlineTrainingsProducer, fetchTrainingsAssetsProducer, setSelectedTrainingProducer } from './Actions'

export function reducer(state: State, action: Actions) {
	switch (action.type) {
		case 'trainings/fetchAssets':
			return fetchTrainingsAssetsProducer(state, action.payload)
		case 'trainings/fetchOnlineTrainings':
			return fetchOnlineTrainingsProducer(state, action.payload)
		case 'trainings/setSelectedTraining':
			return setSelectedTrainingProducer(state, action.payload)
	}
}
