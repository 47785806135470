import { Box, Select, SelectChangeEvent, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Form, useFormikContext } from 'formik'
import { DisplayEnum } from 'shared/enums/display.enum'
import { CustomTextField, StyledMenuItem } from '@Components'
import { ImageUploader } from './ImageUploader'
import { DesktopDatePicker, DesktopTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ReactElement, useEffect, useState } from 'react'
import { CreateTrainingModel } from '../CreateTrainingPage'
import { DURATIONS } from 'features/trainings/constants'
import './form.scss'
import dayjs from 'dayjs'

type Props = {
	initialValues: CreateTrainingModel
}

export function FormCreateTraining(props: Props) {
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const formik = useFormikContext()
	const [duration, setDuration] = useState<string>('30')
	const { initialValues } = props

	const setDate = (value: any) => {
		formik.setFieldValue('date', new Date(value.$d).toLocaleDateString('en-US'), false)
	}

	const setStartTime = (value: any) => {
		const hours = value.$H
		const minutes = value.$m
		const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`
		formik.setFieldValue('startTime', formattedTime, false)
	}

	const checkPastValue = (): boolean => {
		//@ts-ignore
		return formik.values.date === new Date().toLocaleDateString('en-US')
	}

	const handleDuration = (event: SelectChangeEvent) => {
		setDuration(event.target.value as string)
		formik.setFieldValue('duration', event.target.value as string, false)
	}

	useEffect(() => {
		setDuration(initialValues.duration)
	}, [initialValues])

	return (
		<Form>
			<Stack
				flexDirection={xlDisplay ? 'row' : 'column-reverse'}
				alignItems={xlDisplay ? 'flex-start' : 'center'}
				justifyContent={xlDisplay ? 'flex-start' : 'center'}
			>
				<Box width='100%'>
					<Box position='relative' pb={2}>
						<CustomTextField<any>
							name='title'
							label='Title'
							id='title'
							placeholder='Please enter title'
							defaultValue={initialValues.title}
						/>
					</Box>
					<Box position='relative' mt={1} pb={2}>
						<CustomTextField<any>
							id='description'
							name='description'
							label='Description'
							placeholder='Please enter description'
							textArea={true}
							defaultValue={initialValues.description}
						/>
					</Box>
					<Box
						position='relative'
						mt={mobile ? 0 : 1}
						display='flex'
						flexDirection={mobile ? 'column' : 'row'}
						alignItems='flex-start'
						justifyContent='flex-start'
					>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Box mr={mobile ? '0' : '20px'} mb={mobile ? '20px' : 0} width={mobile ? '100%' : 'auto'}>
								<Typography fontSize={16} fontFamily='GilroySemiBold' color={theme.palette.text.secondary} mb={0.8}>
									Date
								</Typography>
								<DesktopDatePicker
									value={dayjs(initialValues.date) || ''}
									format='DD MMM YYYY'
									disablePast={true}
									slotProps={{ textField: { disabled: true } }}
									onChange={setDate}
									sx={{
										backgroundColor: '#fff',
										borderRadius: '4px',
										width: mobile ? '100%' : 'auto',
										input: {
											width: mobile ? '100%' : '125px',
											color: 'rgba(48, 48, 48, 1) !important',
											WebkitTextFillColor: 'rgba(48, 48, 48, 1) !important',
											fontFamily: 'GilroySemiBold',
											fontSize: '16px',
											lineHeight: '1',
											paddingTop: '11.5px',
											paddingBottom: '11.5px',
											pointerEvents: 'none',
										},
									}}
								/>
							</Box>
							<Box mr={mobile ? '0' : '20px'} mb={mobile ? '20px' : 0} width={mobile ? '100%' : 'auto'}>
								<Typography fontSize={16} fontFamily='GilroySemiBold' color={theme.palette.text.secondary} mb={0.8}>
									Start Time
								</Typography>
								<DesktopTimePicker
									value={dayjs(
										`${dayjs(initialValues.date, 'M/D/YYYY').format('YYYY-MM-DD')}T${initialValues.startTime}`,
									)}
									disablePast={checkPastValue()}
									onChange={setStartTime}
									sx={{
										backgroundColor: '#fff',
										borderRadius: '4px',
										width: mobile ? '100%' : 'auto',
										input: {
											width: mobile ? '100%' : '98px',
											color: 'rgba(48, 48, 48, 1)',
											fontFamily: 'GilroySemiBold',
											fontSize: '16px',
											lineHeight: '1',
											paddingTop: '11.5px',
											paddingBottom: '11.5px',
											pointerEvents: 'none',
										},
									}}
								/>
							</Box>
							<Box width={mobile ? '100%' : 'auto'}>
								<Typography fontSize={16} fontFamily='GilroySemiBold' color={theme.palette.text.secondary} mb={0.8}>
									Duration
								</Typography>
								<Select
									value={duration}
									onChange={handleDuration}
									sx={{
										color: 'rgba(48, 48, 48, 1)',
										fontFamily: 'GilroySemiBold',
										fontSize: '16px',
										lineHeight: '1',
										backgroundColor: '#fff',
										borderRadius: '4px',
										height: '46px',
										width: mobile ? '100%' : '105px',
										'> div': {
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'flex-start',
										},
									}}
								>
									{DURATIONS.map((duration: { [key: string]: string }, index: number) => (
										<StyledMenuItem value={duration.value} key={`role_${index}`}>
											{duration.label}
										</StyledMenuItem>
									))}
								</Select>
							</Box>
						</LocalizationProvider>
						{formik.errors &&
						((formik.errors as any)['date'] || (formik.errors as any)['startTime']) &&
						((formik.touched as any)['date'] || (formik.touched as any)['startTime']) ? (
							<Stack position='absolute' left={0} top={mobile ? 280 : 80}>
								<Typography
									variant='caption'
									color='red'
									letterSpacing={mobile ? -0.2 : 0.1}
									lineHeight={mobile ? 1 : ''}
								>
									{(((formik.errors as any)['date'] || (formik.errors as any)['startTime']) ?? <></>) as ReactElement}
								</Typography>
							</Stack>
						) : null}
					</Box>
				</Box>
				<Box
					width='100%'
					maxWidth={mobile ? '100%' : 320}
					ml={xlDisplay ? 6 : 'auto'}
					mr={xlDisplay ? 0 : 'auto'}
					mb={mobile ? 2 : 0}
				>
					<ImageUploader id='cover' label='Cover image' imageUrl={initialValues.cover} />
				</Box>
			</Stack>
		</Form>
	)
}
