import { Footer, Header } from '@Components'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { Acknowledgment } from './part/Acknowledgment'
import { Changes } from './part/Changes'
import { ContactUs } from './part/ContactUs'
import { Disclaimer } from './part/Disclaimer'
import { DisputesResolution } from './part/DisputesResolution'
import { EuropeanUsers } from './part/EuropeanUsers'
import { GoverningLaw } from './part/GoverningLaw'
import { InterpretationAndDefinitions } from './part/InterpretationAndDefinitions'
import { LimitationOfLiability } from './part/LimitationOfLiability'
import { LinksToOtherWebsites } from './part/LinksToOtherWebsites'
import { SeverabilityAndWaiver } from './part/SeverabilityAndWaiver'
import { Termination } from './part/Termination'
import { TranslationInterpretation } from './part/TranslationInterpretation'
import { UnitedStatesLegalCompliance } from './part/UnitedStatesLegalCompliance'

export function TermsAndConditionsPage() {
	const theme = useTheme()

	return (
		<Box bgcolor={theme.palette.background.default}>
			<Header hideNavigationMenu={true} />
			<Box maxWidth={1020} marginX='auto'>
				<Stack padding={4.2} spacing={2} justifyContent='center'>
					<Typography fontFamily='GilroyBold' variant='h3' color='primary'>
						Terms and Conditions
					</Typography>
					<Typography variant='body1' color={theme.palette.text.secondary}>
						Last updated: June 21, 2022
					</Typography>
					<Typography variant='body1' color={theme.palette.text.secondary}>
						Please read these terms and conditions carefully before using Our Service.
					</Typography>
					<InterpretationAndDefinitions />
					<Acknowledgment />
					<LinksToOtherWebsites />
					<Termination />
					<LimitationOfLiability />
					<Disclaimer />
					<GoverningLaw />
					<DisputesResolution />
					<EuropeanUsers />
					<UnitedStatesLegalCompliance />
					<SeverabilityAndWaiver />
					<TranslationInterpretation />
					<Changes />
					<ContactUs />
				</Stack>
			</Box>
			<Footer onMainPage={false} />
		</Box>
	)
}
