import { Stack, styled } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'

export const CardBlock = styled(Stack, { name: 'CardBlock' })({
	width: '100%',
	borderRadius: '5px',
	backgroundColor: '#312E41',
	padding: '40px 60px',
	boxSizing: 'border-box',
	[`@media (max-width: ${DisplayEnum.tablet}px)`]: {
		padding: '20px'
	}
})