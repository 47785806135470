import { Footer, Header } from '@Components'
import { Stack, useTheme } from '@mui/material'
import { SubscriptionPageComponent } from './components'

import { SubscriptionPageStack } from './components/SubscriptionPageStack'
import { useEffect } from 'react'
import { usePaymentContext } from '../context'
import { fetchPaymentSecretActionCreator } from '../state/Actions/fetchPaymentSecret'
import { useUpdateUser } from '../../../shared/hooks/useUpdateUser'

export function SubscriptionPage() {
	const theme = useTheme()
	const [, dispatch] = usePaymentContext()
	const updateUser = useUpdateUser()

	useEffect(() => {
		window.scrollTo(0, 0)
		dispatch(fetchPaymentSecretActionCreator(''))
		updateUser()
	}, [updateUser, dispatch])

	return (
		<Stack bgcolor={theme.palette.background.default}>
			<SubscriptionPageStack>
				<Header hideNavigationMenu />
				<SubscriptionPageComponent />
				<Footer onMainPage={false} />
			</SubscriptionPageStack>
		</Stack>
	)
}
