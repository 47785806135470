import { Box, Stack, Typography } from '@mui/material'
import { Form } from 'formik'
import Button from '@mui/material/Button'
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled'
import { CustomTextField } from '@Components'
import { FormStackSyled } from './FormStackSyled'
import { FormBoxSyled } from './FormBoxSyled'
import { TInvoiceForm } from './typings'


type Props = {
	handleCancel: () => void
	showError: any
	initialValues: any
}

export function DownloadInvoiceForm(props: Props) {
	const { handleCancel, showError, initialValues } = props

	return (
		<Form>
			<Stack>
				{!!Object.entries(showError).length && (
					<FormStackSyled
						spacing={2}
						p={3}
						direction='row'
						alignItems='center'
					>
						<FormBoxSyled>
							<PersonAddDisabledIcon />
						</FormBoxSyled>
						<Typography variant='body1' color='primary'>
							{Object.entries(showError)[0][1] as any}
						</Typography>
					</FormStackSyled>
				)}
				<Box pb={2} mb={1}>
					<CustomTextField<TInvoiceForm>
						id='companyName'
						name='companyName'
						label='NAME OF COMPANY'
						placeholder=''
						defaultValue={initialValues.companyName}
					/>
				</Box>
				<Box pb={2} mb={1}>
					<CustomTextField<TInvoiceForm>
						id='address'
						name='address'
						label='STREET ADDRESS'
						textArea={true}
						placeholder=''
						defaultValue={initialValues.address}
					/>
				</Box>
				<Stack direction='row' spacing={2}>
					<Box flex={1} pb={4}>
						<CustomTextField<TInvoiceForm>
							id='city'
							name='city'
							label='CITY'
							type='text'
							placeholder=''
							defaultValue={initialValues.city}
						/>
					</Box>
					<Box flex={1} pb={4}>
						<CustomTextField<TInvoiceForm>
							id='zipcode'
							name='zipcode'
							label='ZIP CODE'
							type='text'
							placeholder=''
							defaultValue={initialValues.zipcode}
						/>
					</Box>
				</Stack>

				<Stack direction='row' spacing={2}>
				<Box flex={1} pb={4}>
						<CustomTextField<TInvoiceForm>
							id='phoneNumber'
							name='phoneNumber'
							label='TELEPHONE'
							type='text'
							placeholder=''
							defaultValue={initialValues.phoneNumber}
						/>
					</Box>
					<Box flex={1} pb={4}>
						<CustomTextField<TInvoiceForm>
							id='email'
							name='email'
							label='EMAIL'
							type='email'
							placeholder=''
							defaultValue={initialValues.email}
						/>
					</Box>
				</Stack>
				<Box mb={3} width={256} mt={0.5} marginX='auto'>
					<Button type='submit' variant='rounded_success' fullWidth>
						SUBMIT
					</Button>
				</Box>
				<Button autoFocus onClick={handleCancel} variant='text'>
					Cancel
				</Button>
			</Stack>
		</Form>
	)
}
