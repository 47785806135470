import { useSubscriptionsContext } from '../../../../context'
import { useParams } from 'react-router-dom'
import { useToken } from '@Hooks'
import { RolesEnum } from '@Enums'

export function useData() {
	const [{ addUserIsOpen }] = useSubscriptionsContext()
	const user = useToken()

	const rolesItems = [
		{ value: 2, label: 'ADMIN' },
		{ value: 3, label: 'SUPER USER' },
		{ value: 4, label: 'USER' },
		{ value: 5, label: 'GUEST' },
		{ value: 6, label: 'TRAINER'},
	]

	if (user.role === RolesEnum.SuperAdmin) {
		rolesItems.push({ value: 1, label: 'SUPER ADMIN' })
	}

	return {
		addUserIsOpen,
		subscriptionId: user.subscriptionId || '',
		rolesItems,
		defaultRole: user.role === RolesEnum.SuperAdmin ? 1 : 2,
	}
}
