import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSharedContext } from '@Context'
import { logoutActionCreator } from '../state/Actions/Logout'

export function useLogout() {
	const navigate = useNavigate()
	const [, dispatch] = useSharedContext()

	return useCallback(() => {
		const localStorageUser = JSON.parse(localStorage.getItem('user') || '{}')

		if (localStorageUser.token) {
			localStorage.clear()
			dispatch(logoutActionCreator())
			navigate('/')
		}
	}, [navigate])
}
