import Logo from '@Assets/bridge_logo.png'
import LockOpenIcon from '@mui/icons-material/LockOpen'

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Formik } from 'formik'
import { AlertMsg, IconImage } from '@Components'
import { useHandlers } from './hooks'
import { LoginForm } from './LoginForm'
import { LoginValidationSchema } from './validationSchema'
import { useEffect, useState } from 'react'
import { BackgroundStackLogin, BoxBackgroundStack } from '../components'
import { DisplayEnum } from 'shared/enums/display.enum'

export function LoginPage() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { handleSubmit, handleMainClick } = useHandlers()
	const [error, setError] = useState(false)
	const initialValues = { email: localStorage.getItem('email') || '', password: '' }

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<BackgroundStackLogin
			width='100%'
			height='100%'
			minHeight='100vh'
			pb={xlDisplay ? 14.5 : 0}
			px={mobile ? 3 : 0}
			boxSizing={mobile ? 'border-box' : 'content-box'}
		>
			<BoxBackgroundStack
				px={mobile ? 3 : 5}
				pt={mobile ? 3 : 6}
				pb={mobile ? 3 : 3.5}
				borderRadius='10px'
				mt={mobile ? 5 : 14.5}
				ml={xlDisplay ?  13 : mobile ? 0 : 'auto'}
				mr={xlDisplay || mobile ? 0 : 'auto'}
				width={mobile ? '100%' : 530}
				boxSizing='border-box'
			>
				<Box onClick={handleMainClick}>
					<IconImage src={Logo} alt='logo' width='154px' />
				</Box>
				<Typography fontSize={mobile ? 24 : 34} variant='h4' color='primary' fontFamily='GilroyBold' mt={5}>
					Welcome to Bridge VR
				</Typography>
				<Typography
					variant='body1'
					color={theme.palette.text.secondary}
					mt={1}
					fontFamily='GilroyMedium'
					letterSpacing={0}
				>
					Please enter password associated with your email address.
				</Typography>

				<Box mt={1} height='70px'>
					{error && <AlertMsg icon={<LockOpenIcon />} text='Email or password is incorrect. Correct or add new ones.' />}
				</Box>

				<Formik
					initialValues={initialValues}
					onSubmit={(values) => handleSubmit(values, setError)}
					validationSchema={LoginValidationSchema}
				>
					<LoginForm />
				</Formik>
			</BoxBackgroundStack>
		</BackgroundStackLogin>
	)
}
