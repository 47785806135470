import type { ReactElement } from 'react'
import { Navigate } from 'react-router-dom'
import { RolesEnum } from '@Enums'
import { RolesService } from '../services/roles.service'
import { useIsLoggedIn } from '@Hooks'

type Props = {
	children: ReactElement
	roles?: RolesEnum[]
	isLoggedIn: boolean
}

export function ProtectedRoute(props: Props) {
	const { children, roles, isLoggedIn } = props

	if (!isLoggedIn) {
		return <Navigate to='/login' replace />
	}

	if (roles && !RolesService.availableForRole(roles)) {
		return <Navigate to='/errors/forbidden' replace />
	}

	return children
}
