import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export function useHandlers() {
	const navigate = useNavigate()
	const handleSubscribe = useCallback(() => {
		navigate('/subscriptions/all')
	}, [navigate])

	const handlePricing = useCallback(() => {
		const pricing = document.getElementById('pricing')
		pricing!.scrollIntoView()
	}, [])

	const handleContactUs = useCallback(() => {
		const pricing = document.getElementById('contactUs')
		pricing!.scrollIntoView()
	}, [])

	const handleTrainings = useCallback(() => {
		navigate('/trainings')
	}, [navigate])

	return {
		handlePricing,
		handleSubscribe,
		handleTrainings,
		handleContactUs,
	}
}
