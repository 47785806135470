import { IconImage } from '@Components'
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DisplayEnum } from '@Enums'

import Check from '@Assets/done_tick.svg'
import Trash from '@Assets/trash_training.svg'

type Props = {
	trainingId: string | undefined
	updateMode: boolean
	createEvent: () => void
	cancelEvent: () => void
	deleteEvent: (id: string) => void
}

export function HeaderCreateTrainingPage(props: Props) {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { trainingId, updateMode, createEvent, cancelEvent, deleteEvent } = props

	return (
		<Stack flexDirection='row' alignItems='center' justifyContent='space-between' py={2} width='100%'>
			<Box position='relative'>
				<Typography fontSize={xlDisplay ? 36 : 22} fontFamily='GilroyBold' color='#fff' lineHeight='1'>
					{updateMode ? (mobile ? 'Save' : 'Save Training') : mobile ? 'Create' : 'Create Training'}
				</Typography>

				{xlDisplay && (
					<Box
						bgcolor='#fff'
						borderRadius='10px 10px 0 0'
						position='absolute'
						top='61px'
						left='0'
						right='0'
						height='5px'
					></Box>
				)}
			</Box>

			<Box display='flex' alignItems='center'>
				{updateMode && (
					<Button variant='cancel' type='button' onClick={() => deleteEvent(trainingId as string)}>
						<Box display='flex' alignItems='center'>
							<IconImage src={Trash} alt='trash' height='18px' />
						</Box>
					</Button>
				)}

				<Button variant='cancel' type='button' onClick={cancelEvent}>
					Cancel
				</Button>

				<Button variant='create' onClick={createEvent}>
					<Box display='flex' alignItems='center' mr={!mobile ? 1 : 0}>
						<IconImage src={Check} alt='Check' height='16px' />
					</Box>
					{!mobile && (updateMode ? 'Save' : 'Create')}
				</Button>
			</Box>
		</Stack>
	)
}
