import { ThemeProvider } from '@mui/material'
import { theme } from './theme'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import HomePageComponent from './features/HomePage/HomePageComponent'
import { AuthRoutes } from './features/auth'
import { SharedContextProvider } from '@Context'
import { DashboardRoutes } from './features/dashboard'
import { SubscriptionsRoutes } from './features/subscriptions'
import { PaymentRoutes } from 'features/payment/PaymentRoutes'
import { CoursesRoutes } from 'features/courses/CoursesRoutes'
import { ContactUsRoutes } from 'features/contactUsPage'
import { ErrorsRoutes } from 'features/errors/ErrorsRoutes'
import { CommitmentRoutes } from 'features/CommitmentPage/CommitmentRoutes'

import './App.scss'
import { SubscriptionsContextProvider } from './features/subscriptions/context'
import { SharedDataManagement } from '@Components'
import { Fragment } from 'react'
import { RouterErrorsHandler } from './shared/components/RouterErrorsHandler'
import { ReportingRoutes } from './features/reporting'
import { TrainingsFeatureRoutes } from 'features/trainings/TrainingsRoutes'

export function App() {
	return (
		<ThemeProvider theme={theme}>
			<SharedContextProvider>
				<div className='main'>
					<BrowserRouter>
						<RouterErrorsHandler>
							<SharedDataManagement>
								<Fragment>
									<AuthRoutes />
									<DashboardRoutes />
									<SubscriptionsContextProvider>
										<SubscriptionsRoutes />
									</SubscriptionsContextProvider>
									<PaymentRoutes />
									<CommitmentRoutes />
									<CoursesRoutes />
									<ContactUsRoutes />
									<ErrorsRoutes />
									<ReportingRoutes />
									<TrainingsFeatureRoutes />
									<Routes>
										<Route path='/' element={<HomePageComponent />} />
									</Routes>
								</Fragment>
							</SharedDataManagement>
						</RouterErrorsHandler>
					</BrowserRouter>
				</div>
			</SharedContextProvider>
		</ThemeProvider>
	)
}
