import { Box, Stack, useMediaQuery, useTheme } from '@mui/material'
import { PriceCard } from './PriceCard'
import { useData } from './hooks'
import { BlockHeader } from '../BlockHeader'
import { DisplayEnum } from 'shared/enums/display.enum'

export function Pricing() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const { cards, currentSubscription } = useData()

	return (
		<Stack pt={6} pb={xlDisplay ? 18.7 : 5} id='pricing'>
			<BlockHeader color={theme.palette.info.main} title='PRICING DETAILS' subtitle='Competitive Pricing' />
			<Stack mt={6} direction={xlDisplay ? 'row' : 'column'} alignItems='center' justifyContent='center'>
				<Box mr={xlDisplay ? -1.2 : 0} mb={xlDisplay ? 0 : 4} position='relative'>
					<PriceCard options={cards.standard} disabled={currentSubscription >= 1}/>
				</Box>
				<Box zIndex={11} position='relative'>
					<PriceCard options={cards.pro} disabled={currentSubscription >= 2}/>
				</Box>
				<Box ml={xlDisplay ? -1.2 : 0} mt={xlDisplay ? 0 : 2} position='relative'>
					<PriceCard options={cards.enterprise} disabled={currentSubscription >= 3}/>
				</Box>
			</Stack>
		</Stack>
	)
}
