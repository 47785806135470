import { RequestService } from '@Services'
import { URLS } from '@Utils'

export async function uploadImageEffect(file: any): Promise<{ url: string }> {
	const response = await RequestService.postMedia<{ file: any }, { url: string }>(
		URLS.uploadImage,
		{ file },
		true,
	)

	if (response.status === 200) {
		return response.data
	}

	return { url: '' }
}