import { Box, styled } from '@mui/material'

export const CoverBox = styled(Box, { name: 'CoverBox' })({
	position: 'absolute',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	background: 'rgba(0, 0, 0, .2)',
	zIndex: 10,
})

