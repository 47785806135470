import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DisplayEnum, RolesEnum } from '@Enums'
import { diffInMinutes, getTimeLinePosition, pastTraining } from 'features/trainings/utils/calendar.utils'
import { CreateTraining } from 'features/trainings/typings'
import { useToken } from '@Hooks'
import { getTimeIntervalWithPeriod, prepareCreateTrainingModel } from 'features/trainings/utils/utils'
import { CreateTrainingModel } from 'features/trainings/create-training-page/CreateTrainingPage'
import { useEffect, useState } from 'react'
import { fetchCodeEffect } from 'features/trainings/effects'
import { UPDATE_CODE_TIME } from 'features/trainings/constants'

type Props = {
	training: CreateTraining
	changeTraining: (training: CreateTrainingModel) => void
}

export function CalendarTrainingCell(props: Props) {
	const theme = useTheme()
	const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet))
	const user = useToken()
	const { training, changeTraining } = props
	const [timer, setTimer] = useState<boolean>(true)
	const [updatedCode, setUpdatedCode] = useState<null | string>(null)

	const prepareTraining = (training: CreateTraining) => {
		if (user?.role === RolesEnum.User || user?.role === RolesEnum.SuperUser) {
			return
		}
		const data = prepareCreateTrainingModel(training)
		changeTraining(data)
	}

	const getNewCode = async (event: any) => {
		event.stopPropagation()
		const code = await fetchCodeEffect(training.id || '')
		setUpdatedCode(code.code)
		setTimer(true)
	}

	useEffect(() => {
		const interval = setInterval(() => {
			setTimer(false)
		}, UPDATE_CODE_TIME)

		return () => {
			clearInterval(interval)
		}
	}, [timer])

	return (
		<Box
			position='absolute'
			width='100%'
			display='flex'
			flexDirection='row-reverse'
			alignItems='flex-start'
			justifyContent='flex-end'
			right='0'
			top={getTimeLinePosition(new Date(training.startTime)) + 'px'}
			zIndex='999'
			borderRadius='3px'
			padding='6px 5px 6px 0'
			boxSizing='border-box'
			sx={{
				cursor: pastTraining(training.endTime) ? 'auto' : 'pointer',
				':hover': {
					backgroundColor: pastTraining(training.endTime) ? 'transparent' : 'rgba(255, 255, 255, 0.1)',
					'.duration-line': {
						backgroundColor: pastTraining(training.endTime) ? '#c3c3c8' : '#9945FF',
					},
					'.duration': {
						backgroundColor: '#9945FF',
						color: '#fff',
					},
				},
			}}
			onClick={() => {
				if (pastTraining(training.endTime)) {
					return
				}
				prepareTraining(training)
			}}
		>
			<Box width='calc(100% - 11px)'>
				<Box display='flex' alignItems='center' justifyContent='flex-start'>
					<Typography
						fontSize='12px'
						fontFamily='GilroyMedium'
						color='#1E1C30'
						lineHeight='1'
						padding='2px 5px'
						bgcolor='#fff'
						borderRadius='3px'
						className='duration'
					>
						{getTimeIntervalWithPeriod(training.startTime, training.endTime)}
					</Typography>
					{!pastTraining(training.endTime) && timer && (
						<Box ml='auto' fontFamily='GilroyMedium' fontSize='12px' color='rgba(255, 255, 255, 0.7)'>
							#{updatedCode ?? training.code}
						</Box>
					)}

					{!pastTraining(training.endTime) && !timer && (
						<Box fontFamily='GilroyBold' fontSize='10px' color='rgba(0, 0, 0, 0.7)' ml='auto' onClick={getNewCode}>
							Get Code
						</Box>
					)}
				</Box>
				<Typography
					fontSize='12px'
					fontFamily='GilroyMedium'
					color='rgba(255, 255, 255, 0.7)'
					lineHeight='17px'
					mt='6px'
					noWrap
				>
					{training.title}
				</Typography>
			</Box>

			<Box
				width='5px'
				mr='6px'
				borderRadius='0 3px 3px 0'
				height={diffInMinutes(training.startTime, training.endTime) + 'px'}
				className='duration-line'
				bgcolor={pastTraining(training.endTime) ? '#c3c3c8' : '#fff'}
			></Box>
		</Box>
	)
}
