import { styled } from '@mui/material'


const StyledImg = styled('img')(( props ) => ({
	height: props.height,
	width: props.width,
}));

type Props = {
	src: string
	height?: string
	width?: string
	alt?: string
}

export function IconImage(props: Props) {
	const { src, height, width, alt } = props

	return (
		<StyledImg src={src} alt={alt} height={height} width={width}/>
	)
}
