import Video from '@Assets/safety_video.mp4'
import VRLogo from '@Assets/VRIcon.png'
import Image from '@Assets/video2.jpg'

import { IconImage } from '@Components'
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { DisplayEnum } from 'shared/enums/display.enum'

import { BlockHeader } from './BlockHeader'
import { SafetyStack } from './SafetyStack'
import { SafetyVideo } from './SafetyVideo'

export function Safety() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	return (
		<Stack textAlign='center' mt={6} pb={6} id='safetly'>
			<BlockHeader color={theme.palette.success.main} title='Custom Development ' subtitle='Can we tailor the Virtual Reality experience around your company’s training requirements?' />

			<Stack mx='auto'>
				{xlDisplay && (
					<SafetyVideo autoPlay muted loop>
						<source src={Video} type='video/mp4' />
					</SafetyVideo>
				)}
				{!xlDisplay && (
					<IconImage src={Image} width={mobile ? '100vw' : '100%'} />
				)}				
				<SafetyStack
					p={3}
					spacing={4.3}
					mt={-17}
					display='flex'
					justifyContent='center'
					borderRadius='16px'
					direction='column'
					maxWidth={mobile ? 315 : 763}
					boxSizing='border-box'
					mx='auto'>
					<Box>
						<IconImage src={VRLogo} alt='VR Logo' width='68px' />
					</Box>
					<Typography color='primary' fontFamily='GilroyRegular' variant='body1'>
						Yes, of course! We can customize the VR experience around the needs of unique business.
						<br/>
						This means that we can develop VR training courses that focus on your specific training needs. 
						<br/>
						Reach out for more information!
					</Typography>
				</SafetyStack>
			</Stack>
		</Stack>
	)
}
