import Logo from '@Assets/bridge_logo.png'
import Verified from '@Assets/verified.svg'
import Chain from '@Assets/chain.svg'
import QRCode from '@Assets/qr_code_2.png'

import { Box, Button, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useHandlers } from './hooks'
import { BackgroundStack } from '../components/BackgroundStack'
import { IconImage } from '@Components'
import { BoxBackgroundStack } from '../components'
import { DisplayEnum } from 'shared/enums/display.enum'

export function PasswordCreatedPage() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { handleMainPageClick } = useHandlers()

	return (
		<BackgroundStack
			width='100%'
			height='100%'
			minHeight='100vh'
			pb={xlDisplay ? 14.5 : 0}
			px={mobile ? 3 : 0}
			boxSizing={mobile ? 'border-box' : 'content-box'}
		>
			<BoxBackgroundStack
				px={mobile ? 3 : 5}
				pt={mobile ? 3 : 6}
				pb={mobile ? 3 : 3.5}
				borderRadius='10px'
				mt={mobile ? 5 : 7}
				ml={xlDisplay ?  13 : mobile ? 0 : 'auto'}
				mr={xlDisplay || mobile ? 0 : 'auto'}
				width={mobile ? '100%' : 530}
				boxSizing='border-box'
			>
				<IconImage src={Logo} alt='logo' width='154px'/>
				<Box mt={4} mb={2}>
					<IconImage src={Verified} alt='verified' width='90px' />
				</Box>

				<Typography fontSize={mobile ? 24 : 34} variant='h5' color='primary' fontFamily='GilroyBold'>
					The new password has been successfully set.
				</Typography>
				<Typography
					variant='body1'
					color={theme.palette.text.secondary}
					mt={2}
					mb={3}
					fontFamily='GilroyMedium'
					letterSpacing={0}
				>
					A password reset code will be sent to the email address you provided during registration.
				</Typography>

				<Button variant='rounded_success' fullWidth onClick={handleMainPageClick}>
					RETURN TO MAIN PAGE
				</Button>
			</BoxBackgroundStack>

			<BoxBackgroundStack
				px={3.5}
				py={4}
				borderRadius='10px'
				mt={1}
				ml={xlDisplay ? 13 : mobile ? 0 : 'auto'}
				mr={xlDisplay || mobile ? 0 : 'auto'}
				width={mobile ? '100%' : 530}
				boxSizing='border-box'
				direction={mobile ? 'column' : 'row'}
				spacing={3}
				alignItems={mobile ? 'center' : 'start'}
			>
				<IconImage src={QRCode} alt='qrcode' width='87px' />

				<Stack>
					<Typography fontSize={mobile ? 20 : 24} variant='h5' color='primary' fontFamily='GilroyBold'>
						Oculus app download link
					</Typography>

					<Stack direction='row' spacing={1} alignItems='center' mt={2}>
						<IconImage src={Chain} alt='link' width='35px'/>
						<Typography color='primary' fontSize={18} fontFamily='GilroyBold'>
							<Link href='https://ocul.us/3OQSjJd'>https://ocul.us/3OQSjJd</Link>
						</Typography>
					</Stack>

				</Stack>
			</BoxBackgroundStack>
		</BackgroundStack>
	)
}
