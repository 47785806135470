import { Typography, useTheme } from '@mui/material'

export function PersonalData() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color='primary'>
				<u>Collecting and Using Your Personal Data</u>
			</Typography>
			<Typography variant='h4' color={theme.palette.text.primary}>
				Types of Data Collected
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Personal Data
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				While using Our Service, We may ask You to provide Us with certain personally identifiable information that can
				be used to contact or identify You. Personally identifiable information may include, but is not limited to:
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Email address
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				First name and last name
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Phone number
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Address, State, Province, ZIP/Postal code, City
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Usage Data
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Usage Data
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Usage Data is collected automatically when using the Service.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser
				type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent
				on those pages, unique device identifiers and other diagnostic data.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				When You access the Service by or through a mobile device, We may collect certain information automatically,
				including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address
				of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device
				identifiers and other diagnostic data.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				We may also collect information that Your browser sends whenever You visit our Service or when You access the
				Service by or through a mobile device.
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Information from Third-Party Social Media Services
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				The Company allows You to create an account and log in to use the Service through the following Third-party
				Social Media Services:
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				Google
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				Facebook
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				Twitter
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				LinkedIn
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may
				collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as
				Your name, Your email address, Your activities or Your contact list associated with that account.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				You may also have the option of sharing additional information with the Company through Your Third-Party Social
				Media Service's account. If You choose to provide such information and Personal Data, during registration or
				otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this
				Privacy Policy.
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Information Collected while Using the Application
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				While using Our Application, in order to provide features of Our Application, We may collect, with Your prior
				permission:
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				Information regarding your location
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				Information from your Device's phone book (contacts list)
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				Pictures and other information from your Device's camera and photo library
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				We use this information to provide features of Our Service, to improve and customize Our Service. The
				information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply
				stored on Your device.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				You can enable or disable access to this information at any time, through Your Device settings.
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Tracking Technologies and Cookies
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
				information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to
				improve and analyze Our Service. The technologies We use may include:
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Cookies or Browser Cookies.</strong>
				</Typography>{' '}
				A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to
				indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some
				parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service
				may use Cookies.
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Flash Cookies.</strong>
				</Typography>{' '}
				Certain features of our Service may use local stored objects (or Flash Cookies) to collect and store information
				about Your preferences or Your activity on our Service. Flash Cookies are not managed by the same browser
				settings as those used for Browser Cookies.
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>Web Beacons.</strong>
				</Typography>{' '}
				Certain sections of our Service and our emails may contain small electronic files known as web beacons (also
				referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count
				users who have visited those pages or opened an email and for other related website statistics (for example,
				recording the popularity of a certain section and verifying system and server integrity).
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile
				device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. You can
				learn more about cookies on TermsFeed website article.
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Use of Your Personal Data
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				The Company may use Personal Data for the following purposes:
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.secondary}>
					<strong>To provide and maintain our Service</strong>
				</Typography>
				, including to monitor the usage of our Service.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.secondary}>
					<strong>To manage Your Account:</strong>
				</Typography>{' '}
				to manage Your registration as a user of the Service. The Personal Data You provide can give You access to
				different functionalities of the Service that are available to You as a registered user.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.secondary}>
					<strong>For the performance of a contract:</strong>
				</Typography>{' '}
				the development, compliance and undertaking of the purchase contract for the products, items or services You
				have purchased or of any other contract with Us through the Service.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.secondary}>
					<strong>To contact You:</strong>
				</Typography>{' '}
				To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a
				mobile application's push notifications regarding updates or informative communications related to the
				functionalities, products or contracted services, including the security updates, when necessary or reasonable
				for their implementation.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.secondary}>
					<strong>To provide You</strong>
				</Typography>{' '}
				with news, special offers and general information about other goods, services and events which we offer that are
				similar to those that you have already purchased or enquired about unless You have opted not to receive such
				information.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.secondary}>
					<strong>To manage Your requests:</strong>
				</Typography>{' '}
				To attend and manage Your requests to Us.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.secondary}>
					<strong>For business transfers:</strong>
				</Typography>{' '}
				We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization,
				dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of
				bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is
				among the assets transferred.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				<Typography component='span' color={theme.palette.text.secondary}>
					<strong>For other purposes:</strong>
				</Typography>{' '}
				We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the
				effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services,
				marketing and your experience.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				We may share Your personal information in the following situations:
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>With Service Providers:</strong>
				</Typography>{' '}
				We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to
				contact You.
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>For business transfers:</strong>
				</Typography>{' '}
				We may share or transfer Your personal information in connection with, or during negotiations of, any merger,
				sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>With Affiliates:</strong>
				</Typography>{' '}
				We may share Your information with Our affiliates, in which case we will require those affiliates to honor this
				Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or
				other companies that We control or that are under common control with Us.
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>With business partners:</strong>
				</Typography>{' '}
				We may share Your information with Our business partners to offer You certain products, services or promotions.
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>With other users:</strong>
				</Typography>{' '}
				when You share personal information or otherwise interact in the public areas with other users, such information
				may be viewed by all users and may be publicly distributed outside. If You interact with other users or register
				through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your
				name, profile, pictures and description of Your activity. Similarly, other users will be able to view
				descriptions of Your activity, communicate with You and view Your profile.
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				<Typography component='span' color={theme.palette.text.primary}>
					<strong>With Your consent:</strong>
				</Typography>{' '}
				We may disclose Your personal information for any other purpose with Your consent.
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Retention of Your Personal Data
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this
				Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal
				obligations (for example, if we are required to retain your data to comply with applicable laws), resolve
				disputes, and enforce our legal agreements and policies.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
				shorter period of time, except when this data is used to strengthen the security or to improve the functionality
				of Our Service, or We are legally obligated to retain this data for longer time periods.
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Transfer of Your Personal Data
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Your information, including Personal Data, is processed at the Company's operating offices and in any other
				places where the parties involved in the processing are located. It means that this information may be
				transferred to — and maintained on — computers located outside of Your state, province, country or other
				governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to
				that transfer.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
				accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or
				a country unless there are adequate controls in place including the security of Your data and other personal
				information.
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Deletion of Your Data
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				At any time, You may request that the Data that has been collected about You in the course of using this App be
				deleted. This request must be submitted by email to: <strong>info@bridgeLT.com</strong>
			</Typography>
			<Typography variant='h4' color={theme.palette.text.primary}>
				Disclosure of Your Personal Data
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Business Transactions
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We
				will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Law enforcement
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by
				law or in response to valid requests by public authorities (e.g. a court or a government agency).
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Other legal requirements
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				Comply with a legal obligation
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				Protect and defend the rights or property of the Company
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				Prevent or investigate possible wrongdoing in connection with the Service
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				Protect the personal safety of Users of the Service or the public
			</Typography>
			<Typography component='li' color={theme.palette.text.primary}>
				Protect against legal liability
			</Typography>
			<Typography variant='h5' color={theme.palette.text.primary}>
				Security of Your Personal Data
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				The security of Your Personal Data is important to Us, but remember that no method of transmission over the
				Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means
				to protect Your Personal Data, We cannot guarantee its absolute security.
			</Typography>
		</>
	)
}
