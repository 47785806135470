import { Typography, useTheme } from '@mui/material'

export function Changes() {
	const theme = useTheme()

	return (
		<>
			<Typography fontFamily='GilroyBold' variant='h4' color='primary'>
				<u>Changes to These Terms and Conditions</u>
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is
				material We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking
				effect. What constitutes a material change will be determined at Our sole discretion.
			</Typography>
			<Typography variant='body1' color={theme.palette.text.secondary}>
				By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the
				revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the
				Service.
			</Typography>
		</>
	)
}
