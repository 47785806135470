import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { CreateTraining, GroupOnlineTraining } from 'features/trainings/typings'
import { DisplayEnum } from '@Enums'
import { Circles } from 'react-loader-spinner'
import { CreateTrainingModel } from 'features/trainings/create-training-page/CreateTrainingPage'
import { useEffect, useState } from 'react'
import { getTimeLinePosition, matchTrainings } from 'features/trainings/utils/calendar.utils'
import { CalendarTrainingCell } from './CalendarTrainingCell'
import { TIME_ARRAY } from 'features/trainings/constants'

type Props = {
	loading: boolean
	groups: GroupOnlineTraining[]
	weekDays: { [key: string]: string | number | Date | boolean }[]
	changeTraining: (training: CreateTrainingModel) => void
}

export function CalendarViewMobile(props: Props) {
	const theme = useTheme()
	const { groups, loading, weekDays, changeTraining } = props
	const tablet = useMediaQuery(theme.breakpoints.down(DisplayEnum.tablet))
	const [currentTime, setCurrentTime] = useState(new Date())

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCurrentTime(new Date())
		}, 60000)

		return () => {
			clearInterval(intervalId)
		}
	}, [])

	useEffect(() => {
		weekDays.forEach((day: { [key: string]: string | number | Date | boolean }) => {
			const container = document.querySelector('#' + day.dayName)
			if (container) {
				container.scrollTop = 480
			}
		})
	}, [weekDays])

	return (
		<Stack width='100%' borderRadius='5px' bgcolor='#312E41' boxSizing='border-box' overflow='hidden'>
			{loading && (
				<Stack
					position='absolute'
					top={0}
					right={0}
					bottom={0}
					left={0}
					alignItems='center'
					justifyContent='center'
					zIndex={1000}
				>
					<Circles height={120} width={120} color={theme.palette.grey.A200} ariaLabel='loading' />
				</Stack>
			)}
			<Stack flexDirection='column' alignItems='flex-start' justifyContent='flex-start' width='100%'>
				{weekDays.map((day: any, index: number) => {
					return (
						<Box key={day.dayDate} width='100%' borderBottom='1px solid rgba(255, 255, 255, 0.1)'>
							<Box width='100%' boxSizing='border-box' position='relative'>
								<Box
									height='60px'
									display='flex'
									flexDirection='row'
									alignItems='center'
									justifyContent='center'
									position='relative'
								>
									<Typography
										fontSize='34px'
										fontFamily='GilroyBold'
										color={day.today ? '#fff' : 'rgba(255, 255, 255, 0.5)'}
										lineHeight='1'
									>
										{day.dayDate}
									</Typography>
									<Box ml='6px'>
										<Typography
											fontSize='14px'
											fontFamily='GilroyMedium'
											color={day.today ? '#fff' : 'rgba(255, 255, 255, 0.5)'}
											lineHeight='1'
										>
											{day.month}
										</Typography>
										<Typography
											fontSize='14px'
											fontFamily='GilroyMedium'
											color={day.today ? '#fff' : 'rgba(255, 255, 255, 0.5)'}
											lineHeight='1'
										>
											{day.dayName}
										</Typography>
									</Box>
								</Box>
							</Box>
							<Stack
								id={day.dayName}
								flexDirection='row'
								alignItems='flex-start'
								justifyContent='flex-start'
								width='100%'
								height='660px'
								sx={{ overflowY: 'scroll' }}
								pt='6px'
								mt='-6px'
							>
								<Box
									width='80px'
									borderRight='1px solid rgba(255, 255, 255, 0.1)'
									boxSizing='border-box'
									position='relative'
								>
									{TIME_ARRAY.map((time: string, i: number) => {
										return (
											<Box key={time} height='60px' boxSizing='border-box' position='relative'>
												<Typography
													fontSize='14px'
													fontFamily='GilroyMedium'
													color='rgba(255, 255, 255, 0.5)'
													lineHeight='1'
													textAlign='right'
													position='relative'
													left='-10px'
													top='0'
													sx={{ transform: 'translateY(-50%)' }}
												>
													{time}
												</Typography>
											</Box>
										)
									})}

									{day.today && (
										<Box
											position='absolute'
											width='5px'
											height='5px'
											borderRadius='50%'
											bgcolor='#FF9A43'
											right='0'
											top={getTimeLinePosition(currentTime) + 'px'}
											sx={{ transform: 'translate(50%, -50%)' }}
										></Box>
									)}
								</Box>
								<Box key={day.dayDate} width='100%' boxSizing='border-box' position='relative'>
									{TIME_ARRAY.map((time: string, i: number) => {
										return (
											<Box
												key={time}
												borderLeft={index ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'}
												borderBottom={i !== TIME_ARRAY.length - 1 ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'}
												height='60px'
												boxSizing='border-box'
												position='relative'
												bgcolor={day.today ? 'rgba(255, 255, 255, 0.03)' : 'transparent'}
											></Box>
										)
									})}

									{day.today && (
										<Box
											position='absolute'
											width='100%'
											height='2px'
											bgcolor='#FF9A43'
											right='0'
											top={getTimeLinePosition(currentTime) + 'px'}
											sx={{ transform: 'translateY(-50%)' }}
										></Box>
									)}

									{matchTrainings(groups, day.date)?.map((training: CreateTraining) => {
										return (
											<CalendarTrainingCell key={training.id} training={training} changeTraining={changeTraining} />
										)
									})}
								</Box>
							</Stack>
						</Box>
					)
				})}
			</Stack>
		</Stack>
	)
}
