import { RequestService } from '@Services'
import { URLS } from '@Utils'
import { useCallback } from 'react'

export function useHandlers() {
	const deleteTraining = useCallback(async (id: string, setLoading: (value: boolean) => void) => {
		setLoading(true)
		const response = await RequestService.removeWithParams(URLS.deleteTraining, { id: id }, true)
		setLoading(false)
	}, [])

	return {
		deleteTraining,
	}
}
