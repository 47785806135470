import { IconImage } from '@Components'
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'

import ArrowRight from '@Assets/right-arrow.svg'
import ArrowLeft from '@Assets/left-arrow.svg'

import { CourseContentCenterBlock } from './CourseContentCenterBlock'
import { useData, useOculusCode } from '../hooks'
import { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade } from 'swiper'
import 'swiper/scss'
import 'swiper/scss/autoplay'
import 'swiper/scss/effect-fade'
import { useGoToPreviousPage } from '@Hooks'
import { useTrainingsContext } from '../../context'
import { DisplayEnum } from 'shared/enums/display.enum'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

function formatTimeLeft(time: number) {
	const minutes = Math.floor(time / 60)

	let seconds = time % 60

	return (
		<Typography variant='caption' fontSize={16} component='div' color='primary' fontFamily='GilroyRegular'>
			{`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
		</Typography>
	)
}

export function CourseContentComponent() {
	const theme = useTheme()
	const xlDisplay = useMediaQuery(theme.breakpoints.up(DisplayEnum.tablet))
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const { training, canGenerateCode, color, status, withOculus } = useData()
	const [code, setCode] = useState('')
	const goToPrevious = useGoToPreviousPage()
	const [timer, setTimer] = useState(0)
	const [
		{
			trainingsInfo: { trainings },
		},
	] = useTrainingsContext()

	useEffect(() => {
		const interval = setInterval(() => {
			setTimer(Date.now())
		}, 900000)

		return () => {
			clearInterval(interval)
		}
	}, [setTimer, timer])

	useOculusCode(canGenerateCode, setCode, timer)

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<Stack
			maxWidth={xlDisplay ? 1180 : '100%'}
			padding={xlDisplay ? 0 : '0 30px'}
			boxSizing='border-box'
			mx='auto'
			mt={xlDisplay ? 15.5 : 8}
			mb={xlDisplay ? 14 : 8}
		>
			<Stack direction='row' mb={mobile ? 2 : 0}>
				<Box mt={0.5}>
					<Button variant='courses_back' onClick={goToPrevious}>
						<IconImage src={ArrowLeft} alt='arrow' height='16px' />
					</Button>
				</Box>
				<Stack ml={2} width='100%'>
					<Stack direction='row'>
						<Typography fontSize={18} fontFamily='GilroySemiBold' color={theme.palette.info.main}>
							TRAINING & COURSES
						</Typography>
						<Box ml={1.4} mt={0.2}>
							<IconImage src={ArrowRight} alt='arrow' height='11.18px' />
						</Box>
					</Stack>
					<Stack direction='row' mt={-0.1} mb={1} alignItems='baseline' justifyContent='space-between'>
						<Typography
							fontSize={mobile ? 24 : 48}
							fontFamily='GilroyBold'
							color={theme.palette.primary.main}
							maxWidth={withOculus ? '75%' : '100%'}
						>
							{training?.title}
						</Typography>

						{withOculus && (
							<Stack>
								<Typography fontSize={18} fontFamily='GilroySemiBold' color={color}>
									{status === 'INPROGRESS' ? 'IN PROGRESS' : status}
								</Typography>
							</Stack>
						)}
					</Stack>
				</Stack>
			</Stack>

			<Box overflow='hidden' alignItems='center'>
				<Swiper
					modules={[EffectFade, Autoplay]}
					effect={'fade'}
					autoplay={{
						delay: 3000,
						disableOnInteraction: true,
					}}
				>
					{(training?.images || []).map((image, index) => (
						<SwiperSlide key={`image_${index}`}>
							<Box
								maxWidth={xlDisplay ? 'fit-content' : '100%'}
								height={xlDisplay ? 380 : '100%'}
								mx='auto'
								borderRadius='16px'
								overflow='hidden'
							>
								<IconImage
									src={image}
									alt='slide'
									width={xlDisplay ? 'fit-content' : '100%'}
									height={xlDisplay ? '380px' : '100%'}
								/>
							</Box>
						</SwiperSlide>
					))}
				</Swiper>
			</Box>
			{canGenerateCode && (
				<Stack maxWidth={xlDisplay ? 1180 : '100%'} zIndex={1} mt={-4}>
					<CourseContentCenterBlock
						width={xlDisplay ? 1180 : '100%'}
						height={mobile ? '100%' : 139}
						mt={mobile ? 9.5 : 7.4}
						mx='auto'
						px={mobile ? 1 : 22}
						py={mobile ? 1 : 3}
						boxSizing='border-box'
						justifyContent='center'
					>
						<Stack direction={mobile ? 'column' : 'row'} alignItems='center' justifyContent='space-between'>
							<Stack direction={mobile ? 'column' : 'row'} alignItems='center'>
								{!mobile && (
									<CountdownCircleTimer
										size={70}
										strokeWidth={6}
										isPlaying
										duration={900}
										trailColor='#62C9C3'
										colors='#fff'
										onComplete={() => {
											return { shouldRepeat: true }
										}}
									>
										{({ remainingTime }) => formatTimeLeft(remainingTime)}
									</CountdownCircleTimer>
								)}

								<Stack direction='column' ml={2}>
									<Typography
										fontSize={24}
										mb={0.5}
										fontFamily='GilroyBold'
										color={theme.palette.primary.main}
										textAlign={mobile ? 'center' : 'left'}
									>
										Code for Oculus
									</Typography>
									<Typography
										fontSize={16}
										fontFamily='GilroyRegular'
										color={theme.palette.text.secondary}
										textAlign={mobile ? 'center' : 'left'}
									>
										Go to the oculus and enter the code there
										{!mobile && <br />}
										to gain access to the training.
									</Typography>
								</Stack>
							</Stack>

							<Stack>
								<Stack direction='row' mt={mobile ? 1.05 : -0.45} alignItems='center' justifyContent='center'>
									{mobile && (
										<CountdownCircleTimer
											size={70}
											strokeWidth={6}
											isPlaying
											duration={900}
											trailColor='#62C9C3'
											colors='#fff'
											onComplete={() => {
												return { shouldRepeat: true }
											}}
										>
											{({ remainingTime }) => formatTimeLeft(remainingTime)}
										</CountdownCircleTimer>
									)}
									<Typography fontSize={52} ml={2.6} fontFamily='GilroyBold' color={theme.palette.primary.main}>
										{code}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					</CourseContentCenterBlock>
				</Stack>
			)}
			<Stack width={mobile ? '100%' : 731} mt={mobile ? 3 : 11} mx='auto'>
				<Typography
					fontSize={16}
					mb={3}
					fontFamily='GilroySemiBold'
					lineHeight={1.8}
					color={theme.palette.primary.main}
				>
					<span dangerouslySetInnerHTML={{ __html: training?.description || '' }}></span>
				</Typography>
			</Stack>
		</Stack>
	)
}
