import { Stack, Typography, useTheme } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import { useCanDelete, useHandlers } from './hooks'
import type { SubscriptionUser } from '../../typings'
import { RolesEnum } from '@Enums'
import { format } from 'date-fns'
import { StyledGrid } from 'features/subscriptions/components'

type Props = {
	user: SubscriptionUser
}

export function TableRowMobile(props: Props) {
	const theme = useTheme()
	const { user } = props
	const { handleOpenConfirmationDeleteUser } = useHandlers()
	const canDelete = useCanDelete(user.role)

	const role = RolesEnum[user.role]
	const date = format(new Date(user.addedDate), 'MM/dd/yyyy')

	return (
		<StyledGrid direction={'column'} container width={'100%'} p={2}>
			<Stack mb={1.5}>
				<Typography fontSize='14px' fontFamily='GilroyRegular' color={theme.palette.text.secondary} mb={0.5}>
					Email
				</Typography>
				<Typography variant='h6' fontFamily='GilroySemiBold' color='primary'>
					{user.email}
				</Typography>
			</Stack>
			<Stack mb={1.5}>
				<Typography fontSize='14px' fontFamily='GilroyRegular' color={theme.palette.text.secondary} mb={0.5}>
					Oculus User
				</Typography>
				<Stack color={theme.palette.primary.main} fontFamily='GilroyRegular'>
					{user.withOculus && <CheckIcon />}
					{!user.withOculus && (
						<Typography color={theme.palette.text.secondary}>
							<HighlightOffRoundedIcon />
						</Typography>
					)}
				</Stack>
			</Stack>
			<Stack mb={1.5}>
				<Typography fontSize='14px' fontFamily='GilroyRegular' color={theme.palette.text.secondary} mb={0.5}>
					Role
				</Typography>
				<Typography variant='h6' fontFamily='GilroySemiBold' color='primary'>
					{role}
				</Typography>
			</Stack>
			<Stack mb={2.5}>
				<Typography fontSize='14px' fontFamily='GilroyRegular' color={theme.palette.text.secondary} mb={0.5}>
					Added date
				</Typography>
				<Typography variant='h6' fontFamily='GilroySemiBold' color='primary'>
					{date}
				</Typography>
			</Stack>
			<Stack color={theme.palette.text.secondary}>
				{canDelete && <DeleteOutlineIcon onClick={() => handleOpenConfirmationDeleteUser(user)} />}
			</Stack>
		</StyledGrid>
	)
}
