import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { RolesEnum } from '@Enums'
import { DisplayEnum } from 'shared/enums/display.enum'
import { StyledMenuItem, StyledSelect } from '@Components'

type Props = {
	items: { value: string | number; label: string }[]
	name: string
	label?: string
	bgColor?: string
	width?: string
	id: string
	color?: string
	defaultValue?: number
	onChange?: (value: string | number, name: string) => void
}

export function SelectField(props: Props) {
	const { items, name, label, width = '240px', id, defaultValue, color, onChange } = props
	const theme = useTheme()
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))

	const { setFieldValue } = useFormikContext<any>()

	const handleChange = useCallback(
		(event: any) => {
			setFieldValue(name || id, event.target.value)

			if (onChange) {
				onChange(event.target.value, name)
			}
		},
		[setFieldValue, id, name],
	)

	const defVal = defaultValue !== undefined ? defaultValue : 1

	return (
		<Stack width={mobile ? '100%' : width} classes='custom_select'>
			{label && (
				<Typography variant='body1' fontFamily='GilroyMedium' color={theme.palette.text.secondary}>
					{label}
				</Typography>
			)}
			<StyledSelect id={name} name={name} defaultValue={defVal} onChange={handleChange} textColor={color || 'white'}>
				{items.map((item, index) => (
					<StyledMenuItem value={item.value} key={`role_${index}`}>
						{item.label}
					</StyledMenuItem>
				))}
			</StyledSelect>
		</Stack>
	)
}
