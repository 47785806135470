import { Footer, Header } from '@Components'
import { Stack, useTheme } from '@mui/material'
import { CourseContentComponent } from './components/CourseContentComponent'
import { CourseContentStack } from '../components'
import { DataManager } from '../DataManager'

export function CourseContentPage() {
	const theme = useTheme()

	return (
		<DataManager setLoading={(value) => {}}>
			<Stack bgcolor={theme.palette.background.default}>
				<CourseContentStack>
					<Header hideNavigationMenu />
					<CourseContentComponent />
					<Footer showAdditionalInfo={true} onMainPage={false}/>
				</CourseContentStack>
			</Stack>
		</DataManager>
	)
}
