import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Form, useFormikContext } from 'formik'
import type { ContactUs } from '../typings/ContactUs'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useGoToMain } from '@Hooks'
import { DisplayEnum } from 'shared/enums/display.enum'
import { CustomTextField } from '@Components'

type Props = {
	onMainPage: boolean
}

export function ContactUsForm(props: Props) {
	const { onMainPage } = props
	const theme = useTheme()
	const [showThankYou, setShowThankYou] = useState(false)
	const mobile = useMediaQuery(theme.breakpoints.down(DisplayEnum.mobile))
	const goToMain = useGoToMain(setShowThankYou)
	const { touched, errors, isSubmitting, isValid, values } = useFormikContext<ContactUs>()
	const enabled =
		(isValid && (touched.name || touched.message || touched.email)) ||
		(!touched.name && !touched.message && !touched.email)

	console.log(Object.keys(values).length)

	useEffect(() => {
		if (
			isValid &&
			isSubmitting &&
			Object.keys(touched).length &&
			!Object.keys(errors).length &&
			// TODO: Remove this and create better logic for ThankYou message showing
			Object.values(values).filter((value) => !!value).length > 2
		) {
			setShowThankYou(true)
		}
	}, [isSubmitting, isValid, errors, touched])

	const handleSendAgain = useCallback(() => {
		setShowThankYou(false)
	}, [setShowThankYou])

	return (
		<Form>
			<Stack
				bgcolor={theme.palette.success.dark}
				borderRadius={4}
				width={mobile ? 255 : 470}
				height={646}
				p={mobile ? 3 : 6}
			>
				{/* Displayed when a message is sent */}
				{showThankYou && (
					<Stack alignItems='center' flex={1} justifyContent='center'>
						<Typography fontFamily='GilroyBold' fontSize={38} mb={2} color={theme.palette.text.primary}>
							Thank you.
						</Typography>
						<Typography
							fontFamily='GilroySemiBold'
							fontSize={18}
							mb={4}
							color={theme.palette.text.secondary}
							textAlign='center'
						>
							YOUR MESSAGE HAS BEEN SUCCESSFULLY SENT. <br /> WE'LL CONTACT YOU SOON
						</Typography>
						<Box mb={2}>
							<Button variant='contact_us_green_text' onClick={handleSendAgain}>
								SEND MESSAGES AGAIN
							</Button>
						</Box>
						{!onMainPage && (
							<Box>
								<Button variant='contact_us_white_text' onClick={goToMain}>
									GO TO MAIN PAGE
								</Button>
							</Box>
						)}
					</Stack>
				)}
				{/* Displayed by default */}
				{!showThankYou && (
					<Stack>
						<Typography color='primary' fontFamily='GilroyBold' fontSize={38} mb={3} lineHeight={1}>
							Contact Us
						</Typography>
						<Box mb={1} pb={2}>
							<CustomTextField<ContactUs>
								id='email'
								name='email'
								label='BUSINESS EMAIL'
								type='email'
								placeholder='ENTER CONTACT EMAIL ADDRESS'
							/>
						</Box>

						<Box mb={1} pb={2}>
							<CustomTextField<ContactUs>
								id='name'
								name='name'
								label='YOUR NAME'
								type='text'
								placeholder='ENTER YOUR FULL NAME'
							/>
						</Box>

						<Box mb={1} pb={2}>
							<CustomTextField<ContactUs>
								id='organization'
								name='organization'
								label='ORGANIZATION'
								type='text'
								placeholder='ENTER YOUR ORGANIZATION'
							/>
						</Box>

						<Box mb={1} pb={2}>
							<CustomTextField<ContactUs>
								id='message'
								name='message'
								label='MESSAGE'
								type='message'
								placeholder='PLEASE LEAVE YOUR MESSAGE'
								textArea={true}
							/>
						</Box>
						<Button variant='contact_us' fullWidth type='submit' disabled={!enabled}>
							SEND
						</Button>
					</Stack>
				)}
			</Stack>
		</Form>
	)
}
