import { SharedPagination, SharedSearch } from '@Components'
import { Stack, Typography, useTheme } from '@mui/material'
import { Fragment, ReactElement, useCallback, useState } from 'react'
import { StyledReportingsTableHeaderStack, StyledTabs } from '.'
import { ReportingFilter } from './Filter/ReportingFilter'
import { StyledStack } from './StyledStack'
import { useFilterChange } from '../hooks'
import { TrainingStatusesEnum } from '../TrainingsReports/enums'

function a11yProps(index: number) {
	return {
		id: `tab-${index}`,
		'aria-controls': `tabpanel-${index}`,
	}
}

type Props = {
	filtersTitles: TrainingStatusesEnum[] | string[]
	heading?: string
	noTabs?: boolean
	searchPlaceholder: string
	filtersSubTitles: string[]
	isTrainingsReportPage?: boolean
	children: ReactElement | ReactElement[]
	totalPages: number
	entity: 'participants' | 'trainings'
}

export function ReportingFiltersWithTable(props: Props) {
	const {
		filtersTitles,
		filtersSubTitles,
		heading,
		noTabs,
		searchPlaceholder,
		isTrainingsReportPage,
		children,
		totalPages,
		entity,
	} = props
	const theme = useTheme()

	const [value, setValue] = useState(0)

	const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue)
	}, [])
	const filterChanged = useFilterChange(entity)

	return (
		<Fragment>
			<StyledReportingsTableHeaderStack pt={2.5}>
				<Typography
					fontFamily='GilroySemiBold'
					mt={noTabs ? 3 : ''}
					textTransform='uppercase'
					fontSize={18}
					color={theme.palette.primary.main}
				>
					{heading}
				</Typography>
				<Stack direction='row' justifyContent={!noTabs ? 'space-between' : 'end'}>
					{!noTabs && (
						<Stack mt={3} direction='row'>
							<StyledTabs value={value} onChange={handleChange} aria-label='tabs'>
								{filtersTitles.map((filterTitle, index) => (
									<ReportingFilter
										subtitle={filtersSubTitles[index]}
										title={filtersTitles[index]}
										titleColor={theme.palette.primary.main}
										key={`series-${index}`}
										isTrainingsReportPage={isTrainingsReportPage}
										onClick={() => filterChanged(filterTitle, 'type')}
										{...a11yProps(index)}
									/>
								))}
							</StyledTabs>
						</Stack>
					)}
					<StyledStack mt={noTabs ? -4 : -0.5} pb={noTabs ? 2 : ''} height='100%'>
						<SharedSearch placeholder={searchPlaceholder} name='name' id='name' onChange={filterChanged} />
					</StyledStack>
				</Stack>
			</StyledReportingsTableHeaderStack>
			{children}
			<Stack>
				<SharedPagination pagesCount={totalPages} name='page' onChange={filterChanged} />
			</Stack>
		</Fragment>
	)
}
