import { styled, Box } from '@mui/material'
import Background from '@Assets/Rectangle_bg.svg'

export const StyledBox = styled(Box, { name: 'StyledBox' })({
	backgroundImage: `linear-gradient(180deg, #1E1C3000, #1E1C30), url('${Background}')`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	padding: '13px 20px',
	cursor: 'pointer',
})
