import { RequestService } from '@Services'
import { URLS } from '@Utils'
import type { OnlineTraingsResponse } from '../typings'
import { fetchOnlineTrainingsActionCreator, initialState } from '../state'

export async function fetchOnlineTrainingsEffect(params: { from: string; to: string } | null = null) {
	const response = await RequestService.get<{ from: string; to: string } | null, OnlineTraingsResponse | null>(
		URLS.fetchOnlineTrainings,
		params,
		true,
	)

	if (response.status === 200) {
		return { status: 'success', action: fetchOnlineTrainingsActionCreator(response.data) }
	}

	return { status: 'error', action: fetchOnlineTrainingsActionCreator(initialState.onlineTrainings) }
}
