import { useTrainingsContext } from '../../context'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useToken } from '@Hooks'
import { useTheme } from '@mui/material'
import { TrainingStatusEnum } from '../../utils/enums'

export function useData() {
	const { id } = useParams<{ id: string }>()
	const theme = useTheme()
	const user = useToken()
	const [
		{
			trainingsInfo: { trainings },
		},
	] = useTrainingsContext()

	const training = useMemo(() => trainings.find((training) => training.id === id), [trainings])
	const canGenerateCode = user.withOculus

	const color =
		training?.status === TrainingStatusEnum.Complete ? theme.palette.success.main : theme.palette.primary.main

	const status = `${(training?.status !== TrainingStatusEnum.Complete
		? TrainingStatusEnum[training?.status || 0]
		: `completed: ${training?.timeComplete}`
	).toUpperCase()}`

	return {
		training,
		canGenerateCode,
		color,
		status,
		withOculus: user.withOculus,
	}
}
